import { Tooltip, IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useContext } from "react";
import { TemasContext } from '../../services/temasProvider';
import { useNavigate } from "react-router-dom";
import { grey } from '@mui/material/colors';

function NavBarIcon (props) {
    
    const { titulo, link, Icon, dica, menu } = props;
    const {mode} = useContext(TemasContext);
    const Navigate = useNavigate();
    
    
    const StyledNavLink = styled(NavLink)`
         text-decoration: none;
         color:  ${({ theme }) => grey[400]};
         
         &:hover {
            color: ${({ theme }) => theme.palette.primary.light};
        }
         &.active {
            color: ${({ theme }) => theme.palette.primary.main};
        }
  `;
  
      
  function handleOnClick () {
    Navigate (link, { state: { menuOpen: props.open } });
    }
   
    const handleClickAbrirMenu = (e) => {
      props.onClick(e);

    };
  
  function RetornaIcon () {
    if (titulo==='Usuário') {return <Icon fontSize="medium" />}
    if (titulo==='Modo escuro') {
       if (mode === 'light') {return <LightModeIcon fontSize="medium"/>} else {return <DarkModeIcon fontSize="medium"/>}
    }
  }

    return <>
        
        {menu ?   
         
            <Tooltip title={dica} arrow>
              <IconButton onClick={handleClickAbrirMenu}>
                    <StyledNavLink to="#" activeClassName="active" >
                            {RetornaIcon () } 
                    </StyledNavLink>
                    
              </IconButton>
            </Tooltip>
          : <Tooltip title={dica} arrow>
                <IconButton onClick={(e) => {e.preventDefault (); handleOnClick()}}>
                    <StyledNavLink to={link} activeClassName="active">
                            <Icon fontSize="medium" />
                    </StyledNavLink>
                </IconButton>
              </Tooltip>
        
}

     </>
      
    
} export default NavBarIcon;