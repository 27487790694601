import { useContext, useState, useRef } from 'react';
import { API_KEY } from '../../data/config/config'
import { TemasContext } from '../../services/temasProvider';
import { useTheme } from '@mui/material';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { Payment } from '@mercadopago/sdk-react';
import { Box } from '@mui/material';
import CardTitulo from '../../views/components/view.card.titulo';
import { chamarApi} from '../../services/api2';
import  './styles.css';
import Loading from '../../components/Gerais/Loading/Loading2';
import useOnlineStatus from '@rehooks/online-status';

function MercadoPagoPagamento (props) {
  
  //initMercadoPago('APP_USR-3c84855c-4269-4a57-878e-e70f0195c0b5', {locale: 'pt-BR'});
 
  //APP_USR-b461478f-878f-40a3-8588-74ad43e7a779
  const online = useOnlineStatus();
 
  const theme  = useTheme();
  const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false, online: online});
  
  const [carregando, setCarregando] = useState (true);
  //const email = JSON.parse (localStorage.getItem ('usuario')).email;
 
  
    initMercadoPago(API_KEY, {locale: 'pt-BR'});
  
   

  const customization = {
      visual: {
          
        defaultPaymentOption: {
          bankTransferForm: true,
        },
        

        style : {
          theme: theme.palette.mode==='dark' ? 'dark' : 'default',
        }
      },
       
        paymentMethods: {
         // bankTransfer: 'all',
          creditCard: 'all',
        },
       };
       
    
    const initialization = {
        payer:
          {       
           email: props.email,
          },
          
          amount: props.dados.transaction_amount,
       };
      
       
       const onSubmit = async ({formData}
       ) => {
        

        // callback chamado ao clicar no botão de submissão dos dados
        formData = {...formData, metadata: {id_usuario: props.idUsuario, valor: props.dados.valor, periodo: props.dados.periodo, transaction_amount: props.dados.transaction_amount}};
       
        const result = await chamarApi ({type: 'post', endpoint:'creditosPagamento', data: formData}) // chama a API
        
        
       
        props.onFinish (result, formData.payment_method_id);
       
      };
    
      const onError = async (error) => {
       
        // callback chamado para todos os casos de erro do Brick
        setLoading ({inicio: false, fim: true, isSuccess: false})
        };
      
       
        const onReady = async (e) => {
          setLoading ({...loading, inicio: false, fim: true, isSuccess: true});
     //  props.onReady (true)
       
        };
        const paymentRef = useRef();

        if (!paymentRef.current) {
          paymentRef.current = (
            <Box style= {{ml:-2, mr:-2, px:-2, }} >
            <Payment
             
              initialization={initialization}
              customization={customization}
              onSubmit={onSubmit}
              onReady={onReady}
              onError={onError}
            />
            </Box>
          );
        }
        
        
   

  return <> 
   
   {/* ************** LOADING QUANDO CLICAR EM PAGAR ************** */}
   

   <CardTitulo titulo="Mercado Pago" divider mtChildren={-2}>
      
      <Loading local="componente" loading={loading} tipo='imagem' online={online} mostrarErroOnlineStatus/> 
  
     
   
   </CardTitulo>
   <Box sx={{mb:1, width: '100%'}}>
      {paymentRef.current}
      </Box>
</>

} export default MercadoPagoPagamento;