export const themes = {
  
     
     light: {
        classico: {
          nome: 'Clássico',
          components: {
            MuiButton: {
              styleOverrides: {
                root: {
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                  '&:active': {
                    boxShadow: 'none',
                  },
                  '&:focus': {
                    boxShadow: 'none',
                  }
                }
              }
            }
          },
          palette: {
            mode: 'light',
            primary: {
            main: '#546500',
            // main: '#137a17',
             light: 'rgb(66,148,69)',
             dark: 'rgb(13,85,16)',
             
            },
            secondary: {
              main: '#f0be98',
            },
            vermelho:  {
              main: '#d21f3c',
              dark: '#910D09',
              contrastText: 'rgba(0,0,0,0.87)',
            },
            
            verde:  {
              main: '#0B6623',
              dark: '#0B6623',
              contrastText: 'rgba(0,0,0,0.87)',
            },

            navbar : {
              main: '#137a17',
            },

            background: {
             // default: '#fffefc',
             default: '#f3f1e8',
             paper: '#fefcf4',
             
             
             //default: 'rgba(66,148,69, 0.05)',
             //paper: 'rgba(66,148,69, 0.01)',
             
            },
            sidebar: {
              dark: '#0a3823',
            },
            text: {
              //primary: 'rgba(0,0,0,0.83)',
              primary: '#30312b'
            },
            success: {
              main: '#22b77f',
              light: '#d8f6e8',
              dark: '#004b50',
              contrastText: 'rgba(0,0,0,0.87)',
            },
            info: {
              main: '#2dc7db',
              light: '#dbfafc',
              dark: '#003768',
            },
            warning: {
              main: '#f9b626',
              light: '#fff4d9',
              dark: '#7a4100',
            },
            error: {
              main: '#fb8e67',
              light: '#ffe9e1',
              dark: '#7a0916',
            },
          },  
        }
    },
    dark: {
      
        militar: {
          
                nome: 'Floresta',
                palette: {
                    mode: 'dark',
                    primary: {
                    main: '#a8bdad',
                    light: 'rgb(185,202,189)',
                    dark: 'rgb(117,132,121)',
                    contrastText: 'rgba(0,0,0,0.87)',
                    },
                    secondary: {
                    main: '#405667',
                    light: 'rgb(102,119,133)',
                    dark: 'rgb(44,60,72)',
                    contrastText: 'rgba(0,0,0,0.87)',
                    },
                    vermelho:  {
                      main: '#d21f3c',
                      dark: '#910D09',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    
                    verde:  {
                      main: '#0B6623',
                      dark: '#0B6623',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },

                    sidebar: {
                      dark: '#0e1a0f',
                    },
                    background: {
                    default: '#030c03',
                    paper: '#0e1a0f',
                    
                    },
                    navbar: {
                      main: '#0e1a0f'
                    },
                    text: {
                      secondary: 'rgba(255,255,255,0.8)',
                    primary: '#dde6e2',
                    },
                    success: {
                      main: '#22b77f',
                      light: '#d8f6e8',
                      dark: '#004b50',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    info: {
                      main: '#2dc7db',
                      light: '#dbfafc',
                      dark: '#003768',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    warning: {
                      main: '#f9b626',
                      light: '#fff4d9',
                      dark: '#7a4100',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    error: {
                      main: '#fb8e67',
                      light: '#ffe9e1',
                      dark: '#7a0916',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    divider: 'rgba(255,255,255,0.15)',
                },
                typography: {
                  subtitle1: {
                    fontSize: 17,
                  },
                  
                },
            },
        navy_blue: {
                nome: 'Azul Marinho',
                palette: {
                    mode: 'dark',
                     
                    primary: {
                      main: '#78a6de',
                      light: 'rgb(147,183,228)',
                      dark: 'rgb(84,116,155)',
                      contrastText: 'rgba(28,52,103,0.87)',
                    },
                    secondary: {
                      main: '#2ec5d3',
                      light: 'rgb(87,208,219)',
                      dark: 'rgb(32,137,147)',
                      contrastText: 'rgba(28,52,103,0.87)',
                    },
                    vermelho:  {
                      main: '#d21f3c',
                      dark: '#910D09',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    
                    verde:  {
                      main: '#0B6623',
                      dark: '#0B6623',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    background: {
                      default: '#05050a',
                      paper: '#0a1323',
                    },
                    text: {
                      primary: '#e1e1ff',
                      secondary: 'rgba(255,255,255,0.8)',
                    },
                    success: {
                      main: '#22b77f',
                      light: '#d8f6e8',
                      dark: '#004b50',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    info: {
                      main: '#2dc7db',
                      light: '#dbfafc',
                      dark: '#003768',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    warning: {
                      main: '#f9b626',
                      light: '#fff4d9',
                      dark: '#7a4100',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    error: {
                      main: '#fb8e67',
                      light: '#ffe9e1',
                      dark: '#7a0916',
                      contrastText: 'rgba(0,0,0,0.87)',
                    },
                    
                    
                    sidebar: {
                      dark: '#0a1323',
                    },
                    navbar: {
                      main: '#0a1323'
                    },
                    divider: 'rgba(255,255,255,0.15)',               
                  },    
                    },
        marrom: {
            nome: 'Terra',
            palette: {
                mode: 'dark',
                primary: {
                  main: '#d0cdcb',
                  light: 'rgb(217,215,213)',
                  dark: 'rgb(145,143,142)',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                secondary: {
                  main: '#af003d',
                  light: 'rgb(191,51,99)',
                  dark: 'rgb(122,0,42)',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                vermelho:  {
                  main: '#d21f3c',
                  dark: '#910D09',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                
                verde:  {
                  main: '#0B6623',
                  dark: '#0B6623',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                sidebar: {
                  dark: '#292929',
                },
                text: {
                  primary: '#d0cdcb',
                  secondary: 'rgba(255,255,255,0.8)',
                },
                background: {
                  default: '#080808',
                  paper: '#121212',
                },
                navbar: {
                  main: '#080808'
                 // main: (theme) => theme.palette.paper,
                },
                success: {
                  main: '#22b77f',
                  light: '#d8f6e8',
                  dark: '#004b50',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                info: {
                  main: '#2dc7db',
                  light: '#dbfafc',
                  dark: '#003768',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                warning: {
                  main: '#f9b626',
                  light: '#fff4d9',
                  dark: '#7a4100',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                error: {
                  main: '#fb8e67',
                  light: '#ffe9e1',
                  dark: '#7a0916',
                  contrastText: 'rgba(0,0,0,0.87)',
                },
                divider: 'rgba(255,255,255,0.15)',
              },
        },            

}
}

