import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { QuestoesContext } from '../services/questoesProvider';
import { VariaveisContext } from '../services/variaveisProvider';

import TreeListView from '../views/components/view.tree.list';
import CardTitulo from '../views/components/view.card.titulo';
 
import { TratarDados } from '../services/service.tags';
import * as ModelTags from '../models/model.tag'

export default function Tags (props) {
  const { usuario, tags, setTags, tagsEscolhidas, setTagsEscolhidas, disciplinaEscolhida, questoesControle, setQuestoesControle, filtros, questoes, setQuestoes, filtrosEscolhidos } = useContext (QuestoesContext);  
  const [btnDisabled, setBtnDisabled] = useState (tagsEscolhidas.length === 0 ? true : false);
  const [loading, setLoading] = useState({inicio: true, fim: false, isSuccess: false});
  const navigate = useNavigate();
  const { isMobile } = useContext (VariaveisContext);

  useEffect (() => {
    if (tagsEscolhidas.length === 0) {setBtnDisabled (true)} else {setBtnDisabled (false)}
  }, [tagsEscolhidas]);
 
  function removerIndisponiveis(obj) {
    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key])) {
            obj[key] = obj[key].filter(item => item.disponivel !== false);
            obj[key].forEach(childObj => removerIndisponiveis(childObj));
        }
    });
}


    
  // CHAMA A API PARA DETERMINAR AS TAGS
  useEffect(() => {
    
    
    let dados = { params: {id_usuario: usuario.id, segmento: disciplinaEscolhida.segmento, disciplina: disciplinaEscolhida.disciplina }};
      
    ModelTags.Listar (dados, async (result) => {
      if (result.isSuccess) {
       
        let a = TratarDados (result.response[0], disciplinaEscolhida.nome, disciplinaEscolhida.segmento);
        
        removerIndisponiveis(a);
      
      
        setTags (a);
      
        props.onFinishLoading (true, null, null);
        console.debug ('Tags carregadas: ok');
      } else {
          console.debug ('Erro ao obter tags:', result.erro + ' - ' + result.msg);
          props.onFinishLoading (false, result.erro, result.msg);
      } 
  })
  }, []);
 
useEffect (() => {
   
   
   
   
  //  props.onFinishLoadingQuestoes ({inicio: loading.inicio, fim: loading.fim, isSuccess: loading.isSuccess})
   
}, [loading.inicio])

  
return (<>
   
    {isMobile ?
    <CardTitulo key="Assuntos" titulo="Assuntos" variantBtn='outlined' divider={true} fontSizeTitulo={18} fontWeightTitulo={600}>
    <TreeListView arvore={tags} selected={tagsEscolhidas} onChange={(escolhidas) => setTagsEscolhidas (escolhidas)} /> 
</CardTitulo>    
    :
    <CardTitulo key="Assuntos" titulo="Assuntos" divider={true} fontSizeTitulo={18} fontWeightTitulo={600}>
        <TreeListView arvore={tags} selected={tagsEscolhidas} onChange={(escolhidas) => setTagsEscolhidas (escolhidas)} /> 
    </CardTitulo>    
}
  </>);
}