import CardTitulo from "../components/view.card.titulo";
import { FormControl, Grid, Box } from "@mui/material";
import RadioBoxView from "../forms/view.form.radio.box";
import SelectMultipleView from "../forms/view.form.select.multiple";
import Letra from "../../views/view.letra";

function FiltrosView (props) {
     const propriedades = props.filtros.propriedades;
     const opcoes = props.filtros.opcoes;
     const selected = props.filtros.selected;
           
     return (
        <>
               
               
               <CardTitulo key="Assuntos" titulo="Filtros" divider={true} fontSizeTitulo={18} fontWeightTitulo={600} mtChildren={1}
               
               
                children = {

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', ml:1, mr:1, mb:1}}  >
                
               
                   
               
                {(props.loading.inicio=== false  && props.loading.fim && props.loading.isSuccess) &&  
                <FormControl  fullWidth>
                    <Grid container spacing={1} >
                    <Box sx={{mt:1}}>
                        <Letra fontSize='0.9em' fontWeight={300} texto="todas as opções estão selecionadas por default" />     
                    </Box>
                        {propriedades.map ((filtro) => {
                            
                            if (filtro.tipo==='select') {
                                return (
                                <Grid item xs={12} lg={12}  sx={{mt:1.5}}>
                                    <SelectMultipleView key={"key_" + filtro.nome} titulo={filtro.titulo} nome={filtro.nome} opcoes={opcoes[filtro.nome]} selected={selected !==undefined ? selected[filtro.nome] : undefined } onChange={(nome, id) => props.onChange (nome, id)} /> 
                                </Grid> 
                                )    
                            }
  
                            if (filtro.tipo==='radio') {
                                return (
                                <Grid item xs={12} lg={12} sx={{mt:1.5}}>
                                    <RadioBoxView key={"key_" + filtro.nome} titulo={filtro.titulo} nome={filtro.nome} label={filtro.label} onChange={(nome, id) => props.onChangeRadioBox (nome, id)} />
                                </Grid>
                                )
                            }
                        })
                        }

                </Grid>
                </FormControl>
                }
                </Box>        
                }
                />     
                
                
     </>
    )

} export default FiltrosView;