// REACT
import { useState, useContext, useEffect } from 'react';

// CONSTANTES
import { VariaveisContext } from '../../services/variaveisProvider';

// MUI COMPONENTS
import { Alert, Box } from '@mui/material';


// COMPONENTES
import SiteFramework from '../../views/components/view.site-framework2.jsx'
import NotificacoesCard from '../../components/NotificacoesCard/NotificacoesCard';
import CardTitulo from '../../views/components/view.card.titulo';

export default function Notificacoes () {
    
    const { novasNotificacoes, setNovasNotificacoes } = useContext (VariaveisContext);
    const [notificacoes, setNotificacoes] = useState (!localStorage.getItem('notificacoes') ? [] : JSON.parse (localStorage.getItem ('notificacoes')));
    
  // VERIFICA SE TEMPOS EM TEMPOS SE HÁ NOVAS NOTIFICAÇÕES NO LOCALSTORAGE
  useEffect(() => {
   // Entrou na página, o número de novas notificações é zerado
   setNovasNotificacoes (0);

         
  }, []);
  
  // ATUALIZA AS NOTIFICAÇÕES
  function AtualizarNotificacoes () {
    if (novasNotificacoes > 0) {
      setNotificacoes (JSON.parse (localStorage.getItem ('notificacoes')));
      setNovasNotificacoes (0);
    }
  }

    
    return (
      <>
      <SiteFramework 
        children = {<>
           
           <CardTitulo titulo="Notificações" divider >
          {/* ************** CARD NOTIFICACOES ************** */}
          {notificacoes.length > 0 ? notificacoes.map ((a, index) => {return <>
            
                   <NotificacoesCard key={index} notificacoes={a} />
                               
                     </>
                }) : 
                <Box component="main" sx={{ mt:-1.5 }}>
                   <Alert severity="info">Nenhuma notificação até o momento</Alert>
                </Box>
                }

           </CardTitulo>
</>} />
   </>       
  );
}  