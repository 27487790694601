// NavBar.js
import React from 'react';
import { useContext, useState } from 'react';
import { IconButton, Toolbar, Typography, Box, Badge } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import LogoNavBar from '../../components/NavBar/LogoNavBar';
import NavBarIcon from '../../components/NavBar/NavBarIcon';
import { VariaveisContext } from '../../services/variaveisProvider';
import { navbarIcons, NAVBAR_TITULO } from '../../data/config/config';
import { Retangulo } from '../../components/NavBar/NavBarFuncoes';
import MenuMudaTema from '../../components/NavBar/MenuMudaTema';
import MenuUsuario from '../../components/NavBar/MenuUsuario';

const NavBar = ({ open, handleDrawerOpen }) => {
  const { isMobile, openMenu, novasNotificacoes } = useContext(VariaveisContext);
  const [anchorEl, setAnchorEl] = useState(null); // menu de mudar tema
  const [top, setTop] = useState(0); 
  const [left, setLeft] = useState(0);
  const [openMudarTema, setOpenMudarTema] = useState(false); // menu de mudar tema
    const [openUsuario, setOpenUsuario] = useState(false); // menu de mudar tema

  function RetornaOnClick (event, text) {
    if (text==='Modo escuro') {Retangulo (event, anchorEl, setTop, setLeft, setOpenMudarTema, openMudarTema, setAnchorEl);}
    if (text==='Usuário') {Retangulo (event, anchorEl, setTop, setLeft, setOpenUsuario, openUsuario, setAnchorEl);}
  }

  return (
    <>
    
    <AppBar position="fixed" open={open}   
       sx={{
        boxShadow: '0',
        //backgroundImage: 'linear-gradient(rgba(255,255,255,0.0), rgba(255,255,255,0.0))'
        
          
       }
       }
       >
      <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style ={{display: 'flex', alignItems: 'center'}}
      
      >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            
            sx={{
              
              ...(isMobile && {marginLeft: -1}),
              ...(!isMobile && {marginRight: 5}),
              ...(!isMobile && {marginLeft: -2}),
              ...(!open && { display: 'inline' }),
             
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!isMobile && !open &&
          <LogoNavBar open={open} isMobile={isMobile} />
        }
          {/* ************* TITULO  ************* */}
          {isMobile && open ? null :
          <Typography style ={{marginLeft: '0.85em', marginTop: '-0.32em'}} variant="h6" sx={{fontWeight:500, mb: -0.6, fontSize: '1.4em'}}>{NAVBAR_TITULO}</Typography>
          }
        
          </div>
          
          {/* ************* ICONS  ************* */}
        <Box sx={{
          ...(isMobile && open && {marginLeft: -2}),
        }} >
        <div style ={{display: 'flex'}}
       
        >  
       
        {navbarIcons.map(([text, icon, link, dica, menu, index]) => (
            text==="Notificações" && novasNotificacoes > 0 ?	
            <StyledBadge badgeContent={novasNotificacoes} color="secondary">
            <NavBarIcon 
              key={text}
              titulo={text}
              index={index}
              Icon={icon}
              link={link}
              dica={dica}
              menu={menu}
              open={openMenu}
             
              
              /></StyledBadge> :

              <NavBarIcon 
              key={text}
              titulo={text}
              index={index}
              Icon={icon}
              link={link}
              dica={dica}
              menu={menu}
              open={openMenu}
              
              onClick={(event) => RetornaOnClick (event, text)}
              />
          ))}
        
        </div>
        </Box> 
      </Toolbar>
      
    </AppBar>
    
     <MenuMudaTema anchorEl={anchorEl} open={openMudarTema} top={top} left={left} setOpen={setOpenMudarTema} />
     <MenuUsuario anchorEl={anchorEl} open={openUsuario} top={top} left={left} setOpen={setOpenUsuario} />
     </>
  );
};

export default NavBar;

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: 10,
      backgroundColor: theme.palette.vermelho.main,
      padding: '0 0px',
    },
  }));

  const drawerWidth = 230;

  const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open' ,
    })(({ theme, open, isMobile }) => ({
        color: theme.palette.primary.main,
         
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen + 700,
      }),
      ...(open &&  {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen + 700,
        }),
      }),
    }));  

    