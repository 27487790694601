import { useContext, useEffect, useState } from "react";
import { VariaveisContext } from "../../services/variaveisProvider";
import { HTML_INICIAL2 } from "../../data/config/config";
import HtmlPainel from "../Gerais/HtmlPainel/HtmlPainel";
import { Card, CardContent, Grid, Chip, Divider, Typography } from "@mui/material";
import CardTitulo from "../../views/components/view.card.titulo";
import  './styles.css'
import Titulo from "../../views/questoesPage/view.titulo";

export default function ExibirQuestoes (props) {
    console.count ('exibir questoes renders');
    const [chip, setChip] = useState (props.escolhida)
    
    useEffect (() => {
      if (props.escolhida) {setChip (true)
    } else {setChip (false)}   
    }, [props.escolhida])

    const { isMobile } = useContext (VariaveisContext);
    const [colunas, setColunas] = useState (props.alinhamento === 'left' ? {enunciado: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}, gabarito : {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}} :
                                                   {enunciado: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}, gabarito : {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}});
    
    useEffect (() => {
        if (props.alinhamento === 'left') {
            setColunas ({enunciado: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}, gabarito : {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}});
        } else {
            setColunas ({enunciado: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}, gabarito : {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}});
        }
    }, [props.alinhamento]);
    
    function tornarImagensResponsivas(html) {
        // Encontrar todas as tags img e modificar seus atributos
        return html.replace(/<img([^>]*)>/g, (match, capture) => {
          // Se a tag já contém um estilo, adicionamos os estilos de responsividade a ele
          if (capture.includes('style="')) {
            return match.replace(/style="/, 'style="max-width: 100%; height: auto; object-fit: contain; ');
          }
          // Se a tag não contém um estilo, adicionamos um novo atributo de estilo
          return `<img style="max-width: 100%; height: auto; object-fit: contain;"${capture}>`;
        });
      }


    function GabaritoObjetiva () {
      if (props.tipo === 1) {
            if (props.gabarito_objetiva === "A" || props.gabarito_objetiva === "B" || props.gabarito_objetiva === "C" || props.gabarito_objetiva === "D" || props.gabarito_objetiva === "E") {
                return "LETRA " + props.gabarito_objetiva;
            } else {
                return "OPÇÃO " + props.gabarito_objetiva;
      }
        }

      if (props.tipo === 3) {
        return "Soma: " + props.gabarito_objetiva;
      }

      return props.gabarito_objetiva;
    }

    return (
        <CardTitulo titulo={"Questão " + props.atual + " de " + props.total} divider fontSizeTitulo='1.1em' chip={chip} >
            
                <Grid container direction="row"  rowSpacing={2} columnSpacing={8} justifyContent={isMobile ? "center" : "flex-start"}>
                    
                    
                    {/* ******** FONTE ANO ******** */}
                    {props.fonte_ano && 
                    <>
                    <Grid item xs={12} >
                        <Chip label={props.fonte_ano} sx={{fontSize: '0.8em', fontWeight: 400, mb:1, mt:-1}} />
                    </Grid>
                    </>
                    }

                    <Grid item xs={colunas.enunciado.xs} sm={colunas.enunciado.sm} md={colunas.enunciado.md} lg={colunas.enunciado.lg} xl={colunas.enunciado.xl} >
                        {/* ******** ENUNCIADO ******** */}
                        <Grid item xs={12} >
                            <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                                Enunciado
                            </Typography>
                            <Divider sx={{mt:1}}/>
                        </Grid> 
                        
                        <Grid item xs={12} >
                        <HtmlPainel  html= {  tornarImagensResponsivas( props.enunciado) }  />
                        <Divider  sx={{mb:2}}  />
                        </Grid>
                    </Grid> 
                    
                    
                       
                    

                    <Grid item xs={colunas.gabarito.xs} sm={colunas.gabarito.sm} md={colunas.gabarito.md} lg={colunas.gabarito.lg} xl={colunas.gabarito.xl} >
                    
                        {/* ******** GABARITO ******** */}
                        {props.gabarito_objetiva &&
                        <>
                        <Grid item xs={12} >
                        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                               Gabarito
                            </Typography>
                            <Divider sx={{mt:1}}/>
                        </Grid>
                        
                        <Grid item xs={12} >
                            <Chip label={GabaritoObjetiva()} sx={{fontSize: 12, fontWeight: 400, ml:-0.5, mt:2}} />
                        </Grid>
                        </>
                        }

                        {/* ******** GABARITO COMENTADO ******** */}
                        {props.gabarito_comentado &&
                        <>
                        <Grid item xs={12} sx={{mt: props.gabarito_objetiva ? 3 : 0}} >
                        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                               Resolução
                            </Typography>
                            <Divider sx={{mt:1}}/>
                        </Grid>
                        
                        <HtmlPainel  html={  tornarImagensResponsivas(props.gabarito_comentado) }  />
                        <Divider />
                        </>
                        }
                    </Grid> 
                    
                </Grid>     
     </CardTitulo>
        );
    }