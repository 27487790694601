import { useEffect, useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function RepetirSenhaField(props) {
  const { disabled, autofocus, senha } = props;
  const [valor, setValor] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [senhaIgual, setSenhaIgual] = useState(true);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };
    
    useEffect(() => {
        props.onValidate (valor, setSenhaIgual)
    }, [valor]);

    function Atualiza (e) {
        setValor (e.target.value);
        props.onChange (e.target.value)
        validarSenha(e.target.value);
    }
    
    function validarSenha(dado) {
        
        if (dado.length > 0) {
        if (dado !== '' && senha !== '') {
          if (dado === senha) {
            setSenhaIgual(true);
          } else {
            setSenhaIgual(false);
          }
        }
        } else {setSenhaIgual (true)}
      }

  return (
    <TextField
        disabled={disabled}
        required
        fullWidth
        margin="normal"
        name="repetirsenha"
        label="Repetir a senha"
        
        value={valor}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>,
        }}

        onChange={(e) => Atualiza(e)}
        error={!senhaIgual}
        helperText={!senhaIgual ? 'As senhas devem ser iguais.' : ''}
         />
      );

} export default RepetirSenhaField;

RepetirSenhaField.defaultProps = {
    disabled: false,
    autofocus: false,
    senha: '',
    onChange: () => {}
}
