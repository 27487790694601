export function Existe (key) {
    if (localStorage.getItem(key)) {
        return true
    } else return false;
}
 
export function Cria (key) {
    localStorage.setItem (key, JSON.stringify({}));
}

export function Abrir (key) {
    //if (!Existe (key)) Cria (key);
        
    return JSON.parse (localStorage.getItem (key));
}

export async function Salvar (key, data) {
    //if (!Existe (key)) Cria (key);
        
    localStorage.setItem (key, JSON.stringify(data));
}

