import { useState, useEffect, useContext } from "react";
import { QuestoesContext } from '../services/questoesProvider';
import * as ls from '../services/service.local-storage'
import * as ModelFiltro from '../models/model.filtro';
import FiltrosView from '../views/assuntosPage/view.filtros';

function Filtros (props) {
     
    const { usuario, filtros, filtrosEscolhidos, setFiltros, setFiltrosEscolhidos, disciplinaEscolhida } = useContext (QuestoesContext);
    const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false});
      
      
      function RetornaFiltros () {
        if (disciplinaEscolhida.idSegmento === 4) {
            return ModelFiltro.ensino_medio}
       
        if (disciplinaEscolhida.idSegmento === 3) {
             return ModelFiltro.ensino_fundamental2}
      }
      // CHAMA A API PARA DETERMINAR OS FILTROS
      useEffect(() => {
       
              
       let a = {};
       let segmento = RetornaFiltros ();
       
       
       segmento.forEach ((filtro) => {
        
        if (filtro.tipo === 'select') {   
           
            ModelFiltro.Listar ({params: {id_usuario: usuario.id}}, filtro.nome, async (result) => {
                   if (result.isSuccess) {
                    
                    a[filtro.nome] = result.response;
                       setFiltros (a);
                       console.debug ('Filtros carregados: ok');
           } else {
                console.debug ('Erro ao obter filtros:', result.erro + ' - ' + result.msg);
                setLoading ({inicio: false, fim: true, isSuccess: false, erro: result.erro, msg: result.msg})
                props.onFinishLoading (false, result.erro, result.msg);
                return;
           }
          })
        }
           })
            
           setLoading ({inicio: false, fim: true, isSuccess: true})
           props.onFinishLoading (true, '', '');

      }, []);
 
  function BooleanIDRadioBox (valor) {
      if (valor==='1') return true;
      if (valor==='2') return false;
      if (valor==='3') return -1;
      return 0;
  }
   
  function onChange (nome, id) { 
    
    let a = [];
    if (nome in filtrosEscolhidos) {a = filtrosEscolhidos[nome]
        const index = a.indexOf(id);
        if (index === -1) {
            a.push (id);
        } else {
            a.splice(index, 1);
        } 
        let b = {...filtrosEscolhidos, [nome]: a}
        setFiltrosEscolhidos(b);
        ls.Salvar ('filtrosEscolhidos', b)
    
    } else {
        let b = {...filtrosEscolhidos, [nome]: [id]}
        setFiltrosEscolhidos(b);
        ls.Salvar ('filtrosEscolhidos', b)
    }
}

  const onChangeRadioBox = (nome, valor) => { 
           
        if (valor==='0') {
            delete filtrosEscolhidos[nome]
            setFiltrosEscolhidos({...filtrosEscolhidos});
            return
        }
        
        let a = [];
        if (nome in filtrosEscolhidos) {a = filtrosEscolhidos[nome]
            a = BooleanIDRadioBox(valor);
            setFiltrosEscolhidos({...filtrosEscolhidos, [nome]: a});
        
        } else {
            setFiltrosEscolhidos ({...filtrosEscolhidos, [nome]: BooleanIDRadioBox(valor)});
        }
    }
     
 return (
        <>
        
        
        
            <FiltrosView 
                            loading={loading}
                            titulo={props.titulo}
                            key={'filtrosview' + props.titulo}
                            filtros={{propriedades: RetornaFiltros(), opcoes: filtros, selected: filtrosEscolhidos}}
                            onChangeRadioBox={(nome, valor) => onChangeRadioBox (nome, valor)} 
                            onChange={(nome, id) => onChange (nome, id)} 
   /> 

     </>
    )

} export default Filtros;