import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { FormatarData } from '../../services/funcoes';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ListaCard(props) {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
 
  return (
    <div > 
      <Accordion sx={{mt: 2}} expanded={expanded === 'panel' + props.key} onChange={handleChange('panel' + props.key)}>
        <AccordionSummary aria-controls={"panel" + props.key + "d-content"} id={"panel" + props.key + "d-header"}>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <Typography variant="subtitle1">{props.nome}</Typography>
          <Typography sx={{mt:0.6}} variant="caption">{FormatarData(props.data)}</Typography>
          </Box>  
        </AccordionSummary>
        
        <AccordionDetails>
          
          <Typography>
            Questões usadas: {props.questoes_total}
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}
