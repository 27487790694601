import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { VariaveisContext } from "../../services/variaveisProvider";
import { TemasContext } from "../../services/temasProvider";
import { QuestoesContext } from "../../services/questoesProvider";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { IconButton, Chip, Button, Card, CardContent, Grid } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BotaoReportarProblema from "./BotaoReportarProblema";
import ModalSucessoErro from "../Gerais/ModalSucessoErro/ModalSucessoErro";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';   
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import SnackbarAviso from "../Gerais/SnackBar/SnackBarAviso";

function BotoesNavegacaoQuestoes (props) {
    
    const navigate = useNavigate ();
    
   const { usuario, questoes, questoesControle, setQuestoesControle, questoesEscolhidas, setQuestoesEscolhidas, filtros, filtrosEscolhidos, tagsEscolhidas, disciplinaEscolhida } = useContext (QuestoesContext);
   var atual = questoesControle.atual; 
   var total = questoesControle.total;
   var total_parcial = questoesControle.total_parcial;

    const { isMobile } = useContext (VariaveisContext);
    const { theme, modo } = useContext (TemasContext);
        
    const [btnLeftDisabled, setBtnLeftDisabled] = useState (true);
    const [btnRightDisabled, setBtnRightDisabled] = useState (questoesControle.total === 1 ? true : false);
    const [openModal, setOpenModal] = useState (false);
    const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
    const [snack, setSnack] = useState (false);
    const snackValores = useRef (null);

    const Btn = styled(IconButton)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[300],
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? grey[700] : grey[400],
      },
    }));
    
    const BtnCustom = styled(Button)(({ theme }) => ({
      
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[300],
      },
    }));

    useEffect (() => {
      
      
      if (questoesControle.atual === questoesControle.total) {
        setBtnRightDisabled (true);
      }

      if (questoesControle.atual === 1) {
        setBtnLeftDisabled (true);
      }

      if (questoesControle.atual > 1 && btnLeftDisabled) {
        setBtnLeftDisabled (false);
      }

      if (questoesControle.atual > 0 && btnRightDisabled) {
        setBtnRightDisabled (false);
      }

      const anchor = document.querySelector(
        '#back-to-top-anchor',
      );
      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
      
    }, [questoesControle.atual]);

    function onClickComprar () {
      setOpenModal (false);
      navigate ('/creditos', {state: "questoes"})
    }
    
    function eQuestaoEscolhidas (q) {
          return questoesEscolhidas.some (item => item.id === questoes [q-1].id);
        }
    
    function MudaQuestao (num) {
        

        // PROXIMA
        if (atual <= total) {
          atual = atual + num;
                      
                   
          if (atual  > total_parcial) {
            setLoading ({...loading, inicio: true, fim: false, isSuccess: false})
            props.onBuscarQuestoes (true);
            
           // setQuestoesControle ({...questoesControle, total_parcial: total_parcial + questoesControle.offset, escolhida: eQuestaoEscolhidas (atual)});
            //ChamarAPI();
          } else {
            setQuestoesControle ({...questoesControle, atual: atual, escolhida: eQuestaoEscolhidas (atual)});
          }
    }
    
  } 

    function handleProximaQuestao (e) {
      e.preventDefault ();  
      MudaQuestao (1);
      e.stopPropagation ();
    }

    function handleQuestaoAnterior (e) {
      e.preventDefault ();  
      MudaQuestao (-1);
      e.stopPropagation ();
    }
     
    function handleSelecionar (e) {
      e.preventDefault ();
     
      if (questoesControle.numEscolhidas + 1 > usuario.numcreditos) {
        setOpenModal (true);
        return;
      }

      let a = [...questoesEscolhidas];
      a.push (questoes [atual - 1]);
      setQuestoesEscolhidas (a);
      setQuestoesControle ({...questoesControle, escolhida: true, numEscolhidas: a.length});
      snackValores.current = {acao: 'selecionar', tipo: 'verde', msg: 'Questão selecionada'}
      setSnack (true);
      e.stopPropagation ();
    }

    function handleRemover (e) {
      e.preventDefault ();
         
      let a = questoesEscolhidas.filter (item => item.id !== questoes [atual - 1].id);
      
      setQuestoesEscolhidas (a);
      setQuestoesControle ({...questoesControle, escolhida: false, numEscolhidas: a.length});
      snackValores.current = {acao: 'remover', tipo: 'vermelho', msg: 'Questão removida'}
      setSnack (true);
      e.stopPropagation ();
    }

    function handleRemoverTodas (e) {
      e.preventDefault ();
      e.stopPropagation ();
      setQuestoesEscolhidas ([]);
      setQuestoesControle ({...questoesControle, escolhida: false, numEscolhidas: 0});
      snackValores.current = {acao: 'remover-todas', tipo: 'vermelho', msg: 'Todas as questões foram removidas'}
      setSnack (true);
    }

    return (<>
       
 
        {isMobile ? 
        
          
          <Grid container sx={{mt:0, mb:1, px:5, backgroundColor: `${theme.palette.background.default}`, borderTopLeftRadius:8, borderTopRightRadius:8}} direction="row" alignItems="center" spacing={1} justifyContent={isMobile ? "center" : "flex-start"}>
              
              {props.btnGerar &&
              <Grid item xs={3} sx={{ml:0}}>
              {props.btnGerar}
              </Grid>
}

              <Grid item xs={2} sx={{ml:2}}>
              <Btn variant="outlined" color='primary' size="medium" onClick={handleQuestaoAnterior} disabled={btnLeftDisabled}>
                  <NavigateBeforeIcon />
                </Btn> 
              </Grid>
              
              <Grid item  xs={2}>
              <Btn variant="contained" color='primary' size="medium" onClick={handleProximaQuestao} disabled={btnRightDisabled}>
                  <KeyboardArrowRightIcon />
                </Btn>
              </Grid>
              
              <Grid item xs={3}sx={{ml:2}}>
                {!questoesControle.escolhida &&
                  <Button variant="text"  onClick={handleSelecionar}>
                    <PlaylistAddCheckIcon sx={{mr:1}}/>
                    <span style={{marginTop: '-0.1em'}}>
                    {questoesControle.numEscolhidas}
                    </span>
                  </Button>
                }

                  {questoesControle.escolhida &&
                  <Button variant="text"  color= 'vermelho' onClick={handleRemover}>
                    <PlaylistRemoveIcon sx={{mr:1}}/>
                    <span style={{marginTop: '-0.1em'}}>
                    {questoesControle.numEscolhidas}
                    </span>
                    </Button>
                }   
                </Grid>

                
                
               
             
              
            </Grid>
           
               
                
                       
        :         <Card  sx={{mt:-1.5, ml: -1, mr: -2, borderRadius:0, backgroundColor: `${theme.palette.background.default}`,       }} >
            <CardContent  sx={{mt:-1.5, mb:-1.5,}}>
            <Grid container    direction="row" alignItems="center" justifyContent="center" spacing={3} >
              <Grid item  >
                <Btn variant="outlined" color='primary' size="medium" onClick={handleQuestaoAnterior} disabled={btnLeftDisabled}>
                  <NavigateBeforeIcon />
                </Btn>
              </Grid>
              
              <Grid item >
              <Btn variant="contained" color='primary' size="medium" onClick={handleProximaQuestao} disabled={btnRightDisabled}>
                  <KeyboardArrowRightIcon />
                </Btn>
              </Grid>
              
              <Grid item >
                {!questoesControle.escolhida &&
                  <BtnCustom variant="text" size="small" onClick={handleSelecionar}>
                    {isMobile ? <PlaylistAddCheckIcon /> : "Selecionar"}
                  </BtnCustom>
                }
                
                {questoesControle.escolhida &&
                  <Button variant="text" size="small" color="vermelho" onClick={handleRemover}  >
                    {isMobile ? <DeleteForeverIcon /> : "Remover"}
                    </Button>
                }    
              </Grid>
              
              <Grid item >
                  <Chip icon={<CheckIcon />} label={questoesControle.numEscolhidas} variant="outlined" />
              </Grid>

              <Grid item  style={{textAlign: isMobile ? 'center' : 'initial'}} >
              <BtnCustom variant="text" color= 'vermelho' onClick={handleRemoverTodas} size="small" sx={{ml: isMobile ? 0 : 4}}>
                {isMobile ? <DeleteForeverIcon /> : "Remover todas"}
                </BtnCustom>
              </Grid>

              <Grid item  style={{textAlign: isMobile ? 'center' : 'initial'}} >
                <BotaoReportarProblema variant="text" isMobile = {isMobile} questao = {questoes [questoesControle.atual-1].id}/>
              </Grid>
            </Grid>
            </CardContent>
 
            
        </Card>
        
}
        {/* Alerta de erro em caso do número de questões escolhidas for maior que o numero de créditos */}
      {openModal ? 
        <ModalSucessoErro open={openModal} success={false} msg={'O número de questões escolhidas não pode ser maior que a quantidade de créditos. Deseja comprar mais créditos?'} 
        data={{errorTipo: "error", btn1: {label: "Voltar", color: "primary", component: null}, btn2: {mostrar: true, label: "Comprar", color: "primary", component: null}}} 
        onClickBtn1={(e) => {e.preventDefault(); setOpenModal(false)}} 
        onClickBtn2={(e) => {e.preventDefault(); onClickComprar() }} 
        onClose={() => setOpenModal(false)} />
      : null}
      </>  
    );
} export default BotoesNavegacaoQuestoes;