import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { QuestoesContext } from '../../services/questoesProvider';
import { Box } from '@mui/material';

import ModalSucessoErro from '../Gerais/ModalSucessoErro/ModalSucessoErro';
import MercadoPagoPagamento from '../MercadoPago/MercadoPago.jsx';


function Payment({ dados }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false});
  const [paymentError, setPaymentError] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentMsg, setPaymentMsg] = useState('');
  const { usuario, setUsuario } = useContext(QuestoesContext);
  
  // Leva para a página de disciplinas se o pagamento for bem sucedido
  const handlePaymentSuccess = (e) => {
    e.preventDefault();
    setPaymentSuccess(true);
    let a = usuario;
    let comprados = JSON.parse (localStorage.getItem ('creditosInfo')).total;
    a.numcreditos = a.numcreditos + comprados; 
    setUsuario (a);

    if (location.state === "questoes") {navigate ('/questoes')}
    else navigate ('/disciplinas');
  };

  function handleFinishPagamento (response, metodo) {
   
    if (response !== null && response !== undefined) {
    
      // PAGAMENTO BEM SUCEDIDO
       if (response.isSuccess && response.statusCode === 201) {
        
        // PIX
        if (metodo === 'pix') {
          localStorage.setItem ('pix', JSON.stringify ({id: response.response.id, code: response.response.qr_code, qr_code: response.response.qr_code_base}))
          navigate ('/pagamento/pix')
          return;
        }
        
        // CARTÃO DE CRÉDITO	
        if (metodo !== 'pix') {
          
          if (response.erro==='') {
          setPaymentMsg ('Pagamento efetuado com sucesso! Os seus créditos já estão prontos para o uso.');
          setPaymentSuccess (true);
        } else {
          setPaymentMsg ('Pagamento não autorizado (' + response.msg + ').');
          setPaymentError (true);
        }

         
        }
       }
      
       
  } else {
    setPaymentMsg ('Ocorreu um erro na comunicação com os nossos servidores.');
    setPaymentError (true);
  }
}

function onReady (valor) {
  if (valor) {
  setLoading ({inicio: false, fim: true, isSuccess: true})
  
  }
}

  return (
    <>
      
      <Box style= {{ml:-2, mr:-2}} >
      <MercadoPagoPagamento mostrar={loading.inicio} dados = {dados} email={usuario.email} idUsuario={usuario.id} onFinish={(response, metodo) => handleFinishPagamento (response, metodo)} onReady={(valor) => onReady(valor)}
                             > 
      </MercadoPagoPagamento>  
      </Box>
     
      {/* Alerta de erro em caso de erro no pagamento */}
      {paymentError ? 
        <ModalSucessoErro open={paymentError} msg={paymentMsg} data={{errorTipo: "error", btn1: {label: "OK", color: "primary", component: null}, btn2: {mostrar: false}}} 
        success={false} onClickBtn1={(e) => {e.preventDefault(); setPaymentError(false)}} onClose={() => {setPaymentError(false);  window.location.reload()}} />
      : null}

      {/* Alerta de sucesso em caso de pagamento realizado com sucesso */}
      {paymentSuccess ? (
        <ModalSucessoErro open={paymentSuccess} msg={paymentMsg} data={{successTipo: "success", btn1: {label: "OK", color: "primary", component: null}, btn2: {mostrar: false}}} 
                          onClickBtn1={(e) => handlePaymentSuccess (e)} onClose={(e) => handlePaymentSuccess(e)} success/>
      ) : null}
    </>

  );
}


export default Payment;


