import { Box, Container, Grid, Typography, Button, Link } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TagFacesOutlinedIcon from '@mui/icons-material/TagFacesOutlined';
import AllInclusiveTwoToneIcon from '@mui/icons-material/AllInclusiveTwoTone';
import { useNavigate } from "react-router-dom";
import Copyright from "../../../components/Copyright/Copyright";
import { COPYRIGHT_NOME, COPYRIGHT_SITE } from "../../../data/config/config";
import LogoNavBar from '../../../components/NavBar/LogoNavBar';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';


import { ReactComponent as Logo } from '../../../data/images/logo2.svg'

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate ();

  const handleClickSuporte = () => {
    navigate ('/ajuda/faleconosco')
  }

  const handleClickSobre = () => {
    navigate ('/sobre')
  }

  const handleClickFAQ = () => {
    navigate ('/ajuda/faq')
  }
  
  

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  
`; 

  return (
    <Box sx={{mt:0, paddingTop: -1, pb:4, bottom: 0, position: 'relative'}} style={{width: '100%', backgroundColor: theme.palette.primary.main}} >
    
    <Container style={{maxWidth: 750}} sx={{mb:0}}>
                        
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection='row' sx={{gap: 2, pt: 3}} >
              <Button size='large' color="primary" variant="contained" onClick={handleClickSobre} >Quem somos</Button>
              <Button size='large' color="primary" variant="contained"  onClick={handleClickFAQ} >Dúvidas</Button>
              <Button size='large' color="primary" variant="contained"  onClick={handleClickSuporte} >Suporte</Button>
          
    </Box>
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection='row' sx={{gap: 2, pt: 3}} >
    
    <Box sx={{mt:-1}} >
    <StyledNavLink to="/" activeClassName="active" >
        
        <Box sx={{mt:1.2}}>
         
        
         <Logo width="45" height="auto" fill={theme.palette.background.paper} style={{paddingTop: '0.2em', paddingBottom: '-0.5em'}}/>

        </Box> 
      </StyledNavLink>
          </Box>           
    
    <Typography variant="body1" color="primary.contrastText" align="center">
        {'Copyright © '}
        {' 2023 - '}
        {new Date().getFullYear()}
        
      </Typography> 
        
    </Box>
    </Container>

   
   
   </Box>
  );
};

export default Footer;