import { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';

import SiteFramework from '../../views/components/view.site-framework2.jsx'
import Loading from '../../components/Gerais/Loading/Loading2';

import CardTitulo from '../../views/components/view.card.titulo';
import Titulo from '../../views/components/view.titulo';
import DisciplinaCard from '../../views/disciplinasPage/view.card';

import { QuestoesContext } from '../../services/questoesProvider';
import { VariaveisContext } from '../../services/variaveisProvider.jsx';
import * as Disciplina from '../../controllers/controller.disciplina.js'
import { useDisciplinas } from '../../models/model.disciplina.js';

export default function Disciplinas () {
  
  const { usuario, setDisciplinas } = useContext (QuestoesContext);
  const { isMobile } = useContext (VariaveisContext);
  const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false});
  const [ disciplinas ] = useDisciplinas ({params: { id_usuario: usuario.id }}, loading.inicio, setLoading)
  
  useEffect (() => {
    setDisciplinas (disciplinas)
  }, [disciplinas]);
     
  
  return (
    <>
    <SiteFramework 
        children = {<>
        
        {/* ************** DISCIPLINAS ************** */}
          
          {/* ************** LOADING ************** */}
          <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Buscando as disciplinas..."} mostrarErroOnlineStatus/> 
                    
          {loading.online && loading.inicio===false && loading.fim && loading.isSuccess && 
          
          <>
            <CardTitulo titulo="Disciplinas" divider >
              
            {Disciplina.VerificaSegmento (disciplinas, 'Ensino Fundamental II') && ( 
                <>
                <Titulo texto='Ensino Fundamental - Anos Finais' fontWeight={500}  divider/>
                 
                <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...isMobile && {justifyContent: 'center'}, ...!isMobile && {justifyContent: 'center'}, flexWrap: 'wrap', mt: 2, mb: 3, p:0 }} >
                {disciplinas.map ((disc, index) => {if (disc.segmento.nome === 'Ensino Fundamental II') {return <>
                     <DisciplinaCard key={disc.id} nome={disc.nome} icon={Disciplina.RetornaIcon (disc.nome, disc.id_segmento)} idDisciplina={disc.id} idSegmento={disc.segmento.id} disciplinaAbreviacao={disc.abreviacao} segmentoAbreviacao={disc.segmento.abreviacao}
                                     questoes = {disc.questoes} cor={Disciplina.RetornaCores(disc.nome)}/>
                   </>
                 }})}
                 </Box>
                </>
                )}
                
           {Disciplina.VerificaSegmento (disciplinas, 'Ensino Médio') && ( 
                <>
                <Titulo texto='Ensino Médio' fontWeight={500} divider/>
                
                <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...isMobile && {justifyContent: 'center'}, ...!isMobile && {justifyContent: 'center'}, flexWrap: 'wrap', mt: 2, mb: 3,p:0 }} >
                {disciplinas.map ((disc, index) => {if (disc.segmento.nome === 'Ensino Médio') {return <>
                    <DisciplinaCard key={index} nome={disc.nome} icon={Disciplina.RetornaIcon (disc.nome, disc.id_segmento)} idDisciplina={disc.id} idSegmento={disc.segmento.id} disciplinaAbreviacao={disc.abreviacao} segmentoAbreviacao={disc.segmento.abreviacao}
                                    questoes = {disc.questoes} cor={Disciplina.RetornaCores(disc.nome)}/>
                    </>
                }})}
               
                </Box>
                </>
                )}
           
        </CardTitulo>
        </>}   
    </>} />
    
    </>
  );
}
