import { Box, Container, Grid, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TagFacesOutlinedIcon from '@mui/icons-material/TagFacesOutlined';
import AllInclusiveTwoToneIcon from '@mui/icons-material/AllInclusiveTwoTone';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';

const Numeros = () => {
  const theme = useTheme();
  
  return (
    <Box sx={{mt:0, pb: 1}} style={{width: '100%', backgroundColor: theme.palette.background.default}} >
    
    <Container style={{maxWidth: 750}} sx={{mb:6}}>
                        
                        {/* TITULO */}
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{flexDirection: 'column'}} >
                            <Typography variant='h4' sx={{mt:5, fontWeight: 500, textAlign: 'center'}}>
                               Números de questões
                            </Typography>
                            <Typography variant='body1' sx={{mt:1}}>
                                Questões novas e inéditas são inseridas diariamente
                            </Typography>
                        </Box>
    </Container>

   
                        
                        {/* CARD */}
                        <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', mt: 2, mb: 3, gap:2 }} >
                      
                            
                      
                            <Box sx={{backgroundColor: grey[800], width: 350, height: 250, borderRadius: 2, p: 2, mb:1}} >
                            <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', mt: 2, mb: 3, gap:2 }} >
                              <BiotechOutlinedIcon sx={{fontSize: 80, color: grey[200]}}/>
                              <Typography variant='h3' sx={{mt:1, color: grey[200], textAlign:'center', fontWeight: 500}}>
                                +10 mil
                              </Typography>
                              </Box>
                              <Typography variant='h5' sx={{mt:-1, color: theme.palette.primary.contrastText, textAlign:'center', fontWeight: 600}}>
                                Exatas
                              </Typography>
                              <Typography variant='body1' sx={{mt:1, color: theme.palette.primary.contrastText, textAlign:'center'}}>
                                Ciências, Matemática, Física e Química
                              </Typography>
                            </Box>
                            
                            <Box sx={{backgroundColor: grey[800], width: 350, height: 250, borderRadius: 2, p: 2, mb:1}} >
                            <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', mt: 2, mb: 3, gap:2 }} >
                              <PublicOutlinedIcon sx={{fontSize: 80, color: grey[200]}}/>
                              <Typography variant='h3' sx={{mt:1, color: grey[200], textAlign:'center', fontWeight: 500}}>
                                +4,7 mil
                              </Typography>
                              </Box>
                              <Typography variant='h5' sx={{mt:-1, color: theme.palette.primary.contrastText, textAlign:'center', fontWeight: 600}}>
                                Humanas
                              </Typography>
                              <Typography variant='body1' sx={{mt:1, color: theme.palette.primary.contrastText, textAlign:'center'}}>
                                Geografia e História
                              </Typography>
                            </Box>

                            <Box sx={{backgroundColor: grey[800], width: 350, height: 250, borderRadius: 2, p: 2, mb:1}} >
                            <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', mt: 2, mb: 3, gap:2 }} >
                              <AutoStoriesOutlinedIcon sx={{fontSize: 80, color: grey[200]}}/>
                              <Typography variant='h3' sx={{mt:1, color: grey[200], textAlign:'center', fontWeight: 500}}>
                                +3,2 mil
                              </Typography>
                              </Box>
                              <Typography variant='h5' sx={{mt:-1, color: theme.palette.primary.contrastText, textAlign:'center', fontWeight: 600}}>
                                Linguagens
                              </Typography>
                              <Typography variant='body1' sx={{mt:1, color: theme.palette.primary.contrastText, textAlign:'center'}}>
                                Língua Portuguesa e Inglês
                              </Typography>
                            </Box>

                      
                            
                      
                        </Box>
             
   
   </Box>
  );
};

export default Numeros;