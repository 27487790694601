import { chamarApi } from '../services/api2';

export function Validos (data, callback) {
    
    const getResult = async () => {
        try {
               
            const result = await chamarApi ({type: 'get', endpoint: 'creditosTotal', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
    }

export function Listar (data, callback) {
    
        const getResult = async () => {
            try {
                
                const result = await chamarApi ({type: 'get', endpoint: 'creditosListar', data: data}) // chama a API
                callback (result);
                
            } catch (error) {
                
                callback (error);
            } 
        }
            getResult();
        }

function Comprar (data, callback) {
    
    const getResult = async () => {
        try {
            
            const result = await chamarApi ({type: 'post', endpoint: 'creditosComprar', data: data}) // chama a API
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
    }      

