import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ModalSucessoErro from '../../components/Gerais/ModalSucessoErro/ModalSucessoErro';
import SiteFramework from '../../views/components/view.site-framework';

import Pix from '../../components/Pix/Pix';

export default function PIXPage () {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const pix = JSON.parse (localStorage.getItem ('pix'));

  function handlePaymentSuccess (e) {
    e.preventDefault ();
    setModalOpen(false);
    navigate ('/disciplinas');
  }

  return (
    
    
    <SiteFramework 
        children = {<>    
        
        <Pix dados = {pix} onFinish={(e) => {e.preventDefault(); setModalOpen(true)}} /> 
         

    

    {/* Alerta de sucesso em caso de pagamento realizado com sucesso */}
    {modalOpen ? (
        <ModalSucessoErro open={modalOpen} msg={"Pagamento realizado com sucesso! Os seus créditos já estão disponíveis para o uso."} 
                          data={{successTipo: "success", btn1: {label: "OK", color: "primary", component: null}, btn2: {mostrar: false}}} 
                          onClickBtn1={(e) => handlePaymentSuccess (e)} onClose={(e) => handlePaymentSuccess(e)} success/>
      ) : null}

</>} />
  );
}