import * as React from "react"
import useOnlineStatus from '@rehooks/online-status';

export function useToggle(defaultValue) {
  const [value, setValue] = React.useState(defaultValue)

  function toggleValue(value) {
    setValue(currentValue =>
      typeof value === "boolean" ? value : !currentValue
    )
  }

  return [value, toggleValue]
}

export function usePrevious(value) {
  const currentRef = React.useRef(value)
  const previousRef = React.useRef()
  if (currentRef.current !== value) {
      previousRef.current = currentRef.current
      currentRef.current = value
  }
  return previousRef.current
}

export function OnlineStatus() {
  const status = useOnlineStatus();
  
  return status;
}


export  function useTimeout(callback, delay) {
    const callbackRef = React.useRef(callback)
    const timeoutRef = React.useRef()
  
    React.useEffect(() => {
      callbackRef.current = callback
    }, [callback])
  
    const set = React.useCallback(() => {
      timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
    }, [delay])
  
    const clear = React.useCallback(() => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }, [])
  
    React.useEffect(() => {
      set()
      return clear
    }, [delay, set, clear])
  
    const reset = React.useCallback(() => {
      clear()
      set()
    }, [clear, set])
  
    return { reset, clear }
  }

  export function useArray(defaultValue) {
    const [array, setArray] = React.useState(defaultValue)
  
    function push(element) {
      setArray(a => [...a, element])
    }
  
    function filter(callback) {
      setArray(a => a.filter(callback))
    }

    function total () {
        let a = array.reduce((a, b) => a + b); 
        return a;
        
    }

    function totalObjeto (chave) {
        
        let a = array.reduce((a, b) => a + (b[chave] || 0), 0);
        return a;
    }
  
    function update(index, newElement) {
      setArray(a => [
        ...a.slice(0, index),
        newElement,
        ...a.slice(index + 1, a.length),
      ])
    }
  
    function remove(index) {
      setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)])
    }
  
    function clear() {
      setArray([])
    }
  
    return { array, set: setArray, push, filter, update, remove, clear, total, totalObjeto }
  }