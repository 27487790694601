import axios from 'axios';
import { endpoint, URLServidorPrincipal } from '../data/config/config';


function RetornaEndpointURL (endpointAlias) {
    return endpoint[endpointAlias].url;
}

function ProcessarErro(err) {
    let msg = ''; let statusCode = 0;
    let erro = 'Erro desconhecido';

    if (err.hasOwnProperty("response"))
       {
        statusCode = err.response.status;
        
        if (err.response.data.msg !== undefined && err.response.data.msg !== null) 
         {
            msg = err.response.data.msg;
            erro = err.response.data.erro;
            
            if (err.response.status === 403) {
            erro = "Acesso não autorizado."
            msg = "Faça login novamente."
            document.location = "/conta/login";
        }
    
        statusCode = err.response.status;
    }                 
} else {
    erro = "Servidor fora do ar";
    msg = "Tente novamente mais tarde.";
    statusCode = 503;
}
        
  return { statusCode, msg, erro };
}

async function ApiCall(apiInfo) {
    
    const { endpoint, data, type } = apiInfo;
    const sessionToken = localStorage.getItem('usuario') ? JSON.parse (localStorage.getItem('usuario')).token : null;

    const api = axios.create({
        //baseURL: URLServidorPrincipal(),
        headers: { Authorization: `Bearer ${sessionToken}`,
        AcceptEncoding: 'gzip, deflate, br',
            ContentType: 'application/json',
            Accept: "*/*",
            AccessControlAllowOrigin: "*",
    },
    });
    
        let result = { };
        
         
        try {
            let a = {};
            if (type === 'get')   {const response = await api.get    (RetornaEndpointURL (endpoint), data); a = response};
            if (type === 'patch') {const response = await api.patch  (RetornaEndpointURL (endpoint), data); a = response};
            if (type === 'post')  {const response = await api.post   (RetornaEndpointURL (endpoint), data); a = response};
            if (type === 'put')   {const response = await api.put    (RetornaEndpointURL (endpoint), data); a = response};
                        console.debug (a.data)
            result.statusCode = a.status;
            result.response = a.data;
            result.isSuccess = true;
            result.msg = a.data.msg;
            if (a.data.erro) {result.erro = a.data.erro};
            result.fim = true;
            result.conectou = true;
            

        } catch (err) {
                       
           // let error = ProcessarErro (err);
            console.debug (err);
            result.statusCode = err.statusCode;
            result.erro = err.erro;
                        
            result.isSuccess = false;
            result.msg = err.msg;
            result.fim = true;
            result.conectou = false;
            
        } finally {
            result.inicio = false;
            result.fim = true;
            
            return result;
        }
    };
 
export default ApiCall;

export async function ApiCallWord(apiInfo) {
    
    const { endpoint, data, type } = apiInfo;
    const sessionToken = localStorage.getItem('usuario') ? JSON.parse (localStorage.getItem('usuario')).token : null;

    const api = axios.create({
        baseURL: URLServidorPrincipal(),
        responseType: 'blob',
        headers: {Authorization: `Bearer ${sessionToken}`, 
            AcceptEncoding: 'gzip, deflate, br',
            ContentType: 'application/json',
            Accept: "*/*",
            AccessControlAllowOrigin: "*",
    },
    });
    
        let result = { };
        
         
        try {
            let a = {};
            if (type === 'get') {const response = await api.get  (RetornaEndpointURL (endpoint), data); a = response};
            if (type === 'post') {const response = await api.post (RetornaEndpointURL (endpoint), data); a = response};
            if (type === 'put')  {const response = await api.put  (RetornaEndpointURL (endpoint), data); a = response};
                        
            result.statusCode = a.status;
            result.response = a.data;
            result.isSuccess = true;
            result.modalMsg = a.data.msg;
            result.fim = true;
            result.conectou = true;

        } catch (err) {
          
            let error = ProcessarErro (err);
            
            result.statusCode = error.statusCode;
            result.error = err;
            result.isSuccess = false;
            result.modalMsg = error.msg;
            result.fim = true;
            result.conectou = false;
            
        } finally {
            result.inicio = false;
            result.fim = true;
            
            return result;
        }
    };
 


export async function chamarApi (api) {
    
    const result = await ApiCall (api);
    
    return result;
}


