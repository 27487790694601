import SiteFramework from '../../views/components/view.site-framework2';
import PaymentResumo from '../../components/Payment/PaymentResumo';
import Payment from '../../components/Payment/Payment';
import { Box } from '@mui/material';


function PaymentPage({setEstado}) {
  
const creditosInfo = JSON.parse (localStorage.getItem ('creditosInfo'));
  
return (
  <>  
      
      <SiteFramework 
        children = {<>
          
          <PaymentResumo valores = {creditosInfo} />
          
          <Box style= {{ml:-2, mr:-2, px:-2}} >
          <Payment dados = {creditosInfo} />
         </Box>
      </>} />  
  </>
  );
}

export default PaymentPage;