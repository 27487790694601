// Formata um número para o formato de moeda brasileira. Exemplo: 1000 -> R$ 1.000,00
export function RetornaStringMoedaReal (numero) {
        
    // Verifica se tem apenas uma casa decimal e, caso afirmativo, acrescenta um zero
    let valor = String(numero);
    var partes = valor.split(',');
    if (partes[1] && partes[1].length === 1) {
        valor += '0';
    }

    if (!partes[1]) {
        valor += ',00';
    }

    // acrescenta R$
    valor = 'R$ ' + valor;
    
    return valor;
}