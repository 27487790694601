import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material/';

import { ReactComponent as Logo } from '../../data/images/logo2.svg'
import { useContext } from 'react';
import { TemasContext } from '../../services/temasProvider';

function LogoNavBar(props) {

const {mode, theme} = useContext (TemasContext);

function IconColorInactive (theme) {
    if (mode==='light') {
      
      return theme.palette.primary.dark;
    } else {
      return theme.palette.primary.dark;
    }
}

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color:  ${({ theme }) => IconColorInactive (theme)};

  &.active {
    color: ${({ theme }) => '#FFFFFF'};
  }
`; 

function cor (theme) {return theme.palette.primary.main}

 
    return (
        <StyledNavLink to="/" activeClassName="active" >
        { props.open ? 
        <Box sx={props.isMobile ? {mt:1} : {mt :2}}>
         
        
         <Logo width="45" height="auto" fill={cor(theme)} style={{paddingTop: '0.5em', paddingBottom: '0.0em'}}/>

        </Box> :
        <Box sx={props.isMobile ? {mt:1} : {mt : 0.7}}>
         <Logo width="45" height="auto" fill={cor(theme)} style={{paddingTop: '0.0em', paddingBottom: '0.0em'}}/>
      
      </Box>
}
      </StyledNavLink>
  );
} export default LogoNavBar;