import './LoginStyles.css';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { chamarApi } from '../../services/api2';

import Login from '../../components/Login/Login';
import CriarConta from '../../components/Login/CriarConta';

import { Box, useTheme } from '@mui/material';

import SnackbarAviso from '../../components/Gerais/SnackBar/SnackBarAviso';

import { TemasContext } from '../../services/temasProvider';
import { QuestoesContext } from '../../services/questoesProvider';
import { VariaveisContext } from '../../services/variaveisProvider';
import { AlteraEstado } from './funcoes'



const LoginPage = () => {
  const [estado, setEstado] = useState(0);
  const [dadosLogin, setDadosLogin] = useState({ email: '', senha: '' });
  const [dadosCriarConta, setDadosCriarConta] = useState({ nome: '', sobrenome: '', email: '', senha: '', repetirSenha: '' });
  const [loading, setLoading ] = useState ({fim: false})
  const { setUsuario } = useContext(QuestoesContext); // usuario
  const { setColorTema } = useContext(TemasContext);
  const { isMobile } = useContext(VariaveisContext);

  const navigate = useNavigate ();
  const theme  = useTheme();

  useEffect (() => {
    setColorTema ('Clássico'); 
   }, []);

  // Função para chamar a API de login ou cadastro de nova conta 
  //async function callApi(url, data) {
    function callApi(tipo) {

    const getResult = async () => {
      let data = {}
      if (tipo==='login') {
      data = {email: dadosLogin.email, senha: dadosLogin.senha}}
      else {data = {nome: dadosCriarConta.nome, sobrenome: dadosCriarConta.sobrenome, email: dadosCriarConta.email, senha: dadosCriarConta.senha}}
      
      let a = '';
      if (tipo==='login') {a = 'usuarioLogin'} else {a = 'usuarioCadastrar'}
      const result = await chamarApi ({type: 'post', endpoint: a, data: data}) // chama a API
      setLoading (result);

      if (result.isSuccess) {
        // SALVAR DADOS DO USUÁRIO NO LOCAL STORAGE
        
        setUsuario (result.response);
        localStorage.setItem ('usuario', JSON.stringify(result.response))
        navigate ('/home', { state: { eLOGIN: true } });
      } 
      }  
      
      getResult();
    }
    
 const handleLogin = (event) => {
    event.preventDefault();
    setLoading({...loading, inicio: true}); // inicia estado de loading
   
    callApi ("login"); // chama a API de login
  };

  const handleCriarConta = (event) => {
    event.preventDefault();
    setLoading({...loading, inicio: true}); // inicia estado de loading
    
    callApi ("cadastrar"); // chamada a API de cadastro de nova conta
  };

  return (
    <>
    
    <div className="body-background overlay" >
    <div style={{backgroundColor: 'rgba(254, 252, 244, 0.08)', backdropFilter: 'sepia(75%)', position: 'absolute', top:0, height:'100%', width:'100%'}} >
      <Box sx={{ maxWidth: isMobile ? '80%' : 500, mx: 'auto', px: 5,  marginTop: 8, marginBottom: 5, display: 'flex',  flexDirection: 'column', alignItems: 'center', 
                 borderRadius: '4px', border: 'solid', borderWidth: '1px', borderColor: 'rgba(0,0,0,0.12)', paddingY: '1em', 
                 backgroundColor: theme.palette.background.paper
      }} variant="outlined">
      
          {estado === 0 && (
            <Login isMobile={isMobile} handleLogin={handleLogin} estado={estado} alterarParaCriarConta={(e) => AlteraEstado (e, 1, setEstado, setDadosLogin, setDadosCriarConta)} dadosLogin={dadosLogin} setDadosLogin={setDadosLogin} isLoading={loading.inicio} />
          )}
          {estado === 1 && (
            <CriarConta isMobile={isMobile} handleCriarConta={handleCriarConta} estado={estado} alterarParaLogin={(e) => AlteraEstado (e, 0, setEstado, setDadosLogin, setDadosCriarConta)} dadosCriarConta={dadosCriarConta} setDadosCriarConta={setDadosCriarConta} isLoading={loading.inicio} />
          )}
     </Box>
     
     {/* Alerta de erro ou sucesso */} 
     <SnackbarAviso open={loading.fim} tipo={loading.isSuccess ? 'success' : 'error'} msg={loading.modalMsg} onClose={() => setLoading({...loading, fim: false})}></SnackbarAviso>
     </div> 
    </div>
    </>
  );
};

export default LoginPage;
