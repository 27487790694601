import { useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { FormControlLabel, Checkbox } from '@mui/material';

function TreeListView (props) {
    
    const arvore = props.arvore;
    const [selected, setSelected] = useState (props.selected);
    const [iDsExpandidas, setIDsExpandidas] = useState ([0]);
    

   function getChildById (nodes, id) {
        let array = [];
    
    function getAllChild(nodes) {
        if (nodes === null) return [];
        array.push(nodes.id);
        if (Array.isArray(nodes.children)) {
        nodes.children.forEach(node => {
            array = [...array, ...getAllChild(node)];
            array = array.filter((v, i) => array.indexOf(v) === i);
        });
        }
        return array;
    }
    
    
    function getNodeById(nodes, id) {
        if (nodes.id === id) {
        return nodes;
        } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach(node => {
            if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
            }
        });
        return result;
        }

        return null;
    }

    return getAllChild(getNodeById(nodes, id));
    }
    
    function areAllChildrenSelected(nodes, parentId) {
        const childNodeIds = getChildById(nodes, parentId);
        return childNodeIds.every(id => selected.includes(id));
    }
    
    
    function getParentId(nodes, id) {
    let parentId;
    function findParent(node) {
        if (Array.isArray(node.children)) {
        node.children.forEach(child => {
            if (child.id === id) {
            parentId = node.id;
            } else {
            findParent(child);
            }
        });
        }
    }
    findParent(nodes);
    return parentId;
    }
          
    function getOnChange(checked, nodes) {
      if (nodes.id === 0) return;
      const allNode = getChildById(arvore, nodes.id);
      let array;
      if (checked) {
        array = [...selected, ...allNode];
        
        let parentId = getParentId(arvore, nodes.id);
        while (parentId !== 0) {
          if (!iDsExpandidas.includes(parentId)) {
            iDsExpandidas.push (parentId);
            //UpdateTagsExpandidas ();
          }
          parentId = getParentId(arvore, parentId);
        }
        setIDsExpandidas (iDsExpandidas)    
        
        /*
        const parentId = getParentId(data, nodes.id);
        if (parentId && areAllChildrenSelected(array, parentId)) {
          array = [...array, parentId];
        }
          */
        
      } else {
        array = selected.filter(value => !allNode.includes(value));
        
        
        const parentId = getParentId(arvore, nodes.id);
        if (parentId) {
          array = array.filter(id => id !== parentId);
        }
      }
      array = array.filter((v, i) => array.indexOf(v) === i);
      setSelected(array);
      //UpdateEscolhidas (array);
      props.onChange (array);

      //if (array.length === 0) {setBtnDisabled (true)} else {setBtnDisabled (false)}
      
    }
     
    
    const renderTree = (nodes) => (
      <>
      { 
      
        <TreeItem 
        key={nodes.id} 
        nodeId={nodes.id}
        label={
            nodes.id === 0 ? nodes.name :
            
            <FormControlLabel
              control={
                
                <Checkbox
                  checked={Array.isArray(selected) && selected.some(item => item === nodes.id)}
                  onChange={event =>
                    getOnChange(event.currentTarget.checked, nodes)
                  }
                  onClick={e => e.stopPropagation()}
                />
                
              }
              
              label={nodes.name}
              key={nodes.id}
            />
          }
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
}</>
      );

    return (
        <>
        <TreeView 
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={iDsExpandidas}
      
      defaultExpandIcon={<ChevronRightIcon />}
     
      sx={{ flexGrow: 1, overflowY: 'auto', mt:2,mb:2 }}
    >
      {renderTree(arvore)}
      
    </TreeView>
    </>
        
       

)} export default TreeListView;