import { useState } from "react";
import { Typography, Select, OutlinedInput, MenuItem, Chip, Box } from "@mui/material";

function SelectMultipleView (props) {
    //const [selected, setSelected] = useState (props.selected!==undefined && props.opcoes!==undefined ? props.selected : []); // [
    //const [selected2, setSelected2] = useState (props.selected!==undefined && props.opcoes!==undefined ? RetornaOpcao (props.opcoes, props.selected, "id", "nome")  : []);
    const [selected2, setSelected2] = useState ([]);
    const opcoes = props.opcoes!==undefined ? handleOpcoes() : []; 
    

    const [, setRenderiza] = useState (Date.now());

    function handleOpcoes () {
        if (props.opcoes!==undefined) {
          props.opcoes.forEach ((opcao) => {
            if (opcao.hasOwnProperty('nome')) {
              opcao.name = opcao.nome;
              delete opcao.nome;
              delete opcao.segmento;
            }
          })
        }
        
        return props.opcoes;
    }


    useState (() => {
     setRenderiza();
   })
  
   const handleChange = (event, child) => {
   
      const {
      target: { value }
    } = event;
       
       setSelected2(
       //typeof value === 'string' ? value.split(',') : value
       value
    );
   
      // setSelected (props.nome, event.explicitOriginalTarget.id); 
     
      props.onChange (props.nome, child.props.id);  
          

       //props.onChange (props.nome, value);  
  };
  
   return (<>
        
        {props.titulo!==undefined &&
        <Typography variant="body2" component="div">
            {props.titulo}    
        </Typography>
}
       {opcoes.length > 0 &&
        <Select
          sx={{mt:0.3}}
          fullWidth
          shink
            
          id={props.nome + "-select"}
          multiple
          value={selected2}
          onChange={handleChange}
          input={<OutlinedInput id={props.nome + "select-multiple-chip"} label="Chip" />}
          
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.4 }}>
              {selected.map((value) => (
                <Chip key={value} label={value}  />
              ))}
            </Box>
          
          )}
        >
          {opcoes.map((opcao, index) => (
            <MenuItem key={opcao.name} value={opcao.name} id={opcao.id}>
              {opcao.name}
            </MenuItem>
          ))}
        </Select>  
        
} 
        </>
    )

} export default SelectMultipleView;