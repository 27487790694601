import { useState, useContext } from 'react';
import { QuestoesContext } from '../../services/questoesProvider';

import { TextField, Box,  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { chamarApi } from '../../services/api2';

import ModalSucessoErro from '../../components/Gerais/ModalSucessoErro/ModalSucessoErro';
import CardTitulo from '../../views/components/view.card.titulo';
import Titulo from '../../views/components/view.titulo';
import SiteFramework from '../../views/components/view.site-framework2.jsx'
import { useNavigate } from 'react-router-dom';


export default function Contato () {
  
  const navigate = useNavigate ();
  const { usuario } = useContext (QuestoesContext);
  const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
  const [dadosMsg, setDadosMsg] = useState({ msg: '' });
  const [botaoLigado, setBotaoLigado] = useState(false);
  const [open, setOpen] = useState (false);

  
  
  function AtualizaCampos(e, setDadosMsg, setBotaoLigado) {
    setDadosMsg({ msg: e.target.value });
    if (e.target.value.trim()) {
        setBotaoLigado(true);
    } else {
        setBotaoLigado(false);
    }
}
  
    function handleClose (e) {
        e.preventDefault();
        setOpen (false);
        if (loading.inicio===false && loading.fim && loading.isSuccess) {
          navigate ('/home')
       }
    }
    
    function Enviar (e) {
        e.preventDefault();
        setLoading ({...loading, inicio: true, fim: false, isSuccess: false});
        // CHAMA A API
        
        const getResult = async () => {
          
          let data = {id_usuario: usuario.id, texto: dadosMsg.msg};
          const result = await chamarApi ({type: 'put', endpoint:'mensagemEnviar', data: data}) // chama a API
          
        if (result.isSuccess) {
            console.debug ('Mensagem enviada com sucesso');
            setLoading ({...loading, inicio: false, fim: true, isSuccess: true, modalMsg: "Mensagem enviada com sucesso! Em breve, entraremos em contato."});
          } else {
            console.debug ('Erro ao enviar a mensagem: ', result.erro + ' - ' + result.msg);
            setLoading ({...loading, inicio: false, fim: true, isSuccess: false, modalMsg: "Erro ao enviar a mensagem. Tente novamente mais tarde."});
          }

            
        }
        getResult();
        setOpen (true);
    }  
  return (

    <>
    {loading.inicio===false && loading.fim && open &&
    <ModalSucessoErro open={open} msg={loading.modalMsg} 
    data={{errorTipo: "error", successTipo: "success", btn1: {label: "OK", color: "primary", component: null}, 
            btn2: {mostrar: false}}} 
                            onClickBtn1={(e) => handleClose (e)} onClose={(e) => handleClose(e)} 
                            success={loading.isSuccess ? true : false} 
                            />
}
    
<SiteFramework 
        children = {<>
        {/* ************** CARD ************** */}
        <CardTitulo titulo="Contato" divider 
          children={<>
            <Titulo texto='Mensagem' divider/>

            <TextField
            sx={{mt: 3}}
            fullWidth
            id="mensagem"
            label="Escreva a sua mensagem aqui"
            variant="outlined"
            value={dadosMsg.mensagem}
            onChange={(e) => AtualizaCampos (e, setDadosMsg, setBotaoLigado)}
            multiline
            rows={4} />
          <Box display="flex" justifyContent="center" sx={{ mt: 3, mb:2 }}>
            <LoadingButton
              disableElevation
              loading={loading.inicio}
            
              variant="contained"
              color="primary"
              disabled={!botaoLigado}
              onClick={(e) => Enviar(e)}
            >
              <span>Enviar</span>
            </LoadingButton>
          </Box>
          </>}/>  
          </>} /> 
    </>
  );
} 