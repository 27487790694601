export function TratarDados (dados, disciplina, segmento) {
    dados.name = disciplina + ' - ' + segmento;   
    return percorrerEsubstituir(dados);
 } 
 
 function percorrerEsubstituir (obj) {
 // Base case: se o objeto não for um objeto ou for null, retorne
 if (typeof obj !== 'object' || obj === null) return;
 
 // Encontre todas as chaves que correspondem à regex
 const regex = /TAG/;
 const chavesCorrespondentes = Object.keys(obj).filter(chave => regex.test(chave));
 
 // Para cada chave correspondente, faça a substituição e delete a chave original
 chavesCorrespondentes.forEach(chaveCorrespondente => {
   Object.defineProperty(obj, "children", Object.getOwnPropertyDescriptor(obj, chaveCorrespondente));
   delete obj[chaveCorrespondente];
 });
 
 // Agora, percorra cada propriedade do objeto
 for (let chave in obj) {
   // Se a propriedade for um objeto, chame a função recursivamente
   if (typeof obj[chave] === 'object') {
   percorrerEsubstituir(obj[chave]);
   }
 }
 return obj;
 }

