import { useState, useContext, useRef } from "react";
import { QuestoesContext } from "../services/questoesProvider";
import * as ModelCredito from '../models/model.credito.js'
import * as ModelLista from '../models/model.lista.js'
import * as ModelQuestao from '../models/model.questao'
import Loading from '../components/Gerais/Loading/Loading';
import * as ls from '../services/service.local-storage';

function HomeController (props) {
    
    const { usuario, setUsuario } = useContext(QuestoesContext);
    const terminou = useRef({credito: {loading: true, sucesso: false}, questao: {loading: true, sucesso: false}, lista: {loading: true, sucesso: false}});
    const [loading, setLoading] = useState({inicio: true, fim: false, isSuccess: false});
    
    
    function ChamarAPI () {
        props.onFinishLoading (false);
        setLoading ({inicio: true, fim: false, isSuccess: false});
        
        let dadosListar = {};
        dadosListar.params = {id_usuario: usuario.id};

        ModelCredito.Validos (dadosListar, async (result) => {
            
            if (result.isSuccess) {
                console.debug ('Créditos válidos', result.response);
                onFinishLoading (true, 'credito')
                usuario.numcreditos = result.response;
                ls.Salvar ('usuario', usuario )
             
            } else {
                console.debug ('Erro ao obter créditos válidos:', result.erro + ' - ' + result.msg);
                onFinishLoading (false, 'credito')
            } 
    })  

        ModelLista.Total (dadosListar, async (result) => {
            if (result.isSuccess) {
                console.debug ('Listas total', result.response);
                onFinishLoading (true, 'lista')
                usuario.numprovas = result.response;
                ls.Salvar ('usuario', usuario )
            } else {
                console.debug ('Erro ao obter o total de listas:', result.erro + ' - ' + result.msg);
                onFinishLoading (false, 'lista')
            } 
    })
    
        ModelQuestao.Total (dadosListar, async (result) => {
            if (result.isSuccess) {
                console.debug ('Questões total', result.response);
                onFinishLoading (true, 'questao')
                usuario.numquestoes = result.response;
                ls.Salvar ('usuario', usuario )
            } else {
                console.debug ('Erro ao obter o total de questoes:', result.erro + ' - ' + result.msg);
                onFinishLoading (false, 'questao')
            } 
    })  
    }
    
        
    function onFinishLoading (resultado, tipo) {
       
        if (resultado) {
            terminou.current [tipo].sucesso = true;
            terminou.current [tipo].loading = false;
        } else {
            terminou.current [tipo].sucesso = false;
            terminou.current [tipo].loading = false;
            setLoading ({inicio: false, fim: true, isSuccess: false});
            props.onFinishLoading (false);
            return;
            
        }
        
        if (!terminou.current.credito.loading && !terminou.current.lista.loading && !terminou.current.questao.loading) {
            if (terminou.current.credito.sucesso && terminou.current.lista.sucesso && terminou.current.questao.sucesso) {
               
                props.onFinishLoading (true);
                setLoading ({...loading, inicio: false, fim: true, isSuccess: true })
            }
            if (!terminou.current.credito.sucesso && !terminou.current.lista.sucesso && !terminou.current.questao.sucesso) {
                props.onFinishLoading (false);
                setLoading ({...loading, inicio: false, fim: true, isSuccess: false })
            } 
        }
    }


    return (
        <>
            <Loading tipo="simples" loading={loading} onlineStatus={(valor) => valor ? ChamarAPI() : null} show={props.show} msg={"Carregando seus dados..."}/> 
        </>
    )

} export default HomeController;

