import { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import SiteFramework from '../../views/components/view.site-framework2.jsx'

import { TemasContext } from '../../services/temasProvider';	
import { VariaveisContext  } from '../../services/variaveisProvider';
import { QuestoesContext } from '../../services/questoesProvider';

import { Box } from '@mui/material';

import ModalSucessoErro from '../../components/Gerais/ModalSucessoErro/ModalSucessoErro';

import Card from '../../views/homePage/view.card';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import Titulo from '../../views/homePage/view.titulo';
import HomeController from '../../controllers/controller.home';

export default function Home() {
  
  const location = useLocation();
  const { usuario } = useContext(QuestoesContext); // usuario
  const { setColorTema } = useContext(TemasContext);
  const { isMobile } = useContext(VariaveisContext); 
  
  const [creditosParabensModal, setCreditosParabensModal] = useState (PrimeiroAcesso());
  const [show, setShow] = useState (false);

  console.count ('home')
  
  useEffect (() => {
    if (location.state && location.state.eLOGIN) {   
      setColorTema ('Clássico');
    }
   }, []);
   

// DETERMINAR SE É PRIMEIRO ACESSO
  function PrimeiroAcesso () {
    let resultado = false;
    usuario.primeiro_acesso===true  ? resultado = true : resultado = false;
    return resultado; 
  }

// FECHAR MODAL
  const fecharModal = (e) => {
    e.preventDefault();
    usuario.primeiro_acesso=false;
    setCreditosParabensModal (false);
  } 
 
  return (
    <>
    
    <SiteFramework 
      
      children = {<>
                  
      {/* ************** MODAL PARABENS PRIMEIRO ACESSO - CREDITOS GRATIS ************** */}
      {PrimeiroAcesso() && usuario.numcreditos > 0 ? 
        <ModalSucessoErro open={creditosParabensModal} msg={'Parabéns! Você recebeu ' + usuario.numcreditos + ' créditos de bônus!'} 
       data = {{successTipo: "success",  btn1: {label: "OK", color: "primary"}, btn2: {mostrar: false}}} success={true} onClickBtn1={(e) => fecharModal (e)} onClose={(e) => fecharModal (e)}  />
      : null}
        
        <HomeController onFinishLoading = {(resultado) => resultado ? setShow (true) : setShow (false) } show={show}/>

        {show &&
        <> 
          <Titulo />
          
          <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...isMobile && {justifyContent: 'center'}, ...!isMobile && {justifyContent: 'flex-start'}, flexWrap: 'wrap', mt: 5,  gap: 2 }} >
            <Card num={usuario.numprovas}  icon={<FormatListNumberedOutlinedIcon sx={{color: "success.main"}} fontSize="large" />} texto="Listas criadas" cor_clara='success.light' cor_escura='success.dark' />
            <Card num={usuario.numquestoes} icon={<InsertChartOutlinedIcon sx={{color: "info.main"}} fontSize="large" />} texto="Questões utilizadas" cor_clara='info.light' cor_escura='info.dark'/>
            <Card num={usuario.numcreditos} icon={<ShoppingBagOutlinedIcon sx={{color: "error.main"}} fontSize="large" />} texto="Créditos" cor_clara='error.light' cor_escura='error.dark'/>
          </Box>

          
       </>}
       

      
     
      </>} /> 
      </>
        
    
    
         
  );
}
