import { Container, Box, Typography } from "@mui/material";
import NavBar from "./Componentes/NavBar";
import { useTheme } from "@mui/material";
import sobre from './sobre.jpg';
import Footer from "./Componentes/Footer";

function SobrePage() {
    const theme = useTheme ();

  return (
       <>
       <NavBar />
       <Box style={{width: '100%', height: '93vh', backgroundColor: theme.palette.background.default}} >
       <Container style={{maxWidth: 800}} sx={{mb:6}}>
                        
            {/* TITULO */}
            <Box display="flex" alignItems="center" justifyContent="center" sx={{flexDirection: 'column'}} >
                <Typography variant='h4' sx={{mt:4, fontWeight: 500}}>
                    Quem somos
                </Typography>
                <Typography variant='body1' sx={{mt:1}}>
                    Um banco de questões criado por um professor para os professores
                </Typography>
            </Box>
            <img src={sobre} style={{width: '90%', height: 'auto', marginLeft: '5%', marginRight: '5%', marginTop: '3em'}} alt="Professora" />
            {/* TEXTO */}
            <Box display="flex" alignItems="center" justifyContent="start" sx={{flexDirection: 'column', mt:4}} >
                <Typography variant='body1' sx={{textAlign: 'justify'}}>
                    Bem-vindo ao FazProva, a sua plataforma de banco de questões inovadora, criada e desenvolvida por um dedicado professor com o objetivo de transformar o modo como professores e alunos interagem com o ensino. 
                    Nossa missão é simplificar e enriquecer o processo de aprendizagem para todas as disciplinas do Ensino Fundamental Anos Finais (Ciências, História, Geografia, Língua Portuguesa, Inglês, Matemática, Física e Química) e do Ensino Médio (Física).   
                </Typography>
                
                <Typography variant='body1' sx={{textAlign: 'justify', mt:3}}>
                    O FazProva nasceu da visão de um professor apaixonado pela educação, que reconheceu a necessidade de recursos mais eficientes e acessíveis para o ensino. 
                    Com anos de experiência em sala de aula, ele desenvolveu uma plataforma intuitiva que facilita a elaboração de provas e listas de exercícios para estimular o aprendizado dos estudantes.
                </Typography>
                
                <Typography variant='body1' sx={{textAlign: 'justify', mt:3}}>
                    Com um vasto banco de questões cuidadosamente elaboradas, o FazProva oferece um recurso inestimável para os educadores. Nossas questões são constantemente atualizadas e revisadas para garantir a máxima qualidade e relevância. A plataforma é intuitiva, permitindo que os professores criem e personalizem avaliações de acordo com as necessidades específicas de suas turmas.
                </Typography>
                 
                <Typography variant='body1' sx={{textAlign: 'justify', mt:3}}>
                    No FazProva, estamos comprometidos com a educação de qualidade. Acreditamos que um bom recurso educacional pode fazer a diferença na vida de alunos e professores. Portanto, estamos sempre buscando inovar e melhorar, mantendo-nos alinhados com as últimas tendências e metodologias de ensino.
                </Typography>

                <Typography variant='body1' sx={{textAlign: 'justify', mt:3}}>
                    Junte-se a nós nessa jornada educacional e descubra como o FazProva pode transformar sua experiência de ensino e aprendizado!
                </Typography> 

                
            </Box>

            
       </Container>
       <Footer />
       </Box>    
        </> 
       
    
  );
} export default SobrePage;