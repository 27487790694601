import { useContext } from "react";
import { ListItem, ListItemButton, ListItemText, ListItemIcon, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { VariaveisContext } from "../../../services/variaveisProvider";

function MenuItem (props) {
    
    const {text, open, index, Icon, link, selected, divisor, isMobile, setOpen} = props;
    const {setOpenMenu} = useContext(VariaveisContext);

    const navigate = useNavigate();

    const handleClick = () => {
        props.onClick (text);
     //   if (isMobile) {setOpen (false)};
        //setOpen (true);
        navigate(link, { state: { selected: text } });
    };
    
    const StyledListItemIcon = styled(ListItemIcon, {
        shouldForwardProp: (prop) => prop !== 'minhaCondicao',
      })(({ selected, theme }) => ({
        color: selected ? theme.palette.primary.contrastText : 'inherit',
      }));
   
 
    
    return (
        <>    
        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={handleClick}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: selected ? 'primary.main' : 'inherit',
                  color: selected ? 'primary.contrastText' : 'inherit',
                  '&:hover': selected ?  
                  {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                  }
                  : {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    }
                }}
              >
                <StyledListItemIcon selected = {selected}
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                     
                  }}
                  
                >
                  <Icon />
                </StyledListItemIcon>
                <ListItemText primary={text} primaryTypographyProps ={{fontSize: '0.9em', mt: 0.25}} sx={{ opacity: open ? 1 : 0,  }} />
              </ListItemButton>
            </ListItem>
            {divisor && <Divider  />}
            </>
    )
} export default MenuItem; 
