import { createContext, useState, useEffect } from 'react';
import * as ls from './service.local-storage';

const QuestoesContext = createContext();


function QuestoesProvider(props) {
     
    const [usuario, setUsuario] = useState (ls.Existe ('usuario') ? ls.Abrir ('usuario') : {});
    
    const [questoes, setQuestoes] = useState (ls.Existe ('questoes') ? ls.Abrir ('questoes') : []);
    const [questoesAntigas, setQuestoesAntigas] = useState (ls.Existe ('questoes') ? ls.Abrir ('questoes') : []);

    const [questoesControle, setQuestoesControle] = useState (ls.Existe ('questoesControle') ? ls.Abrir ('questoesControle') : {total: 0, atual: 0, numEscolhidas: 0, offset: 0, total_parcial:0}); // colocar 
    const [questoesEscolhidas, setQuestoesEscolhidas] = useState(localStorage.getItem ('questoesEscolhidas') ? JSON.parse (localStorage.getItem ('questoesEscolhidas')) : []);
    
    const [filtros, setFiltros] = useState (ls.Existe ('filtros') ? ls.Abrir ('filtros') : []);
    const [filtrosEscolhidos, setFiltrosEscolhidos] = useState (ls.Existe ('filtrosEscolhidos') ? ls.Abrir ('filtrosEscolhidos') : []);
    
    const [tags, setTags] = useState (ls.Existe ('tags') ? ls.Abrir ('tags') : []);
    const [tagsEscolhidas, setTagsEscolhidas] = useState (ls.Existe ('tagsEscolhidas') ? ls.Abrir ('tagsEscolhidas') : []);
    
    const [disciplinas, setDisciplinas] = useState (ls.Existe ('disciplinas') ? ls.Abrir ('disciplinas') : []);
    const [disciplinaEscolhida, setDisciplinaEscolhida] = useState (ls.Existe ('disciplinaEscolhida') ? ls.Abrir ('disciplinaEscolhida') : {});
    
    async function UpdateLocalStorage (nome, variavel) {
        ls.Salvar (nome, variavel);
    }

    
    useEffect (() => {
      
        UpdateLocalStorage ('usuario', usuario);
    }
    ,[usuario])

    useEffect (() => {
        UpdateLocalStorage ('questoes', questoes);
    }
    ,[questoes])

    

    useEffect (() => {
        UpdateLocalStorage ('questoesEscolhidas', questoesEscolhidas);
    }
    ,[questoesEscolhidas])
    
    useEffect (() => {
        UpdateLocalStorage ('questoesControle', questoesControle);
    }
    ,[questoesControle])
    
    useEffect (() => {
        UpdateLocalStorage ('filtros', filtros);
    }
    ,[filtros])

    useEffect (() => {
        UpdateLocalStorage ('filtrosEscolhidos', filtrosEscolhidos);
    }
    ,[filtrosEscolhidos])
     
    useEffect (() => {
        UpdateLocalStorage ('tags', tags);
    }
    ,[tags])

    useEffect (() => {
        UpdateLocalStorage ('tagsEscolhidas', tagsEscolhidas);
    }
    ,[tagsEscolhidas])

    useEffect (() => {
        UpdateLocalStorage ('disciplinas', disciplinas);
    }
    ,[disciplinas])

    useEffect (() => {
        UpdateLocalStorage ('disciplinaEscolhida', disciplinaEscolhida);
    }
    ,[disciplinaEscolhida])

    function ZerarEscolhaQuestoes () {
        setQuestoes ([]);
        setQuestoesAntigas ([]);
        setQuestoesEscolhidas ([]);
        setQuestoesControle ({total: 0, atual: 0, escolhida: false, numEscolhidas: 0, offset: 0, total_parcial:0});
        setTags ([]);
        setFiltros ([]);
        setFiltrosEscolhidos ({});
        setTagsEscolhidas ([]);
        setDisciplinas ([]);
        setDisciplinaEscolhida ({})
}  

    return <QuestoesContext.Provider value={{
     
     usuario, setUsuario,   
     questoes, setQuestoes, questoesEscolhidas, setQuestoesEscolhidas, questoesControle, setQuestoesControle, 
     filtros, setFiltros, filtrosEscolhidos, setFiltrosEscolhidos, 
     tags, setTags, tagsEscolhidas, setTagsEscolhidas,
     disciplinas, setDisciplinas, disciplinaEscolhida, setDisciplinaEscolhida, questoesAntigas, setQuestoesAntigas,
     ZerarEscolhaQuestoes}}>
                {props.children}
           </QuestoesContext.Provider> 
}

export { QuestoesContext, QuestoesProvider};