import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import FormatListBulletedTwoToneIcon from '@mui/icons-material/FormatListBulletedTwoTone';


const isDesenvolvimento = false;
const isLocalHost = false;

export const protegerRotas = process.env.REACT_APP_PROTEGER_ROTAS;

export const img_dir = isDesenvolvimento ? process.env.PUBLIC_URL + 'data/images/questoes/' : process.env.PUBLIC_URL + 'data/images/questoes/';

// ************* COPYRIGHT *************
export const COPYRIGHT_NOME = "FazProva";
export const COPYRIGHT_SITE = "https://www.fazprova.com";
  
// ************* NAVBAR *************
export const logo_light = require ('../images/navbar_logo_light.png');
export const logo_dark  = require ('../images/navbar_logo_dark.png');
export const NAVBAR_TITULO = "FazProva";
 
// ************* NAVBAR ICONS *************
export const navbarIcons = [['Notificações', NotificationsIcon, '/notificacoes', 'Notificações', false],
                            ['Modo escuro', null, '/modo', 'Tela', true],
                            ['Usuário', PersonIcon, '/usuario', 'Usuário', true],

                            

];

// **************** NAVBAR MENUS ********************** //
export const temaMenu = [
  ['light', 'Claro', 'Clássico', true],
  ['dark', 'Escuro', 'Floresta', false],
  ['dark', 'Escuro', 'Blue', false],
  ['dark', 'Escuro', 'Terra', false],
];

// ************* LOCAL STORAGE *************
export const localStorageVariaveis = [
  {nome: "disciplinas", eJson: true, valor: [] },
  {nome: "disciplinaEscolhida", eJson: false, valor: 0 },
  {nome: "segmentoEscolhido", eJson: false, valor: 0 },
  {nome: "filtrosEscolhidos", eJson: true, valor: {fontes: [], anos: [], tipos: [], ordenacao: [1]} },
  {nome: "tags", eJson: true, valor: []},
  {nome: "tagsEscolhidas", eJson: true, valor: []},
  {nome: "tagsIDsExpandidas", eJson: true, valor: [0] },
  {nome: "questoes", eJson: true, valor: [] },
  {nome: "questoesEscolhidas", eJson: true, valor: [] },
  {nome: "questoesControle", eJson: true, valor: {total: 0, atual: 0, escolhida: false, numEscolhidas: 0} },
  {nome: "creditosInfo", eJson: true, valor: {creditos: 1000, periodos: 12 }},
 
];

// ************* MERCADO PAGO *************
export const API_KEY = process.env.REACT_APP_API_KEY

// ************* SIDE BAR *************
// ************* SIDE BAR ICONS *************
export const sidebarIcons = [
  ['Home', HomeTwoToneIcon, '/home', false],
  ['Disciplinas', MenuBookTwoToneIcon, '/disciplinas', true],
  ['Listas', FormatListBulletedTwoToneIcon, '/listas', true],
  ['Créditos', ShoppingCartTwoToneIcon, '/creditos', true]
];

// ************* API *************
export const URLServidorPrincipal = () => {
  if (isLocalHost) {
    return process.env.REACT_APP_SERVIDOR_PRINCIPAL_LOCAL_HOST
  } else {
    return process.env.REACT_APP_SERVIDOR_PRINCIPAL_NUVEM
  }
}

const URLServidorOBA = () => {
  if (isLocalHost) {
    return process.env.REACT_APP_SERVIDOR_OBA_LOCAL
  } else {
    return process.env.REACT_APP_SERVIDOR_OBA_NUVEM
  }
}

const versaoAPI= '/v1';	

export const endpoint = {
  usuarioLogin                       : {url: URLServidorPrincipal() + versaoAPI + '/usuarios/login'},
  usuarioLogout                      : {url: URLServidorPrincipal() + versaoAPI + '/usuarios/logout'},
  usuarioCadastrar                   : {url: URLServidorPrincipal() + versaoAPI + '/usuarios/cadastro'},
  usuarioEditar                      : {url: URLServidorPrincipal() + versaoAPI + '/usuarios'},
  senhaSolicitarResetRota            : {url: URLServidorPrincipal() + versaoAPI + '/usuarios/senha/recuperacao/token'},
  senhaValidarTokenRota              : {url: URLServidorPrincipal() + versaoAPI + '/usuarios/senha/recuperacao/token/validacao'},
  senhaEditarSenhaRota               : {url: URLServidorPrincipal() + versaoAPI + '/usuarios/senha/recuperacao/edicao'},


  creditosTotal               : {url: URLServidorPrincipal() + versaoAPI + '/creditos/total'},
  listasTotal                 : {url: URLServidorPrincipal() + versaoAPI + '/listas/total'},
  questoesTotal               : {url: URLServidorPrincipal() + versaoAPI + '/listas/questoes/total'},

  
  dadosHomeUsuarioRota        : {url: URLServidorPrincipal() + versaoAPI + '/usuarios/dados/home'},
  
  id_fonteListar                : {url: URLServidorOBA() + versaoAPI + '/fontes'},
  id_anoListar                  : {url: URLServidorOBA() + versaoAPI + '/anos'},
  tipoListar                    : {url: URLServidorOBA() + versaoAPI + '/tipos'},
  tagsListar                    : {url: URLServidorPrincipal() + versaoAPI + '/tags'},
  
  disciplinasListar           : {url: URLServidorOBA() + versaoAPI + '/disciplinas/'},
  questoesListar              : {url: URLServidorOBA() + versaoAPI + '/questoes'},
  
  questoesReportarProblema    : {url: URLServidorPrincipal() + versaoAPI + '/questao/problema'},
  
  creditosListar              : {url: URLServidorPrincipal() + versaoAPI + '/creditos'},
  creditosPagamento           : {url: URLServidorPrincipal() + versaoAPI + '/creditos/pagamento'},
  
  
  verificaPagamento           : {url: URLServidorPrincipal() + versaoAPI + '/pagamento/verifica'},
  
  
  listasListar                : {url: URLServidorPrincipal() + versaoAPI + '/listas'},
  gerarProva                  : {url: URLServidorPrincipal() + versaoAPI + '/listas/pronta'},
  
  notificacoesListar          : {url: URLServidorPrincipal() + versaoAPI + '/notificacoes'},
  
  mensagemEnviar              : {url: URLServidorPrincipal() + versaoAPI + '/mensagem/usuario'},
};

// ************* HTML *************
export const HTML_INICIAL  = '<!DOCTYPE html><html lang="en-US" dir="ltr"><head><meta charset="utf-8"><meta http-equiv="content-type" content="text/html"><meta name="viewport" content="width=device-width, initial-scale=1">';
export const HTML_INICIAL2 = '<script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script><script id="MathJax" async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"></script><div id="math-content"></head><body>';
export const HTML_FINAL    = '</div></body></html>';


// ************* CARD TITULO  *************
export const CARD_TITULO_BORDER_RADIUS = 2;
export const CARD_TITULO_BORDER_COLOR = 'null';
export const CARD_TITULO_FONT_WEIGHT_TITULO = 600;
export const CARD_TITULO_FONT_SIZE_TITULO = 22;
export const CARD_TITULO_FONT_COLOR_TITULO = 'primary.main';
export const CARD_TITULO_VARIANT_TITULO = 'body1';
export const CARD_TITULO_MT_CHILDREN = 3;
export const CARD_TITULO_MT = -2;
export const CARD_TITULO_MB = 2.5

// ************* NOTIFICACOES TIMEOUT  *************
export const NOTIFICACOES_TIMEOUT = process.env.REACT_APP_TIMEOUT_NOTIFICACOES;

// ************* OFF SET  *************
export const OFFSET_QUESTOES = process.env.REACT_APP_OFFSET_QUESTOES;

// ************* TITULO  *************
export const TITULO_FONT_WEIGHT = 400;
export const TITULO_VARIANT = 'subtitle1';

export const logo = [
  'M50 1035 l0 -975 1455 0 1455 0 2 971 c2 534 1 973 -1 975 -2 2 -658  4 -1458 4 l-1453 0 0 -975z m2808 -2 l-3 -868 -1347 -3 -1348 -2 0 870 0 870  1350 0 1350 0 -2 -867z',
  'M546 1588 c-22 -30 -22 -146 0 -176 l15 -22 404 0 404 0 15 22 c11 15 16 45 16 88 0 43 -5 73 -16 88 l-15 22 -404 0 -404 0 -15 -22z',
  'M1590 1590 c-16 -16 -20 -33 -20 -88 0 -113 -19 -106 317 -112 252 -4 292 -7 321 -23 91 -50 109 -192 34 -268 -53 -52 -99 -59 -392 -59 -213 0  -251 -2 -264 -16 -14 -13 -16 -52 -16 -275 0 -309 -4 -299 109 -299 110 0 110  0 113 205 l3 170 215 5 215 5 67 32 c153 72 229 212 214 392 -15 180 -136 312  -312 341 -32 6 -177 10 -321 10 -250 0 -264 -1 -283 -20z',
  'M554 1116 c-18 -14 -19 -31 -22 -320 -3 -364 -9 -346 111 -346 114 0 109 -11 111 241 l2 214 272 3 272 2 16 25 c24 36 16 161 -11 181 -28 21 -722 21 -751 0z',

]
  
  


