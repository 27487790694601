import { useState, useEffect } from "react";
import { Snackbar, Alert, Slide } from "@mui/material";

function SnackbarAviso (props) {
    
    SnackbarAviso.defaultProps = {
        open: true,   
        isAlert: true,
        time: 3000,
        transition: Slide,
        posicao: { "vertical": "bottom", "horizontal": "center" },
        variant: "filled",
        tipo: "success",
        msg: ""
        
    }
      
    
    const [openSnack, setOpenSnack] = useState (props.open);
    
    useEffect (() => {
        setOpenSnack(false);
    }, [props.open]);
    
    function handleClose () {
        setOpenSnack(false);
      //  props.onClose ();
    }

    return <>
    
    {props.openSnack &&
    <Snackbar open={props.open} autoHideDuration={props.time} 
               TransitionComponent={props.transition}
               anchorOrigin={props.posicao}
               onClose={handleClose}
               sx={{zIndex: 10000}}
      >

              {props.isAlert ? <Alert variant={props.variant} severity={props.tipo} sx={{ width: '100%' }}>
                                    {props.msg}
                             </Alert> : props.msg}
     </Snackbar>
}
     
    
    </>


} export default SnackbarAviso;