export function Retangulo (event, anchorEl, setTop, setLeft, setOpen, Open, setAnchorEl) {
    event.preventDefault();
    
  if (anchorEl === null) {
    
    let retangulo = event.currentTarget.getBoundingClientRect();
    setTop(retangulo.top + retangulo.height);
    setLeft(retangulo.left);
    setOpen (!Open);
    setAnchorEl(event.currentTarget)
  } else {
    setOpen (!Open);
    setAnchorEl(null)
  }

}