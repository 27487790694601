import { Container } from "@mui/material";
import Beneficios from "./Componentes/Beneficios";
import CallToAction from "./Componentes/CallToAction";
import EspecificacoesTecnicas from "./Componentes/EspecificacoesTecnicas";
import Footer from "./Componentes/Footer";
import Heading from "./Componentes/Heading";
import NavBar from "./Componentes/NavBar";
import Testemunhos from "./Componentes/Testemunhos";
import Numeros from "./Componentes/Numeros";

function LandingPage() {
  return (
       <>
       <NavBar></NavBar>
       <Heading />
      
            
           
            <Beneficios />
            <Numeros />
            
            <Footer />
      
        </> 
       
    
  );
} export default LandingPage;