
import { useTheme } from '@mui/material';
import heading from '../heading.jpg';
import { Typography, Box } from '@mui/material';

function Heading() {
    const theme = useTheme();
  return (
    <>
    
   
      
      <Box style={{position: 'relative'}} sx={{ display: {xs: 'none', sm: 'block'}, width: '100%'}}>
        <img src={heading} alt="imagem" style={{
           width: '105%',
           height: 'auto',
           maxHeight: '30em',
           marginLeft: '-5%'
        }} />
        
        <Typography  sx={{mt:0, fontWeight: 500,  textAlign: 'center', fontSize: {xs: 25, md: 35, lg: 50}}} style={{position: 'absolute', top: '14%', left: '50%'}} >
                              Questões inéditas para o Ensino Fundamental e Médio!
                            </Typography>
        

                            </Box>
    
    </>
  );
}

export default Heading;