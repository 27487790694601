import { useState, useRef, useContext } from 'react';
import { QuestoesContext } from '../../services/questoesProvider';
import { Alert, AlertTitle, CircularProgress, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Switch, Divider } from '@mui/material';
import GenericField from '../Gerais/Form/GenericField';
import { ApiCallWord, chamarApi } from '../../services/api2';


export default function ModalGerarProva (props) {
  const [open, setOpen] = useState(true);
  const [arquivo, setArquivo] = useState ('');
  const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
  const { questoesEscolhidas, usuario, disciplinaEscolhida } = useContext (QuestoesContext);
  const condicoes = useRef ( {fonte: true, ano: true, gabarito: true} ); 

  const dados = useRef ({id_usuario: usuario.id, condicoes: {fonte: true, ano: true, gabarito: true} })
 
    const handleClose = () => {
        
    props.onClose({gerada: dados.current.isSuccess, nome: dados.current.arquivo, data: dados.current.resposta});
    setOpen(false);
    
  };

  function handleGerar (e) {
    e.preventDefault ();
    setLoading ({inicio: true, fim: false, isSuccess: false});
    
    dados.current.nome = arquivo;
    dados.current.condicoes = condicoes.current;
    dados.current.id_disciplina = disciplinaEscolhida.idDisciplina
    // Determina apenas os ids das questões
    dados.current.id = questoesEscolhidas.map (item => item.id);
    
    // CHAMA A API
    
    const getResult = async () => {
      const result = await ApiCallWord ({type: 'post', endpoint:'gerarProva', data: dados.current}) // chama a API
      setLoading (result);
      
      if (result.isSuccess) {
        dados.current.isSuccess = true;
        dados.current.resposta = result.response;
        dados.current.arquivo = arquivo;
        handleClose()
      } 
      }  
      
      getResult();
  }
  
  
  return (<>      
      
      
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Gerar Prova
        <Divider  /> 
        </DialogTitle>
        
        <DialogContent>
          <DialogContentText>
            Selecione as informações para gerar a prova
          </DialogContentText>
          
          <FormGroup sx={{mt:3}}>
            <FormControlLabel control={<Switch defaultChecked onChange={(e) => {e.preventDefault(); condicoes.current.fonte = e.target.checked}} />} label="Fonte" />
            <FormControlLabel control={<Switch defaultChecked onChange={(e) => {e.preventDefault();condicoes.current.ano = e.target.checked}} />} label="Ano" />
            <FormControlLabel control={<Switch defaultChecked onChange={(e) => {e.preventDefault();condicoes.current.gabarito = e.target.checked}} />} label="Gabarito" />
            <Box sx={{mt:1}}>
              <GenericField label={'Disciplina - Série - Assunto'} onChange={(valor) => {setArquivo(valor) }} />
            </Box>
            
            <Typography variant="body2" sx={{color: "text.secondary"}}>
                Utilize um nome que identifique facilmente a prova.
            </Typography>
            <Typography variant="body2" sx={{color: "text.secondary"}}>
                Exemplo: Física - 1º ano - Leis de Newton
            </Typography>
          </FormGroup>
        {/* Alerta de erro */} 
      {
        loading.fim && !loading.isSuccess &&
          <Alert severity="error" >
              <AlertTitle>
                 Erro ao gerar a prova!
              </AlertTitle>  
                Tente novamente mais tarde.
          </Alert>
      }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Voltar</Button>
          
          {loading.inicio && !loading.fim ? <Button disabled={true} ><CircularProgress size={20} /></Button>
          : <Button onClick={(e) => handleGerar (e)} disabled={arquivo===''  ? true : false} >Gerar</Button>}

        </DialogActions>
      </Dialog>
    
    </>
  );
}