import { Typography } from "@mui/material";


function Letra (props) {
    
    const variant = props.variant ? props.variant : null;
    const fontWeight = props.fontWeight ? props.fontWeight : null;
    const fontSize = props.fontSize ? props.fontSize : null;
    const fontColor = props.fontColor ? props.fontColor : null;
    return (
        <Typography variant={variant} fontWeight={fontWeight} fontSize={fontSize} sx={{color: fontColor}}>
          {props.texto}  
        </Typography>
    )
} export default Letra;