import { Box, Container, Grid, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TagFacesOutlinedIcon from '@mui/icons-material/TagFacesOutlined';
import AllInclusiveTwoToneIcon from '@mui/icons-material/AllInclusiveTwoTone';

const Beneficios = () => {
  const theme = useTheme();
  
  return (
    <Box sx={{mt: {xs: 0, sm: -1} , pb:4}} style={{width: '100%', backgroundColor: grey[300]}} >
    
    <Container style={{maxWidth: 750}} sx={{mb:6}}>
                        
                        {/* TITULO */}
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{flexDirection: 'column'}} >
                            <Typography variant='h4' sx={{mt: {xs: 3, sm: 5}, fontWeight: 500, textAlign: 'center'}}>
                               Um banco de questões feito especialmente para você!
                            </Typography>
                            <Typography variant='body1' sx={{mt:1}}>
                                Elabore listas de exercícios e provas com questões próprias e de qualidade
                            </Typography>
                        </Box>
    </Container>

   
                        
                        {/* CARD */}
                        <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', mt: 0, mb: 0, gap:2 }} >
                      
                            
                      
                            <Box sx={{backgroundColor: theme.palette.background.paper, width: 300, height: 320, borderRadius: 2, p: 3, mb:1}} >
                              <SchoolOutlinedIcon sx={{fontSize: 80, color: theme.palette.primary.main}}/>
                              <Typography variant='body1' sx={{mt:1, color: theme.palette.text.secondary}}>
                                Questões para todas as disciplinas do Ensino Fundamental Anos Finais (Ciências, Geografia, História, Língua Portuguesa, Inglês, Matemática, Física e Química) e Física para o Ensino Médio.
                              </Typography>
                            </Box>
                      

                      
                            <Box sx={{backgroundColor: theme.palette.background.paper, width: 300, height: 320, borderRadius: 2, p: 3, mb:1}} >
                              <TagFacesOutlinedIcon sx={{fontSize: 80, color: theme.palette.primary.main}}/>
                              <Typography variant='body1' sx={{mt:1, color: theme.palette.text.secondary}}>
                                Questões inéditas para o Ensino Fundamental Anos Finais e Ensino Médio, além de questões de vestibular para o Ensino Médio.
                              </Typography>
                            </Box>
                      

                      
                            <Box sx={{backgroundColor: theme.palette.background.paper, width: 300, height: 320, borderRadius: 2, p: 3, mb:1}} >
                              <AllInclusiveTwoToneIcon sx={{fontSize: 80, color: theme.palette.primary.main}}/>
                              <Typography variant='body1' sx={{mt:1, color: theme.palette.text.secondary}}>
                                Divisão dos assuntos super detalhada para você encontrar facilmente as questões.
                              </Typography>
                            </Box> 
                      

                      
                            <Box sx={{backgroundColor: theme.palette.background.paper, width: 300, height: 320, borderRadius: 2, p: 3, mb:1}} >
                              <DescriptionOutlinedIcon sx={{fontSize: 80, color: theme.palette.primary.main}}/>
                              <Typography variant='body1' sx={{mt:1, color: theme.palette.text.secondary}}>
                                Crie e baixe as suas listas de exercícios no formato Word e edite como desejar.  
                              </Typography>
                            </Box>
                      
                        </Box>
             
   
   </Box>
  );
};

export default Beneficios;