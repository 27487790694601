// REACT
import { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import { Grid, Box, Button, Typography, Fab, Tab, Tabs, AppBar } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';

// CONTENXT
import { VariaveisContext } from '../../services/variaveisProvider';
import { QuestoesContext } from '../../services/questoesProvider';
import { TemasContext } from "../../services/temasProvider";
import { useTheme } from '@mui/material/styles';

// MEUS COMPONENTES
import SiteFramework from '../../views/components/view.site-framework2.jsx'
import Loading from '../../components/Gerais/Loading/Loading';
import Loading2 from '../../components/Gerais/Loading/Loading2';
import Filtros from '../../controllers/controller.filtro';
import Tags from '../../controllers/controller.tag';
import Alerta from '../../views/components/view.alerta';
import BotoesQuestoes from '../../components/QuestoesButtons/BotoesQuestoes';
import BotoesNavegacaoQuestoes from '../../components/QuestoesButtons/BotoesNavegacaoQuestoes';
import ExibirQuestoes from '../../components/ExibirQuestoes/ExibirQuestoes';

// FUNCOES
import { RetornaFonteAno } from '../Questoes/QuestoesFuncoes';
import { RetornarBody, ajeitarResultado } from '../../controllers/controller.questao';
import { Scrollbars } from 'react-custom-scrollbars';
import SnackbarAviso from '../../components/Gerais/SnackBar/SnackBarAviso';

// HOOKS
import { useQuestoes } from '../../models/model.questao';
const styles = theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundcolor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
});
export default function Assuntos () {
  const colunasGeral = {xs: 12, sm: 12, md: 12, lg: 4, xl: 3}
  const { theme } = useContext (TemasContext);
  const tema = useTheme();
  const { isMobile } = useContext (VariaveisContext);
  const [mostrarQuestoes, setMostrarQuestoes] = useState (false);
  const { usuario, setQuestoes, setQuestoesControle, questoesControle, filtrosEscolhidos, tagsEscolhidas, filtros, disciplinaEscolhida } = useContext (QuestoesContext);
  const loading = useRef ({filtro: {loading: true, sucesso: false}, tag: {loading: true, sucesso: false}});
  const [loadingQuestoes, setLoadingQuestoes] = useState({inicio: false, fim: false, isSuccess: false});
  const [terminouLoading, setTerminouLoading] = useState({inicio: true, fim: false, isSuccess: false});
  const [btnEnabled, setBtnEnabled] = useState (false);
  const [alinhamento, setAlinhamento] = useState ('left');
  const [show, setShow] = useState (false);
  //const theme = useTheme();
  const [value, setValue] = useState(0);
  const [questoesBody, setQuestoesBody] = useState (null);
  const [ questoes, refetch ] = useQuestoes (questoesBody, false, setLoadingQuestoes)
  const classes = styles();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function onFinishLoading (resultado, erro, msg, tipo) {
        
    if (resultado) {
        loading.current [tipo].sucesso = true;
        loading.current [tipo].loading = false;
    } else {
        loading.current [tipo].sucesso = false;
        loading.current [tipo].loading = false;
        setTerminouLoading ({...terminouLoading, inicio: false, fim: true, isSuccess: false, erro: erro, msg: msg});
        return;
    }
    
    if (!loading.current.filtro.loading && !loading.current.tag.loading ) {
        if (loading.current.filtro.sucesso && loading.current.tag.sucesso ) {
          
          setTerminouLoading ({...terminouLoading, inicio: false, fim: true, isSuccess: true});
        } 
    }
    }
  

   

  function onClickAssuntos(e) {
    e.preventDefault();
    setMostrarQuestoes (false);
  }

  const style = {
    margin: 0,
    top: 'auto',
    right: 25,
    bottom: 30,
    left: 'auto',
    position: 'fixed',
};

function BuscarQuestoes (offset) {
  
  setMostrarQuestoes(false)
  let a = RetornarBody (usuario.id, filtros, filtrosEscolhidos, tagsEscolhidas, disciplinaEscolhida, offset)
  setQuestoesBody (a);
  setLoadingQuestoes ({inicio: true, fim: false, isSuccess: false})
  refetch (a) 
}

function handleBuscarQuestoes (valor) {
  if (valor && isMobile) {  
  handleChangeIndex(valor)
}
     setQuestoesControle({...questoesControle, offset: 0})
   BuscarQuestoes (0);
    
  }

function handleBuscarQuestoes2 (e) {
   
  BuscarQuestoes (questoesControle.offset)
 
}
 
useEffect (() => {

  if (loadingQuestoes.fim && questoes !== undefined && questoes.length > 0  ) {
  ajeitarResultado (questoes, setQuestoes, setQuestoesControle, questoesControle)
  setMostrarQuestoes (true)  
  
  }

  if (loadingQuestoes.fim && isMobile && questoes !== undefined && questoes.length === 0  ) {
    
   // handleChangeIndex(1)
    setMostrarQuestoes (true)
    
    }

}, [loadingQuestoes.fim])

useEffect (() => {
 if (questoes && questoes.length > 0 && isMobile) {
    handleChangeIndex(2)
    
 }
}, [questoes])


if (isMobile) {

  return (
    <>
     <SiteFramework 
        children = {<>
         <Loading tipo="normal" loading={terminouLoading} onlineStatus ={(valor) => setTerminouLoading ({...terminouLoading, online: valor})} show={show} msg="Buscando os assuntos..."/>
           {questoesControle.escolhida && <SnackbarAviso variant='filled' open={questoesControle.escolhida} time={1500} transition='Grow'   posicao={{vertical: "top", horizontal: "left"}} /> }
           <Box sx={{  ml:-2, mr:-2, mt:-3 }}>
      <AppBar position="sticky"
       sx={{boxShadow: 'none'}}
      >
  
        <Tabs 
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
         
          variant="fullWidth"
          aria-label="full width tabs example"
          keepMounted={true}
          sx={{backgroundColor: tema.palette.background.default}}
        >
          <Tab label="Filtros" {...a11yProps(0)} />
          <Tab label="Assuntos" {...a11yProps(1)} />
          <Tab label="Questões" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      
        <TabPanel keepMounted={true} value={value} index={0} dir={theme.direction} >
        </TabPanel>
        
        
        <TabPanel keepMounted={true} value={value} index={1} dir={theme.direction}>
        </TabPanel>
       
        <TabPanel keepMounted value={value} index={2} dir={theme.direction}>
      </TabPanel>
       
        <div style={{ display: value===0 ? 'block' : 'none', marginTop: '-2em', marginLeft: '1em', marginRight:'1em' }}> 
        <Loading2 local="full" loading={loadingQuestoes} online={loadingQuestoes.online} msg='Procurando as questões...'  tipo="imagem"/> 
        {questoesControle.total === 0 && mostrarQuestoes &&
            <Box sx={{mt:-1.5, mb:3}}>
                <Alerta tipo="info" msg='Refine a sua busca' titulo="Nenhuma questão encontrada com esses filtros" variant="outlined"/>
            </Box>
            }
          
            <Filtros onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'filtro')}/>
          
          
        </div>
        
        <div style={{ display: value===1 ? 'block' : 'none', marginTop: '-2em', marginLeft: '1em', marginRight:'1em' }}> 
        <Loading2 local="full" loading={loadingQuestoes} online={loadingQuestoes.online} msg='Procurando as questões...'  tipo="imagem"/> 
        {questoesControle.total === 0 && mostrarQuestoes &&
            <Box sx={{mt:-1.5, mb:3}}>
                <Alerta tipo="info" msg='Refine a sua busca' titulo="Nenhuma questão encontrada com esses filtros" variant="outlined"/>
            </Box>
            }
          <Tags onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'tag')}   />
        </div>
        
        <div style={{ display: value===2 ? 'none' : 'block'}}>
        <Fab style={style} onClick={() => {handleBuscarQuestoes (value) }} variant="extended" size="small" color="primary" disabled={tagsEscolhidas.length===0 ? true : false}>
           <NavigationIcon sx={{ mr: 1 }} />
              Buscar questões
        </Fab>
        </div>

        <div style={{ display: value===2 ? 'block' : 'none', marginTop: '-3em', marginLeft: '1em', marginRight:'1em', marginBottom: '4em' }}> 
        <Loading2 local="conteudo" loading={loadingQuestoes} online={loadingQuestoes.online}  tipo="imagem"/> 
        {questoes!==null && questoes[questoesControle.atual-1]!==undefined && questoesControle.total > 0 && mostrarQuestoes && loadingQuestoes.isSuccess &&
                      <>
                      
                
                      <Grid sx={{mt:-1.5, ml: -2, mr:-2, borderRadius:0, backgroundColor: `${theme.palette.background.default}`, width:'100%', position:"fixed", bottom: 0}} item xs={12} >
                        {<> 
                          <BotoesNavegacaoQuestoes escolhida={questoesControle.escolhida} numEscolhidas={questoesControle.numEscolhidas} 
                                                  btnEnabled = {btnEnabled} onBuscarQuestoes= {() => handleBuscarQuestoes2()}
                                                  btnGerar={<BotoesQuestoes mostrarBtnAssuntos={mostrarQuestoes} onChange={(value) => setAlinhamento (value)}/>}
                                                   /></>}
                    </Grid>
                      <Box sx={{mt:2}}>
                      <ExibirQuestoes enunciado={questoes[questoesControle.atual - 1].enunciado} gabarito_comentado={questoes[questoesControle.atual - 1].gabarito_comentado} gabarito={questoes[questoesControle.atual - 1].gabarito} 
                                      gabarito_objetiva = {questoes[questoesControle.atual - 1].gabarito_objetiva} gabarito_discursiva={questoes[questoesControle.atual - 1].discursiva}
                                      tipo = {questoes[questoesControle.atual - 1].tipo}	
                                      atual={questoesControle.atual} total={questoesControle.total} fonte_ano= {disciplinaEscolhida.idSegmento === 4 ? RetornaFonteAno(filtros, questoes, questoesControle) : ''}
                                      alinhamento={alinhamento}
                      />
                       
                      </Box>
                      
                      </>
                  }  
         </div>         
    </Box>

    </>} />
 
    </>
  )
}


  return (
    <>
    
    <SiteFramework 
        children = {<>
       
        
        
        <Grid container spacing={2} className={classes} >
      
        
            {/* ************** FILTROS E TAGS ************** */}
           
          
          
            <Grid  item xs={colunasGeral.xs} sm={colunasGeral.sm} md={colunasGeral.md} lg={colunasGeral.lg} xl={colunasGeral.xl} >
            <Scrollbars style={{ width: 'inherit', height: '90vh', marginTop:'-1em', }}>
           
                <Grid item xs={colunasGeral.xs} >
                    <Filtros onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'filtro')}/>
                </Grid>

               <Box sx={{ml:2,mr:2}} >
                  <Button variant="contained"  color='primary' size="small" fullWidth onClick={(e) => handleBuscarQuestoes (e)} sx={{mb:4, mt:-1}}>PROCURAR QUESTÕES</Button>
               </Box>
               <Grid item xs={colunasGeral.xs} sx={{mt:-1}}>
                  <Tags onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'tag')}   />
                </Grid> 
                
                </Scrollbars>
            </Grid>
            
           
               
            {/* ************** QUESTOES ************** */}
            
            <Grid item xs={colunasGeral.xs} sm={colunasGeral.xs} md={colunasGeral.xs - colunasGeral.md} lg={colunasGeral.xs - colunasGeral.lg} xl={colunasGeral.xs - colunasGeral.xl} 
                  sx={{mt:1, }}>
                   
               <Loading2 local="conteudo" loading={loadingQuestoes} online={loadingQuestoes.online}  tipo="imagem"/> 
            {questoesControle.total > 0 && mostrarQuestoes && loadingQuestoes.isSuccess && <>
              
              <Grid container sx={{mb:6}} direction="row" justify="space-between" alignItems="center">
                
                 <Grid item xs={12} sx={{borderStyle: 'none'}} >
                  <BotoesQuestoes mostrarBtnAssuntos={mostrarQuestoes} onChange={(value) => setAlinhamento (value)}/>
                </Grid>
        
                  {questoes[questoesControle.atual-1]!==undefined && mostrarQuestoes && 
                       <Scrollbars style={{ width: 'inherit', height: '100vh',  }}>
                      <ExibirQuestoes enunciado={questoes[questoesControle.atual - 1].enunciado} gabarito_comentado={questoes[questoesControle.atual - 1].gabarito_comentado} gabarito={questoes[questoesControle.atual - 1].gabarito} 
                                      gabarito_objetiva = {questoes[questoesControle.atual - 1].gabarito_objetiva} gabarito_discursiva={questoes[questoesControle.atual - 1].discursiva}
                                      tipo = {questoes[questoesControle.atual - 1].tipo} escolhida={questoesControle.escolhida}	
                                      atual={questoesControle.atual} total={questoesControle.total} fonte_ano= {disciplinaEscolhida.idSegmento === 4 ? RetornaFonteAno(filtros, questoes, questoesControle) : ''}
                                      alinhamento={alinhamento}
                      />
                      </Scrollbars>
                  }
                
                </Grid> 
                
            </>}
            
            {questoesControle.total === 0 && mostrarQuestoes &&
            <Box sx={{mt:-1.5, ml:1}}>
                <Alerta tipo="info" msg='Refine a sua busca' titulo="Nenhuma questão encontrada com esses filtros" variant="outlined"/>
            </Box>
            }
             <Loading tipo="normal" loading={terminouLoading} onlineStatus ={(valor) => setTerminouLoading ({...terminouLoading, online: valor})} show={show} msg="Buscando as assuntos..."/>
             
            </Grid>
            
            {questoesControle.total > 0 && mostrarQuestoes && questoes[questoesControle.atual-1]!==undefined  &&
                    <Grid sx={{mt:-1.5, ml: -2, mr:-2, borderRadius:0, backgroundColor: `${theme.palette.background.default}`, width:'100%', position:"fixed", bottom: 0}} item xs={12} >
                        {<BotoesNavegacaoQuestoes escolhida={questoesControle.escolhida} numEscolhidas={questoesControle.numEscolhidas} 
                                                  btnEnabled = {btnEnabled} onBuscarQuestoes= {() => handleBuscarQuestoes2()} />}
                    </Grid>
}
          </Grid>
        
          </>} />

    
      
    </>
  
  );

}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      
    role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3}} >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
