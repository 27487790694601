// Verifica se a data é fornecida é menor que a data atual. Exemplo: data atual: 25/09/2023, data fornecida: 24/09/2023. Resultado: true 
export default function eDataMenorQueAtual (data) {
  // Converte as datas para o formato MM/DD/AAAA
  const dataAtual = new Date().toLocaleDateString('pt-BR');
  var [dia1, mes1, ano1] = data.split("/");
  var [dia2, mes2, ano2] = dataAtual.split("/");
  var novaData1 = new Date(`${mes1}/${dia1}/${ano1}`);
  var novaData2 = new Date(`${mes2}/${dia2}/${ano2}`);
      
  return novaData1 < novaData2 
}

// Formata a data no formato gravado no servidor para DD/MM/YYYY
export function Formatar (data) {
    
      // Extrai apenas a parte de data da string de data e hora
    const dateParts = data.split(' ')[0].split('-');
    
    // Reorganiza as partes para criar uma string no formato 'DD/MM/YYYY'
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
}


