export function handleBtn (acao, questoes, questoesControle, setQuestoesControle) {


if (acao = 'próxima') {
    if (questoesControle.atual < questoesControle.total_parcial) {
        questoesControle.atual += 1;
        setQuestoesControle (questoesControle);
    }   
}
}
 


function RetornaNome (filtro, id, nome) {
    
    let retorno = '';
    filtro.forEach((item) => {
        if (item.id === id) {
            retorno = item[nome];
        }
    });
    return retorno;
}

export function RetornaFonteAno (filtros, questoes, questoesControle) {
    let fonte_ano = '';
    if (questoes[questoesControle.atual - 1].id_fonte===0) {
     
    } else
      {
       fonte_ano = RetornaNome (filtros.id_fonte, questoes[questoesControle.atual - 1].id_fonte, 'name');
       
       if (questoes[questoesControle.atual - 1].id_ano !== 0) {
        fonte_ano += ' ' + questoes[questoesControle.atual - 1].ano.nome
      }
 }
 return fonte_ano;
 }



export function RetornarBody (id_usuario, filtros, filtrosEscolhidos, tagsEscolhidas, disciplinaEscolhida, offset) {

    let a = {};
    a.id_usuario = id_usuario;
    
    
    if (disciplinaEscolhida.idSegmento === 4) {
        if (filtrosEscolhidos.id_fonte && filtrosEscolhidos.id_fonte.length > 0) {a.id_fonte = filtrosEscolhidos.id_fonte};
        if (filtrosEscolhidos.id_ano && filtrosEscolhidos.id_ano.length > 0) {a.id_ano = filtrosEscolhidos.id_ano};
    }   

    if (filtrosEscolhidos.id_tipo && filtrosEscolhidos.tipo.length > 0) {a.tipo = filtrosEscolhidos.tipo};
    
    a.tag = tagsEscolhidas;
    a.offset = offset;

console.debug ('questoes body: ', a);
return a;
}

