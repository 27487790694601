import { localStorageVariaveis } from "../../data/config/config";

export function ZerarVariaveisLocalStorage () {
    
    function ZeraUmaVariavel(nome, eJson, valor) {
    if (!localStorage.getItem(nome)) {
        if (eJson) {
            localStorage.setItem(nome, JSON.stringify(valor))
        } else {    
            localStorage.setItem(nome, valor);
    }
    }
    }
    localStorage.clear();
    
    localStorageVariaveis.map((item) => {
              ZeraUmaVariavel(item.nome, item.eJson, item.valor)
    })
}

// Cria armazenamento local para o objeto usuário
export function CriaLocalStorageUsuario () {
    if (!localStorage.getItem('usuario')) {
        localStorage.setItem('usuario', JSON.stringify({}));
      }
}

// Troca da tela de login para a tela de criar conta e vice-versa
// Zera os objetos dos campos de login e senha caso troque de login para criar conta. Isso serve para que os dados digitados na tela de login apareçam quando o usuário voltar 
// para a tela de login após ir para a tela de criar conta
export function AlteraEstado (e, estado, setEstado, setDadosLogin, setDadosCriarConta) {
    e.preventDefault();
    setEstado(estado);
    setDadosLogin({ email: '', senha: '' });
    setDadosCriarConta({ nome: '', sobrenome: '', email: '', senha: '', repetirSenha: '' });
}

// Retorna o objeto usuário do armazenamento local
export function RetornaLocalStorageUsuario () {
    return JSON.parse(localStorage.getItem('usuario'));
}

// Atualiza o objeto usuário do armazenamento local
export function AtualizaLocalStorageUsuario (usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
}   



