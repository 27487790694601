import { useState } from 'react';
import { TextField } from '@mui/material';

function GenericField(props) {
  const { disabled, autofocus, label } = props;
  const [valor, setValor] = useState(props.valor ? props.valor : '' );
    
  GenericField.defaultProps = {
    disabled: false,
    autofocus: false,
    onChange: (e) => Atualiza(e)
}

    function Atualiza (e) {
        setValor (e.target.value);
        props.onChange (e.target.value)
    }

  return (
    <TextField
                disabled={disabled}
                required
                fullWidth
                autoFocus={autofocus}
                margin="normal"
                name={label}
                label={label}
                value={valor}
                onChange={(e) => Atualiza(e)} />
      );
} export default GenericField;

