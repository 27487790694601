import { useContext, useState } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, Paper, Button } from "@mui/material"

import CardTitulo from '../../views/components/view.card.titulo';
import data  from '../../services/service.data';
import { Formatar }  from '../../services/service.data';
import { RetornaStringMoedaReal } from '../../services/service.moeda.js';
import Loading from '../../components/Gerais/Loading/Loading2';
import * as Credito from '../../controllers/controller.credito.js'
import { QuestoesContext } from '../../services/questoesProvider';

export default function HistoricoCredito (props) {
  const [loading, setLoading] = useState ({inicio: false, fim: false, isSuccess: false});
  const [creditosLista, setCreditosLista] = useState (-1);
  const { usuario } = useContext (QuestoesContext)

  const handleBtnClick = () => {
      setLoading ({...loading, inicio: true});

      Credito.Listar (setLoading, usuario, loading, setCreditosLista)
    }
  

    return (
        <>
          <CardTitulo titulo="Histórico de compras" divider mt={-3.0}>
                      
                      <Button variant="outlined" onClick={handleBtnClick} size="small" sx={{mt: -0, mb:2}}>Carregar</Button>
                      {/* ************** LOADING ************** */}
                      
                      <TableContainer component={Paper} sx={{ mt: 0 }}>
                            <Table size="small" aria-label="a dense table" sx={{ '& > *': { borderBottom: 'unset' }}}>
                              <TableHead >
                                <TableRow >
                                
          
                                  <TableCell padding='5' align="left">N</TableCell>
                                  <TableCell align="left">Data</TableCell>
                                  <TableCell align="left">Créditos</TableCell>
                                  
                                  
                                  <TableCell align="left">Valor</TableCell>
                                 
                                </TableRow>
                              </TableHead>
                            
                              
                              
                                {creditosLista === -1 && null }
                                {creditosLista.length === 0 && loading.inicio===false && loading.isSuccess=== true &&
                                  <Typography sx={{ marginLeft: '1em', marginBottom: '1em', marginTop: '1em' }} component="h6">
                                     Você não adquiriu nenhum crédito até o momento.
                                  </Typography>}
                             
                             
                              <TableBody>                                
                             
                              {creditosLista.length > 0 && loading.inicio===false && loading.isSuccess=== true && creditosLista.map((a, index) => {return <>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{Formatar (a.data)}</TableCell>
                                <TableCell align="left">{a.total}</TableCell>
                                
                                
                                <TableCell align="left">{a.transaction_amount > 0 ? RetornaStringMoedaReal (a.transaction_amount) : "Bônus"}</TableCell>
                                </TableRow>  
                                </>
                              }
                              )
                            }
                                  
                              </TableBody>
                                 
                            </Table>
                            <Loading local="componente" loading={loading} online={true} tipo='imagem'  /> 
                      {/* TABELA QUE EXIBE OS DETALHES DAS COMPRAS DE CRÈDITOS ANTERIORES */}      
                          </TableContainer>
                    
                  </CardTitulo>    
        </>
    )
}
