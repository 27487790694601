import { useState, useEffect } from 'react';
import { TextField, Typography } from '@mui/material';

function FormTextoMulti (props) {
  
  const { disabled, autofocus, nome, titulo, texto } = props;
  const [value, setValue] = useState(texto ? texto : '' );
    
  FormTextoMulti.defaultProps = {
    disabled: false,
    autofocus: false,
}

    function Atualiza (e) {
        setValue (e.target.value);
        props.onChange (e.target.value)
    }
    useEffect(() => {
      setValue (props.value);
  }, [props.value]);
  
  return (
    <>
    {titulo!==undefined &&
      <Typography sx={{ml:0.2}} variant="body2" component="div">
          {titulo}    
      </Typography>
}
    <TextField
                size="small"    
                
                disabled={disabled}
                fullWidth
                multiline
                autoFocus={autofocus}
                margin="normal"
                name={nome}
                label={nome}
                value={value}
                onChange={(e) => Atualiza(e)}
                />
      </>
      );
      
} export default FormTextoMulti;

