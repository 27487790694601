import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import { themes } from '../data/config/themes';
const TemasContext = createContext();


function TemasProvider(props) {
  
    const [mode, setMode] = useState('dark');
    const [colorTema, setColorTema] = useState('Clássico'); // ['padrao', 'militar'
     
    const colorMode = useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
    );    
    
    function config_geral (tema) {
      
      return {
      components: {
   
        
        
      },
      
    ...tema
    }}
    
    const theme_geral = useMemo(
      () => { 
        
        if (colorTema === 'Clássico') {return createTheme({...themes.light.classico})}
        if (colorTema === 'Floresta') {return createTheme({...themes.dark.militar})}
        if (colorTema === 'Blue') {return createTheme({...themes.dark.navy_blue})}
        if (colorTema === 'Terra') {return createTheme({...themes.dark.marrom})}
      
      }, [colorTema]
    );   

    const theme = useMemo(
      () => { 
        
       

        return createTheme(theme_geral, {
          palette : {
            violeta: theme_geral.palette.augmentColor({
              color: {
                main: '#7F00FF',
              },
              name: 'violeta',
            }),
          },
         
          components: {
           
            MuiAppBar: {
              styleOverrides: {
                root: {
                  //backgroundColor: theme_geral.palette.background.paper,
                  [theme_geral.breakpoints.down ('sm')] : { backgroundColor: theme_geral.palette.background.default},
                  [theme_geral.breakpoints.up ('sm')] : { backgroundColor: theme_geral.palette.background.paper},
                  backgroundImage: 'linear-gradient(rgba(255,255,255,0.0), rgba(255,255,255,0.0))'
                }
              }
            },
            
          },
          
          
        }
        )
        
      }, [theme_geral]
    );   
    
    return <TemasContext.Provider value={{mode, theme, setMode, colorTema, setColorTema, colorMode      
     }}>
                {props.children}
           </TemasContext.Provider> 
}

export { TemasContext, TemasProvider};
