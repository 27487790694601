import * as React from 'react';
import { useContext, useState, useEffect} from 'react';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import NavBar from './view.navbar';
import SideBar from './view.sidebar';
import { Container, Box } from '@mui/material';
import { VariaveisContext } from '../../services/variaveisProvider';
import ApiCall from '../../services/api2';
import { styled } from '@mui/system';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { NOTIFICACOES_TIMEOUT } from '../../data/config/config';

function SiteFramework(props) {
  
  const { isMobile, openMenu, setOpenMenu, novasNotificacoes, setNovasNotificacoes } = useContext (VariaveisContext);
  const [open, setOpen] = useState(openMenu);
  
    async function chamarApi (api) {
    
      const result = await ApiCall (api);
      return result;
    }    
       
    // CHAMA A API PARA BUSCAR AS NOTIFICAÇÕES A CADA X MINUTOS
    useEffect(() => {
    function fazerChamadaDeAPI() {
      let data = { params: {id_usuario: JSON.parse (localStorage.getItem ('usuario')).id }};
      
      const getResult = async () => {
      const result = await chamarApi ({type: 'get', endpoint:'notificacoesListar', data: data}) // chama a API
       
      if (result.isSuccess) {
        
        if (result.response.data_envio) {
          if (!localStorage.getItem('notificacoes')) {
            localStorage.setItem('notificacoes', JSON.stringify([]));
          }
          
          let a = JSON.parse (localStorage.getItem ('notificacoes'));
          let b = result.response; // pega as notificações que vieram da API
          a.push (b); // junta as notificações do localStorage com as da API
          
          localStorage.setItem ('notificacoes' , JSON.stringify(a)); // guardar as novas notificações no localStorage
          let num = novasNotificacoes + 1;
          setNovasNotificacoes (num); // guardar a quantidade de novas notificações no estado
        };  
      } 
      }  
        getResult();
      }
    
    // Configurar um intervalo para fazer a chamada de API a cada 1 minuto (60.000 milissegundos)
    
    const intervalo = NOTIFICACOES_TIMEOUT; // 120 segundos = 2 minutos
       const intervalId = setInterval(fazerChamadaDeAPI, intervalo);
    
    // Lembre-se de limpar o intervalo quando o componente for desmontado para evitar vazamentos de memória
       return () => clearInterval(intervalId);
    }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenMenu(false);
  };

  return (
    <React.Fragment>
   <Container maxWidth={false} 
   
   >
     
     
      <Box sx={{ display: 'flex' }}  
      >
      
          <NavBar open={open} 
                  handleDrawerOpen={handleDrawerOpen}
                  
                  >
           
           </NavBar>
         
         <SideBar
            open={open}
            setOpen={setOpen}
            setOpenMenu={setOpenMenu}
            handleDrawerClose={handleDrawerClose}
            isMobile={isMobile}
            
          />
           {/* Backdrop */}
      {open && isMobile && (
         <Fade in={open}>
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 200, // Garanta que seja maior que o zIndex do conteúdo e menor que o do Sidebar
        }} onClick={handleDrawerClose}></div>
        </Fade>
      )}
          <Main open={open}sx={{mt:2}} >
        
          <DrawerHeader >
          <Toolbar id="back-to-top-anchor" />
          </DrawerHeader>
         
            {props.children}
         
          
          </Main>
        
         
      </Box>
     
    </Container>
    <ScrollTop {...props}>
        <Fab style={{bottom: 60, right: 20}} size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
);
}

export default SiteFramework;

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
 
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


const drawerWidth = 230; 

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isMobile }) => ({
    
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen + 1000,
    }),
    
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen + 1000,
      }),
     
     [theme.breakpoints.up('sm')]: {marginLeft: 0},
     
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
   
}));
