import { useState, useEffect } from 'react';

import { Box, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Card, CardContent, Typography, Divider, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ApiCall from '../../services/api2';
import SnackbarAviso from '../../components/Gerais/SnackBar/SnackBarAviso';
import CardTitulo from '../../views/components/view.card.titulo';

export default function Pix (props) {
  const usuario = JSON.parse (localStorage.getItem ('usuario')).id;
  //const [minutes, setMinutes] = useState(1);
  const [minutes, setMinutes] = useState(localStorage.getItem ('time') ? localStorage.getItem ('time') : 59);
  const [seconds, setSeconds] = useState(1);
  const [isTimeOut, setTimeOut] = useState(false);
  const [avisoOpen, setAvisoOpen] = useState(false);

  const pixCode = props.dados.code; // Substitua por seu código PIX
  
  const base64 = "data:image/png;base64," + props.dados.qr_code;
  
  async function UpdateLocalStorage () {
     localStorage.setItem ('time', minutes)
  }


  // TIMER DO PAGAMENTO DO PIX
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (seconds === 0 && minutes === 0) {
      setTimeOut(true);
    } else {
      setMinutes(minutes - 1);
      setSeconds(59);
    }
    UpdateLocalStorage();

  }, [seconds]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(pixCode);
    setAvisoOpen(true);
  };
 
  // CONFERE SE O PAGAMENTO FOI FEITO OU NÃO
  useEffect(() => {
    const interval = setInterval(() => {
        
        // Simulando uma chamada de API para verificar o pagamento
        let b = props.dados.id;
        
        const getResult = async () => {
            const result = await ApiCall ({type: 'post', endpoint:'verificaPagamento', data: {idUsuario: usuario, id: b}}) // chama a API

            if (result.isSuccess) {
                if (result.response.msg = "approved") {
                    localStorage.removeItem ('pix');
                    props.onFinish ();
                    clearInterval(interval); // Se o pagamento foi realizado, podemos parar de checar
                }
                
            } else {           
              alert ('Erro ao verificar o pagamento. Entre em contato conosco.')  
              console.error("Erro ao verificar o pagamento:", result.erro);
            }
        }
        getResult();
        
        if (isTimeOut) {
            clearInterval(interval);
            localStorage.removeItem ('pix');
        }
        
    }, 5000); // Checar a cada 5 segundos

    return () => clearInterval(interval); // Limpar o interval ao desmontar o componente
}, []);


return (    
<>

{/* ************** AVISO ************** */}
{avisoOpen ? <SnackbarAviso tipo='secondary' open={avisoOpen} msg="Código PIX copiado!" onClose={() => setAvisoOpen(false)} /> : null
}
{/* ************** CARD ************** */}
<Box sx={{display: 'flex', flexDirection:'row', justifyContent: 'center' }}>
<Box sx={{maxWidth: '800px', }}>
<CardTitulo titulo='PIX' divider >

        <Typography sx={{mt: 2, fontSize: '1.3em'}}>
            Siga as instruções para realizar o pagamento via PIX:
        </Typography>  
        <Typography sx={{ml:2, mt: 3, fontSize: '1.0em'}}>
            1. Abra o aplicativo do seu banco e selecione a opção PIX.
        </Typography>    
        <Typography sx={{ml:2, mt: 1, fontSize: '1.0em'}}>
            2. Escolha a opção "Pagar com QR Code" ou insira o código abaixo.
        </Typography>  
      

      {!isTimeOut ? (
        <>
          

          <Grid container sx={{mt:3}}  spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid item sm={12}>
              <img src={base64} style={{"width": "200px", "display": "block", "marginLeft": "auto", "marginRight": "auto" }} alt="QR Code" />
            </Grid>
          
            <Grid item >
            <Grid container sx={{mt:3}} spacing={0} direction="row" justifyContent="center" alignItems="center">
            <Grid item >
          <FormControl style={{width: "300px"}}  variant="outlined">
          <InputLabel   htmlFor="outlined-adornment-password">{pixCode}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
           // type={showPassword ? 'text' : 'password'}
            
            label="Código PIX"
          />
          
        </FormControl>
        </Grid>
        <Grid item > 
        <Button onClick={() =>  handleCopyClick()} variant="outlined"  sx={{borderRadius:0, pt:-0, pb:0, pl:0, pr:0}}  >
        <ContentCopyIcon fontSize="medium" sx={{ mt:1.9, mb:1.9}}/>
</Button>

          
                </Grid>
                
         
         
          </Grid>
          
          <Grid item sm={12} >
          <Typography align='center' sx={{ mt: 3, mb:3 }} variant="h6">
            Tempo restante: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
          </Grid>       
          

          </Grid>
          
          </Grid>
          
        </>
      ) : (
        <Typography sx={{ mt: 4, mb:4, textAlign: "center" }} color="error" variant='h6'>
          O tempo para o pagamento do PIX expirou. Por favor, refaça a compra.
        </Typography>
      )}
    



    </CardTitulo>
    </Box>
    </Box>
</>
)
}

