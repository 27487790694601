import { useState, useContext } from 'react';
import { Typography } from '@mui/material'; 

import SiteFramework from '../../views/components/view.site-framework2.jsx'
import Loading from '../../components/Gerais/Loading/Loading2';

import CardTitulo from '../../views/components/view.card.titulo';
import ListaCard from '../../components/ListaCard/ListaCard';

import { useListas } from '../../models/model.lista.js';
//import * as ControllerLista from '../../controllers/controller.lista.js'

import { QuestoesContext } from '../../services/questoesProvider';

export default function Listas () {
  
  const [loading, setLoading] = useState({inicio: true, fim: false, isSuccess: false});
  
  const { usuario } = useContext (QuestoesContext);
  const [ listas ] = useListas ({params: { id_usuario: usuario.id}}, loading.inicio, setLoading)
  
  return (
    <>
    
    <SiteFramework 
        children = {<>
        
        {/* ************** LOADING ************** */}
        <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Buscando as listas..."} mostrarErroOnlineStatus /> 
        
        {/* ************** LISTAS ************** */}
        {loading.online && loading.inicio===false && loading.fim && loading.isSuccess &&       
        <CardTitulo titulo="Listas" divider >
          
            {listas.length > 0 ? listas.map ((a, index) => {
                      return <>
                    <ListaCard key={index} nome={a.nome} questoes_total={a.questoes_total} 
                               data={a.data} 
                    /> </>
                }) : 
                    <Typography sx={{mt: 3} }variant="body1" component="div">
                       Nenhuma lista foi criada até o momento.
                    </Typography>
                }
          
          </CardTitulo>
}     
</>} />
    </>
  );
}