import { chamarApi } from '../services/api2';
import * as React from 'react';
import { OnlineStatus } from '../services/service.hooks'
import { sleep } from '../services/service.sleep';
export function Listar (data, callback) {
    
    const getResult = async () => {
        try {
            console.debug ('Listar disciplinas');
            const result = await chamarApi ({type: 'get', endpoint: 'disciplinasListar', data: data}) // chama a API
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
    }

    export function useDisciplinas(data, fetch, setLoading) {
    const [shouldFetch, setShouldFetch] = React.useState(fetch);  
    const [disciplinas, setDisciplinas] = React.useState(null);
    const loading = React.useRef ({});
      
    const onlineStatus = OnlineStatus();
    
        const fetchDisciplinas = React.useCallback(async () => {
          
            try {
            console.debug('Listar disciplinas');
            
            const result = await chamarApi({ type: 'get', endpoint: 'disciplinasListar', data: data });
            
            if (result.isSuccess) {
                setDisciplinas(result.response);
            } else {
                setDisciplinas([]);        
            }

            loading.current = {inicio: result.inicio, fim: result.fim, isSuccess: result.isSuccess, online: true, erro: result.erro, msg: result.msg}
           
            setLoading (loading.current) 
          } catch (error) {
            setDisciplinas([]);
            loading.current = {inicio: error.inicio, fim: error.fim, isSuccess: error.isSuccess, online: true,  erro: error.erro, msg: error.msg};
           
            setLoading (loading.current) 
          } finally {
            setShouldFetch(false);
            
          }
      }, []); // Dependências do useEffect
     
      
      React.useEffect (() => {
        if (onlineStatus) { 
            
            loading.current = {inicio: true, fim: false, isSuccess: false, online: true};
            setLoading (loading.current) 
            setShouldFetch(true)

            } 
        }, [onlineStatus])

      React.useEffect(() => {
        
        if (onlineStatus===false) {
            loading.current = {inicio: false, fim: true, isSuccess: false, online: false, erro: null, msg: null};
            setLoading (loading.current)
            setShouldFetch(false);
            return 
        }
        
        if (shouldFetch && onlineStatus) {
            fetchDisciplinas();

        }}, [shouldFetch]);

     

            
     
        
     // const refetch = () => setShouldFetch(true);
      
      return [ disciplinas ];
    }
    
   
    
   