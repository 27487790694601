import { useState, useContext } from 'react';
import { QuestoesContext } from '../../services/questoesProvider';

import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

import { Alert, AlertTitle, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Switch, Divider } from '@mui/material';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

export default function BaixarProva (props) {
  const [open, setOpen] = useState(true);
  const [baixado, setBaixado] = useState (false);
  
  const { ZerarEscolhaQuestoes } = useContext (QuestoesContext);
  const navigate = useNavigate ();

  const handleBaixar = () => {
    
    const blob = new Blob([props.prova.data], { type: 'application/octet-stream' });
    saveAs(blob, props.prova.nome + '.docx');
    setBaixado (true);
    
    
  };
  
  const onClose = () => {
    setOpen(false);
    ZerarEscolhaQuestoes();
    navigate ('/home');
    }

  return (<>      
    {/* Alerta de sucesso */} 
    
    

    <Dialog open={open} >
      <DialogTitle>Lista produzida com sucesso!
      <Divider /> 
      </DialogTitle>
      
      <DialogContent>
        <DialogContentText>
        <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
                    <Grid item xs={12} >
                        <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon>
                    </Grid>
          
                    <Grid item xs={12} mt={3} >
                        Clique em BAIXAR para obter o arquivo da lista como documento do Word. 
                    </Grid>
          
        </Grid>  
        </DialogContentText>
        {
      baixado &&
    
        <Alert sx={{mt:2}} severity="success" >
            <AlertTitle>
              Finalizado!
            </AlertTitle>  
              O arquivo das questões se encontra na pasta Downloads
        </Alert>
    }    
      </DialogContent>
      <DialogActions>
                
        <Button onClick={() => onClose ()} disabled={!baixado}>Sair</Button>
        <Button onClick={(e) => handleBaixar (e)} disabled={baixado} >Baixar</Button>

      </DialogActions>
    </Dialog>
  
  </>
);
}