
import * as ModelQuestao from '../models/model.questao'
import { OFFSET_QUESTOES } from '../data/config/config';

export function QuestaoTotalController (usuario) {

    let dadosListar = {};
        dadosListar.params = {id_usuario: usuario.id};

    ModelQuestao.Total (dadosListar, async (result) => {
        if (result.isSuccess) {
            console.debug ('Questões total', result.response);
            return result.response;
        } else {
            console.debug ('Erro ao obter o total de questões:', result.erro + ' - ' + result.msg);
            return -1;
        } 
})     
    
} 

export function ajeitarResultado (questoes, setQuestoes, setQuestoesControle, questoesControle) {
    
    let total = questoes[questoes.length - 1].total
    let total_parcial = questoes.length - 1 ;
    questoes.pop();    

    if (total_parcial === 0) {
    
        setQuestoes (questoes);
        setQuestoesControle ({...questoesControle, total: 0, total_parcial: 0, offset: 0, escolhida: false});
        return  ;
    }
    
    

    if (questoesControle.offset > 0) {
         
                
        let offset_temp = questoesControle.offset + OFFSET_QUESTOES;
        setQuestoesControle ({...questoesControle, atual: 1 + questoesControle.atual, total: total, total_parcial: total_parcial, offset: offset_temp , escolhida: false}); 
        setQuestoes (questoes);
        return;      
      }
      
      if (questoesControle.offset === 0) {
        
        setQuestoesControle ({...questoesControle, atual: 1, total: total, total_parcial: OFFSET_QUESTOES, offset: OFFSET_QUESTOES, escolhida: false }); 
                        
        setQuestoes (questoes);
        
      }

}

export function RetornarBody (id_usuario, filtros, filtrosEscolhidos, tagsEscolhidas, disciplinaEscolhida, offset) {
    
    let a = {};
    a.id_usuario = id_usuario;
    
    
    if (disciplinaEscolhida.idSegmento === 4) {
        if (filtrosEscolhidos.id_fonte && filtrosEscolhidos.id_fonte.length > 0) {a.id_fonte = filtrosEscolhidos.id_fonte};
        if (filtrosEscolhidos.id_ano && filtrosEscolhidos.id_ano.length > 0) {a.id_ano = filtrosEscolhidos.id_ano};
        if (filtrosEscolhidos.ordem) {a.ordem = filtrosEscolhidos.ordem}
    } else {
        a.id_segmento = disciplinaEscolhida.idSegmento;
    }    

    if (filtrosEscolhidos.hasOwnProperty ('tipo') && filtrosEscolhidos.tipo.length > 0) {a.tipo = filtrosEscolhidos.tipo};
    
    a.tag = tagsEscolhidas;
    a.offset = offset;

console.debug ('questoes body: ', a);
return a;
}

export async function QuestaoListar (usuario, filtros, questoes, setQuestoes, setQuestoesControle, questoesControle, filtrosEscolhidos, tagsEscolhidas, offset, disciplinaEscolhida) {
        
   // let data = ModelQuestao.RetornarBody(usuario.id, filtros, filtrosEscolhidos, tagsEscolhidas, disciplinaEscolhida, offset);
    
    
    ModelQuestao.Listar (null, async (result) => {
      
        
                console.debug ('Carregando questões')
        if (result.isSuccess) {
            let total = result.response[result.response.length-1].total;
            let total_parcial = result.response.length - 1 ;
            
            if (total_parcial === 0) {
                
                setQuestoes ([]);
                setQuestoesControle ({...questoesControle, total: 0, total_parcial: 0, offset: 0, escolhida: false});
               
 
                return true ;
            }
            result.response.pop();
            
            if (offset > 0) {
                
                
                result.response.forEach (item => questoes.push (item));

                let offset_temp = offset + OFFSET_QUESTOES;
                setQuestoesControle ({...questoesControle, atual: 1 + questoesControle.atual, total: total, total_parcial: questoesControle.total_parcial + total_parcial, offset: offset_temp , escolhida: false}); 
                return true
              }
              
              if (offset === 0) {
                
                setQuestoesControle ({...questoesControle, atual: 1, total: total, total_parcial: OFFSET_QUESTOES, offset: OFFSET_QUESTOES, escolhida: false }); 
                                
                setQuestoes (result.response);
                return true
              }
              
              
              console.debug ('Questões carregadas: ok');
             
        } else {
            console.debug ('Erro ao obter questões:', result.erro + ' - ' + result.msg);
           
            return false;
        }
      })
   
    }

 