import { Stack, Typography, Box } from "@mui/material";
import servidor from '../../data/images/erro_servidor_reduzida2.png'
import internet from '../../data/images/sem_internet.png'

export default function ErroServidor (props) {
    
    function imagem () {
        if (props.tipo==='servidor') {return servidor}
        if (props.tipo==='internet') {return internet}
    }

    function titulo () {
        if (props.tipo==='servidor') {return 'Não foi possível atender esta solicitação'}
        if (props.tipo==='internet') {return 'Parece que você não está conectado'}
    }

    function subtitulo () {
        if (props.tipo==='servidor') {return 'Tente novamente mais tarde'}
        if (props.tipo==='internet') {return 'Verifique a sua conexão e tente novamente'}
    }
    return (
        <>
        <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '75vh'}} >
        <Stack 
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
        >
           <Typography fontSize='1.2em' align='center'>
              {titulo()}
              
           </Typography>
           <Typography fontSize='1.1em' align='center' sx={{color: 'text.secondary', textAlign:'center'}}>
              {subtitulo()}
              
           </Typography>
           <img style={{ maxWidth: '250px', height: 'auto', objectFit: 'contain', marginTop: '1.2em', textAlign:'center'}} src={imagem()} alt="Erro" />
        </Stack>
        </Box>
        </>
    )
}