import { Container, Box, Typography, Divider } from "@mui/material";
import NavBar from "./Componentes/NavBar";
import { useTheme } from "@mui/material";
import AccordionCustom from "../../views/components/view.accordion";
import { pergunta } from "./faq";
import Footer from "./Componentes/Footer";

function AjudaPage() {
    const theme = useTheme ();

  return (
       <>
       <NavBar />
       <Box style={{width: '100%', height: '93vh', backgroundColor: theme.palette.background.default}} >
       <Container style={{maxWidth: 800}} sx={{mb:6}}>
                        
            {/* TITULO */}
            <Box display="flex" alignItems="center" justifyContent="center" sx={{flexDirection: 'column'}} >
                <Typography variant='h4' sx={{mt:4, fontWeight: 500}}>
                    Ajuda
                </Typography>
                <Typography variant='body1' sx={{mt:1}}>
                    Navegue pelas perguntas mais frequentes sobre a plataforma. 
                </Typography>
            </Box>
            
            {/* FAQ */}
            <Box display="flex" alignItems="center" justifyContent="start" sx={{flexDirection: 'column', mt:4, gap:0}} >
                {pergunta.map ((item) => {
                    return (<>
                        {item.id !==1 && <Divider />}
                        <AccordionCustom key={item.id}
                            titulo={item.titulo}
                            componentes={
                                <Typography variant='body1' sx={{mt:5, textAlign: 'justify'}} >
                                    {item.texto}
                                </Typography>
                            }
                    />
                    </>)
                }

                )}
                

                
            </Box>

            
       </Container>
       <Footer />
       </Box>    
       
        </> 
       
    
  );
} export default AjudaPage;