import { useState, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Divider } from '@mui/material';
import { TemasContext } from '../../services/temasProvider';
import { temaMenu } from '../../data/config/config' 


export default function MenuMudaTema(props) {
  
  const {setMode, setColorTema} = useContext(TemasContext); // funções para mudar o tema
  const [selectedIndex, setSelectedIndex] = useState(1);
    
  const handleMenuItemClick = (event, index, modo, tema) => {
    setSelectedIndex(index);
    setMode (modo);
    setColorTema (tema);
    props.setOpen (false);
  };

  const handleClose = () => {
    props.setOpen (false);
  };

  return (
    
      
      <Menu
        id="lock-menu"
        //anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorReference={'anchorPosition'}
        anchorPosition={{ top: props.top, left: props.left }}
        open={props.open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {temaMenu.map(([modo, modoNome, tema, divisor], index) => (
              <>
              <MenuItem
                key={modo+tema}
                
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index, modo, tema)}
              >
               {modoNome + ' - ' + tema}
              </MenuItem>
              {divisor && <Divider />}
              </>
            ))}
      </Menu>
    
  );
}