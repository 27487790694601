import { useState } from "react";
import { Button } from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReportarProblemaModal from "./ReportarProblemaModal";

function BotaoReportarProblema (props) {
    const [reportarProblemaModalOpen, setReportarProblemaModalOpen] = useState (false);

    
    function handleOpenReportarProblemaModal (e) {
        
        setReportarProblemaModalOpen (true);
      }
    
    function handleCloseReportarProblemaModal (e) {
        
        setReportarProblemaModalOpen (false);
      }

    return (<>
        <Button variant={props.variant} onClick={handleOpenReportarProblemaModal} size="small" sx={{ml: props.isMobile ? 0 : 4}}>
          {props.isMobile ? <ReportProblemIcon /> : "Reportar problema"}
          </Button>

        {reportarProblemaModalOpen ? <ReportarProblemaModal onClose={(e) => handleCloseReportarProblemaModal (e)} questao={props.questao}/> : null}
        </>
    )
} export default BotaoReportarProblema;