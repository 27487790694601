import { Box, CircularProgress, TextField, Button, InputAdornment, IconButton, Link, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import FormNomeView from '../../../views/forms/view.form.nome';
import FormEmailView from '../../../views/forms/view.form.email';
import FormTextoMulti from '../../../views/forms/view.form.texto.multi';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { chamarApi } from '../../../services/api2';
import ModalSucessoErro from '../../../components/Gerais/ModalSucessoErro/ModalSucessoErro';
import { useNavigate } from 'react-router-dom';

function FormularioSuporte (props) {
    const navigate = useNavigate();
    const [botaoLigado, setBotaoLigado] = useState (false);
    const [nome, setNome] = useState ('');
    const [email, setEmail] = useState ('');
    const [msg, setMsg] = useState ('');
    const [emailValido, setEmailValido] = useState (true);
    const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
    const [open, setOpen] = useState (false);

    useEffect(() => {
        validarFormulario();
    }, [nome, email, msg]);
    
    function validarFormulario () {
        if (nome!=='' && email!=='' && msg!=='' && emailValido) {
          setBotaoLigado(true);
        } else {
          setBotaoLigado(false);
        }
      }    
    
    function handleBtnClick (e) {
        e.preventDefault();
        
        let dados       = {};
        dados.nome      = nome;
        dados.email     = email;
        dados.msg       = msg;

        props.onFinish (dados);
        Enviar (dados);
    }
    
    function handleClose (e) {
        e.preventDefault();
        setOpen (false);
        if (loading.inicio===false && loading.fim && loading.isSuccess) {
          navigate ('/page')
       }
    }

    function Enviar (dados) {
        
        setLoading ({...loading, inicio: true, fim: false, isSuccess: false});
        // CHAMA A API
        
        const getResult = async () => {
          
          let data = {id_usuario: dados.id, texto: dados.msg};
          const result = await chamarApi ({type: 'put', endpoint:'mensagemEnviar', data: data}) // chama a API
          
        if (result.isSuccess) {
            console.debug ('Mensagem enviada com sucesso');
            setLoading ({...loading, inicio: false, fim: true, isSuccess: true, modalMsg: "Mensagem enviada com sucesso! Em breve, entraremos em contato."});
          } else {
            console.debug ('Erro ao enviar a mensagem: ', result.erro + ' - ' + result.msg);
            setLoading ({...loading, inicio: false, fim: true, isSuccess: false, modalMsg: "Erro ao enviar a mensagem. Tente novamente mais tarde."});
          }

            
        }
        getResult();
        setOpen (true);
    }

    return <>
        {loading.inicio===false && loading.fim && open &&
    <ModalSucessoErro open={open} msg={loading.isSuccess ? 'Mensagem enviada com sucesso! Entraremos em contato em breve!' : 'Não foi possível enviar a mensagem. Tente novamente mais tarde.'} 
    data={{errorTipo: "error", successTipo: "success", btn1: {label: "OK", color: "primary", component: null}, 
            btn2: {mostrar: false}}} 
                            onClickBtn1={(e) => handleClose (e)} onClose={(e) => handleClose(e)} 
                            success={loading.isSuccess ? true : false} 
                            />
}
        <FormControl fullWidth sx={{gap:1}}>
        <FormNomeView focus={true} onChange={(value) => setNome (value)}/>
        <FormEmailView  onChange={(value, valido) => {setEmail (value); setEmailValido (valido)}} />
        <FormTextoMulti nome='Mensagem' onChange={(value) => setMsg (value)}/>
        
        <LoadingButton
            disableElevation
            loading={loading.inicio}
            variant="contained"
            onClick={(event) => handleBtnClick (event)}
            sx={{ mt: 3, mb: 2 }}
            disabled={!botaoLigado}
        >
            Enviar
        </LoadingButton>

        </FormControl>

    </>
} export default FormularioSuporte;