import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Divider } from '@mui/material';
import { TemasContext } from '../../services/temasProvider';

const options = [
  ['Editar', false, '/usuario/editar'],
  ['Contato', true, '/usuario/contato'],
  ['Sair', false, '/usuario/logout']
];

export default function MenuUsuario(props) {
  
  
  const [selectedIndex, setSelectedIndex] = useState();
  const navigate = useNavigate();

  const handleMenuItemClick = (event, link, index) => {
    setSelectedIndex(index);
    
    props.setOpen (false);
    
    if (link==='/usuario/logout') {navigate (link, {state: {tela: window.location.pathname}})} 
    else {navigate (link)} 
  };

  const handleClose = () => {
    props.setOpen (false);
  };

  return (
    
      
      <Menu
        id="lock-menu"
        //anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorReference={'anchorPosition'}
        anchorPosition={{ top: props.top, left: props.left }}
        open={props.open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map(([nome, divisor, link], index) => (
              <>
              <MenuItem
                key={nome}
                
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, link, index)}
              >
               {nome}
              </MenuItem>
              {divisor && <Divider />}
              </>
            ))}
      </Menu>
    
  );
}