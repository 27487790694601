import { Divider, Typography } from "@mui/material";
import { TITULO_FONT_WEIGHT, TITULO_VARIANT } from "../../data/config/config";
function Titulo (props) {
    
    const fontWeight = props.fontWeight ? props.fontWeight : TITULO_FONT_WEIGHT;
    const variant = props.variant ? props.variant : TITULO_VARIANT;
    const fontSize = props.fontSize ? props.fontSize : null;

    return (<>
    
    <Typography sx={{fontWeight: fontWeight, fontSize: fontSize, color: 'text.primary'}} variant={variant} component="div">
        {props.texto}
    </Typography>
    
    {props.divider && <Divider sx={{mb:1}}/>}
    
    </>)

} export default Titulo;