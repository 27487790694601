import { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from '@mui/material';

import SelecionarCreditos from '../../views/creditosPage/view.selecionar.creditos.jsx'
import ResumoPedido from '../../views/creditosPage/view.resumo.pedido.jsx'
import HistoricoCreditos from '../../views/creditosPage/view.historico.creditos.jsx'

import SiteFramework from '../../views/components/view.site-framework2.jsx'


import * as Credito from '../../controllers/controller.credito.js'

export default function Creditos () {
  
  
  const location = useLocation();
 
  
  const [valor, setValor] = useState (0);
  const creditos = useRef (!localStorage.getItem('creditosInfo') ? 1200 : JSON.parse (localStorage.getItem('creditosInfo')).valor);
  const periodos = useRef (!localStorage.getItem('creditosInfo') ? 12 : JSON.parse (localStorage.getItem('creditosInfo')).periodo);
  
  const valorPorCredito = useRef (0);
  
  const navigate = useNavigate();
  
  useEffect (() => {
    
    
    
   // setShow (true)
  }, [])


  function onChangeSelecionar (credito, periodo) {
    creditos.current = credito;
    periodos.current = periodo;
    valorPorCredito.current = Credito.ValorPorCredito (valor, credito);
    UpdateLocalStorage ();
    setValor(Credito.Total (credito, periodo));
  }
     
  // UPDATE NO LOCAL STORAGE OS VALORES DE CREDITOS E PERIODOS
  async function UpdateLocalStorage () {
    localStorage.setItem ('creditosInfo', JSON.stringify ({valor: creditos.current, periodo: periodos.current, transaction_amount: valor}));
  }

  // CHAMA A PÁGINA DE PAGAMENTO AO CLICAR EM COMPRAR
  const handleOpenPagamento = () => {
    UpdateLocalStorage ();
    if (location.state === "questoes") {navigate ('/pagamento', {state: "questoes"})} else {navigate ('/pagamento')}
  }
  
  
  return (
    <>
      
    <SiteFramework 
        children = {<>
                
        <Grid container spacing={{ xs: 2, md: 3 }} rows={{ xs: 1, lg: 2 }}>

            {/* ************** CARD SELECIONAR CRÉDITOS ************** */}  
            <Grid item xs={12} lg={7}>
                 <SelecionarCreditos  creditos = {creditos} periodos = {periodos}  onChange={(credito,  periodo) => onChangeSelecionar(credito, periodo)} />    
            </Grid>
            
            {/* ************** CARD RESUMO ************** */}
            <Grid item xs={12} lg={5}>
                <ResumoPedido creditos = {creditos} periodos = {periodos} valorPorCredito = {valorPorCredito} valor={valor} onClick={() => handleOpenPagamento}/>
            </Grid>

        
        
        {/* ************** CARD HISTORICO ************** */}
        <Grid item xs={12} sm={12}>
            {<HistoricoCreditos />}
            </Grid>  
            </Grid>  

    </>} />
   </>       
  );
}
