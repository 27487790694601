import { Container, Box, Typography } from "@mui/material";
import NavBar from "./Componentes/NavBar";
import { useTheme } from "@mui/material";
import sobre from './sobre.jpg';
import FormularioSuporte from "./Componentes/view.formulario.suporte";
import Footer from "./Componentes/Footer";

function FaleConoscoPage () {
    const theme = useTheme ();

  return (
       <>
       <NavBar />
       <Box style={{width: '100%', height: '93vh', backgroundColor: theme.palette.background.default}} >
       <Container style={{maxWidth: 800, height: '75%'}} sx={{mb:6}}>
                        
            {/* TITULO */}
            <Box display="flex" alignItems="center" justifyContent="center" sx={{flexDirection: 'column'}} >
                <Typography variant='h4' sx={{mt:4, fontWeight: 500}}>
                    Suporte
                </Typography>
                <Typography variant='body1' sx={{mt:1}}>
                    Preencha as informações corretamente para que a nossa equipe entre em contato o mais rápido possível.
                </Typography>
            </Box>
           
            {/* TEXTO */}
            <Box display="flex" alignItems="center" justifyContent="start" sx={{flexDirection: 'column', mt:4}} >
                <FormularioSuporte />

                
            </Box>

            
       </Container>
       <Footer />
       </Box>    
        </> 
       
    
  );
} export default FaleConoscoPage;