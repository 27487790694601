export const pergunta = [
    {
        id: 1, 
        titulo: 'O que são os créditos?', 
        texto: 'Na FazProva, os créditos definem a quantidade de questões que você pode usar durante o período da sua assinatura. Por exemplo, se você assinar por 100 créditos durante 6 meses, terá a capacidade de utilizar até 100 questões nesse período.'
    },
    {
        id: 2, 
        titulo: 'Os créditos são válidos por quanto tempo?', 
        texto: 'Os créditos adquiridos na FazProva são válidos durante o período escolhido para a validade da assinatura. Por exemplo, se você adquirir 100 créditos para um período de 6 meses, eles devem ser utilizados dentro deste intervalo. Qualquer crédito não utilizado após o término do período se tornará inutilizável.'
    },
    {
        id: 3, 
        titulo: 'Como posso adquirir créditos?', 
        texto: 'Crie uma conta no FazProva (sem nenhum custo) e clique em "Créditos". Caso já tenha um cadastro na plataforma, clique em "Créditos" para selecionar o número de créditos, o período da assinatura e realizar o pagamento.'
    },
    {
        id: 4, 
        titulo: 'Quando os créditos são liberados?', 
        texto: 'Os créditos são liberados em sua totalidade imediatamente após a confirmação do pagamento.'
    },
    {
        id: 5, 
        titulo: 'Qual a quantidade mínima e máxima de créditos que podem ser adquiridas em uma única assinatura?', 
        texto: 'A quantidade mínima de créditos que pode ser adquirida é 100, o que equivale a 100 questões. Já o máximo de créditos disponíveis para aquisição em uma única assinatura é de 1000, correspondendo a 1000 questões.'
    },
    {
        id: 6, 
        titulo: 'Qual a quantidade mínima e máxima de períodos de validade de uma única assinatura?', 
        texto:  'O período mínimo de validade para uma assinatura é de 1 mês, enquanto o máximo é de 12 meses, ou seja, 1 ano.'
    },
    {
        id: 7, 
        titulo: 'Qual o valor da assinatura?', 
        texto: 'O custo da assinatura varia de acordo com a quantidade de créditos escolhida e a duração do período de validade da assinatura (em meses).'
    },
    {
        id: 8, 
        titulo: 'Quais são as formas de pagamento?', 
        texto: 'Atualmente, a única forma de pagamento aceita é através de cartão de crédito.'
    },
    {
        id: 9, 
        titulo: 'É seguro comprar créditos na plataforma?', 
        texto: 'Sim, a segurança é uma prioridade no FazProva. Para garantir transações seguras, utilizamos o reconhecido sistema de pagamento do Mercado Pago.'
    },
    {
        id: 10, 
        titulo: 'É necessário ter uma conta no Mercado Pago para realizar a compra de créditos?', 
        texto: 'Não, não é necessário possuir uma conta no Mercado Pago para adquirir créditos no FazProva. Você pode simplesmente fornecer os dados do seu cartão de crédito no momento de finalizar a compra.'
    },
    {
        id: 11, 
        titulo: 'É necessário ter uma conta no Mercado Pago para realizar a compra de créditos?', 
        texto: 'Não, não é necessário possuir uma conta no Mercado Pago para adquirir créditos no FazProva. Você pode simplesmente fornecer os dados do seu cartão de crédito no momento de finalizar a compra.'
    },
    {
        id: 12, 
        titulo: 'É necessário definir a disciplina que leciono?', 
        texto: 'Não, não é necessário especificar a disciplina que você leciona no FazProva. A plataforma permite que você monte listas de questões abrangendo qualquer uma das disciplinas disponíveis, sem a necessidade de restringir-se a uma única área de ensino.'
    },
    {
        id: 13, 
        titulo: 'Como eu posso adquirir créditos gratuitamente?', 
        texto: 'Você pode receber créditos gratuitos no FazProva ao utilizar o botão "Reportar Problema" presente em cada questão. Se você reportar um erro em alguma questão e nosso time confirmar esse erro, você será recompensado com créditos. Isso é uma forma de agradecer e incentivar a manutenção da alta qualidade das questões na plataforma.'
    },
    {
        id: 14, 
        titulo: 'Quem são os autores das questões no FazProva?', 
        texto: 'As questões disponíveis na FazProva são elaboradas por uma equipe de professores altamente experientes, tanto em sala de aula quanto na especialização de suas disciplinas. Para o Ensino Médio, além das questões originais criadas por nossa equipe, também disponibilizamos questões de todos os vestibulares do Brasil.'
    },
    {
        id: 15, 
        titulo: 'As questões do Ensino Fundamental são adaptações de questões de vestibular?',
        texto: 'Não. As questões do Ensino Fundamental no FazProva são criadas especificamente para esse segmento, considerando o nível cognitivo dos alunos. A plataforma foi desenvolvida por um professor com ampla experiência em autoria de materiais e avaliações educacionais, assegurando que as questões sejam apropriadas para a faixa etária e não simplesmente adaptações de questões de vestibular.'
    },
    {
        id: 16, 
        titulo: 'Quais os tipos de questões disponíveis?',
        texto: 'A plataforma oferece diversos tipos de questões, incluindo: múltipla escolha, discursivas, discursivas com subitens (por exemplo, a), b), etc.), questões de verdadeiro ou falso, e questões para completar frases.'
    },
    {
        id: 17, 
        titulo: 'Quais são os formatos do arquivo das listas baixadas?',
        texto: 'Os arquivos das listas baixadas estão no formato DOCX, compatível com o Microsoft Word. Para visualizá-los, é necessário ter o programa Microsoft Word instalado em seu computador ou celular.'
    },
    {
        id: 18, 
        titulo: 'Por que não estou vendo as fórmulas e equações nas questões de exatas?',
        texto: 'Para visualizar corretamente as fórmulas e equações nas questões de exatas, é importante que você tenha instalado uma versão do Microsoft Word que seja compatível com as "Word Equations". Verifique se a sua versão do Word suporta essa funcionalidade.'
    },
    
    
    
]