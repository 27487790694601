import { useState, useEffect } from "react";
import { TextField } from "@mui/material";

function FormEmailView (props) {
    
    const [value, setValue] = useState (props.value ? props.value : '');
    const [emailValido, setEmailValido] = useState(null);

    useEffect(() => {
        validarEmail (value)
        props.onChange (value, validaEmail(value));
    }, [value]);

    
    useEffect(() => {
      setValue (props.value);
  }, [props.value]);

 
  
    function validarEmail (email) {

        if (validaEmail(email) || value === undefined || value === '') {
          setEmailValido(true);
        } else {
          setEmailValido(false);
        }}

        function isEmail(email) {
            const expressaoRegularEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return expressaoRegularEmail.test(email);
        }
        
        function validaEmail(email) {
          if (email !== '') {
            if (isEmail(email)) {
              return true
            } else {
              return false;
            }
          }
        }

    return (
    <>
    <TextField
                size="small"      
                required
                fullWidth
                margin="normal"
                name="email"
                label="Email"
                value={value}
                error={!emailValido}
                helperText={!emailValido ? 'Digite um email válido' : ''}
                onChange={(e) => setValue (() => (e.target.value))}
                /> 

    </>
)} export default FormEmailView;
