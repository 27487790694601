


/***************************/ 
/******** DATA *************/
/***************************/ 

//funções que lidam com datas




// Pega a data completa com hora, min e segundos no formato genérico e transforma em data no formato DD/MM/AAAA. Exemplo: 2021-09-25T03:00:00.000Z -> 25/09/2021
export function FormatarData (data) {
  
    // Extrai apenas a parte de data da string de data e hora
  const dateParts = data.split(' ')[0].split('-');
 
  // Reorganiza as partes para criar uma string no formato 'DD/MM/YYYY'
  return `${dateParts[2].substring (0,2)}/${dateParts[1]}/${dateParts[0]}`;
}

