import { useContext, useState, useRef } from "react";
import { VariaveisContext } from "../../services/variaveisProvider";
import { QuestoesContext } from "../../services/questoesProvider";	
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Grid } from "@mui/material";
import ModalGerarProva from "../GerarProva/GerarProva";
import BaixarProva from "../BaixarProva/BaixarProva";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArranjoEnunciadoGabarito from "../../views/assuntosPage/view.arranjo.enunciado.gabarito";

function BotoesQuestoes (props) {
    
    const navigate = useNavigate ();
    const { isMobile } = useContext (VariaveisContext);
    const { questoesEscolhidas, ZerarEscolhaQuestoes } = useContext (QuestoesContext);
    const [gerarProva, setGerarProva] = useState (false);
    const [baixarProva, setBaixarProva] = useState (false);
    const prova = useRef ({});

    function handleAssuntos (e) {
      e.preventDefault ();
     // props.onClickAssuntos (true);
    }
  
    function handleGerarProva (e) {
        e.preventDefault ();
        setGerarProva (true);
      }

      function handleFecharGerarProva (pr) {
                
        prova.current.nome = pr.nome;
        prova.current.data = pr.data;
        
        if (pr.gerada) {
            setBaixarProva (true);
        } 
        setGerarProva (false);
        }
        
     function handleFecharBaixarProva () {
        setBaixarProva (false);
        ZerarEscolhaQuestoes();
        navigate ('/home');
        }   

    
        

    return (
        <>
        {isMobile ? <>
            <Button variant="contained" size="small" onClick={(e) => handleGerarProva (e)}  disabled={questoesEscolhidas.length===0 ? true : false}>
                    Gerar
                </Button>
                {gerarProva && <ModalGerarProva onClose={(pr) => handleFecharGerarProva(pr)} />}
            {baixarProva && <BaixarProva prova={prova.current} onClose={handleFecharBaixarProva} />}
            </> :
        <Card variant="outlined" sx={{mt:-3, borderRadius:2, borderStyle: 'none'}}>
            <CardContent sx={{mt:-0.5, mb:-1.5, ml:2, mr:2}} >
                <Grid container direction="row" alignItems="center" spacing={3} justifyContent={isMobile ? "center" : "space-between"}>
                
                {isMobile && props.mostrarBtnAssuntos &&
                <Grid item >
                <Button variant="contained" color={'secondary'} size="small"  startIcon={<KeyboardArrowLeftIcon />} onClick={(e) => handleAssuntos (e)}>
                    Assuntos
                </Button>
                </Grid>
}
                <Grid item>
                <Button variant="contained" size="small" onClick={(e) => handleGerarProva (e)}  disabled={questoesEscolhidas.length===0 ? true : false}>
                    Gerar Prova
                </Button>
                </Grid>

                {!isMobile &&  
                <Grid item sx={{ml:1}}>
                    <ArranjoEnunciadoGabarito onChange={(value) => props.onChange (value)}/>
                </Grid>
}

                </Grid>    
            </CardContent>
            {gerarProva && <ModalGerarProva onClose={(pr) => handleFecharGerarProva(pr)} />}
            {baixarProva && <BaixarProva prova={prova.current} onClose={handleFecharBaixarProva} />}
        </Card>
}</>
    );
} export default BotoesQuestoes;