
import React, { useEffect, useRef, Component } from 'react'
import { img_dir } from '../../../data/config/config';

class ErrorBoundary extends Component {
  
  componentDidCatch(error, errorInfo) {
    
    // Você pode registrar o erro em um serviço de relatório de erros ou no console
    console.error("Capturado erro: ", error, errorInfo);
  }

  render(error){
  
    // Sempre renderiza os componentes filhos, mesmo se um erro ocorrer
    return this.props.children;
  }
}

// InnerHTML component
export default function InnerHTML(props) {
  
  const html = modificarSrc(props.html);
  const divRef = useRef(html)
  
  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      console.error('Erro capturado:', message, 'no arquivo', source, 'na linha', lineno, 'e coluna', colno);
      return true;  // isso previne a propagação do evento e a exibição do erro no console
  };
    
    const parsedHTML = document.createRange().createContextualFragment(html)
    divRef.current.appendChild(parsedHTML)
    divRef.current.innerHTML = html;
    
    const { MathJax } = window;
   
    try {
      MathJax.typesetPromise().then(() => {
        // modify the DOM here
         MathJax.typesetPromise();
        }).catch((err) => console.log(err.message));
    }
    catch(err) {
      
    }
  }, [props.html])

  function modificarSrc(originalString) {
    const regex = /(<img\s[^>]*?src\s*=\s*['"])([^'"]*?)(['"][^>]*?>)/gi;
    const novaString = originalString.replace(regex, (match, p1, p2, p3) => {
        return `${p1}${img_dir}${p2}${p3}`;
    });
    return novaString;
}


    

  return (
    <ErrorBoundary>
      <div id="math-content" ref={divRef}></div>
    </ErrorBoundary>
  )
}


