import { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestoesContext } from '../../services/questoesProvider';
import { Grid, Divider, Typography, FormControl } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SiteFramework from '../../views/components/view.site-framework2.jsx'
import ModalSucessoErro from '../../components/Gerais/ModalSucessoErro/ModalSucessoErro';
import CardTitulo from '../../views/components/view.card.titulo';
import Titulo from '../../views/components/view.titulo';
import { chamarApi } from '../../services/api2';

import GenericField from '../../components/Gerais/Form/GenericField';
import EmailField from '../../components/Gerais/Form/EmailField';
import SenhaField from '../../components/Gerais/Form/SenhaField';
import RepetirSenhaField from '../../components/Gerais/Form/RepetirSenhaField';


export default function Configuracoes () {
  
  const { usuario, setUsuario } = useContext (QuestoesContext);
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
  const [dadosMsg, setDadosMsg] = useState('');
  const [botaoLigado, setBotaoLigado] = useState(false);
  const [open, setOpen] = useState (false);
  const [dados, setDados] = useState ({nome: usuario.nome, sobrenome: usuario.sobrenome, email: usuario.email, senha: "", repetirSenha: ""});
  const emailValido = useRef (true); 
  
 useEffect (() => {
  if (dados.nome === usuario.nome && dados.sobrenome === usuario.sobrenome && dados.email === usuario.email && dados.senha === '') {
    setBotaoLigado (false);
    return;
  } else {

  if ((dados.senha===dados.repetirSenha && emailValido.current)) {setBotaoLigado (true)}
      else {setBotaoLigado (false)};
  }
 }, [emailValido.current, dados.senha, dados.repetirSenha, dados.nome, dados.sobrenome, dados.email])

 function Enviar (e) {
  e.preventDefault();
  
  // CHAMA A API
  setLoading ({...loading, inicio: true, fim: false, isSuccess: false})

  const getResult = async () => {
    let data;

    if (dados.senha === "" && dados.repetirSenha === "") {
       data = {id_usuario: usuario.id, nome: dados.nome, sobrenome: dados.sobrenome, email: dados.email};
    } else data = {id_usuario: usuario.id, nome: dados.nome, sobrenome: dados.sobrenome, email: dados.email, senha: dados.senha};
    
    const result = await chamarApi ({type: 'patch', endpoint:'usuarioEditar', data: data}) // chama a API
    
    
    if (result.isSuccess) {
      console.debug ('Dados do perfil atualizados com sucesso');
      setUsuario ({...usuario, nome: dados.nome, sobrenome: dados.sobrenome, email: dados.email})
      setDadosMsg ("Dados do perfil atualizados com sucesso!");
    } else {
      console.debug ('Erro ao atualizar os dados do perfil: ', result.erro + ' - ' + result.msg);
      setDadosMsg ("Erro ao atualizar os dados do perfil. Tente novamente mais tarde.");
    }
    setLoading (result);
  }
  
  getResult();
  setOpen (true);
}  

      
function handleClose (e) {
    e.preventDefault();
    setOpen (false);
    if (loading.isSuccess) {
      navigate ('/home');
    }
}

function handleSair (e) {
  e.preventDefault();
  setOpen (false);
  navigate ('/home', { state: { eLOGIN: false } });
}


  return (
    <>
    {loading.inicio===false && loading.fim && open &&
    <ModalSucessoErro open={open} msg={dadosMsg} 
    data={{errorTipo: "error", successTipo: "success", btn1: {label: "OK", color: "primary", component: null}, 
            btn2: {mostrar: false}}} 
                            onClickBtn1={(e) => handleClose (e)} onClose={(e) => handleClose(e)} 
                            onClickBtn2={(e) => handleSair (e)}
                            success={loading.isSuccess ? true : false} 
                            />
}
    <SiteFramework 
        children = {<>
        

        {/* ************** CARD ************** */}
        <CardTitulo titulo="Editar" divider >
          
            <Titulo texto='Dados' divider/>
            
            {/* ************** CAMPOS ************** */}  
            <FormControl size='small' fullWidth>   
                
                <Grid container spacing={2}>
                  
                  <Grid item xs={12} md={4}>
                      <GenericField label="Nome" valor={dados.nome} onChange={(valor) => setDados({...dados, nome: valor})}  />
                  </Grid>  

                  <Grid item xs={12} md={4}>    
                      <GenericField label="Sobrenome" valor={dados.sobrenome} onChange={(valor) => setDados({...dados, sobrenome: valor})}  />     
                  </Grid>

                  <Grid item xs={12} md={4} >   
                      <EmailField label="Email" valor={dados.email} onChange={(valor) => setDados({...dados, email: valor})} onValidate={(valor, validado) => emailValido.current = validado}  />
                  </Grid>    
                    
                    <Divider sx={{mt:3}}/>

                  <Grid item xs={12} >
                      <Typography sx={{mt:2, mb:-2, ml:0.5}} variant="caption" component="div">
                                Deixe os campos Senha e Repetir a senha em branco caso não queira alterar a senha.
                      </Typography>
                  </Grid>    

                  <Grid item xs={12} md={6}>  
                       <SenhaField label="Senha" valor={dados.senha} onChange={(valor) => setDados({...dados, senha: valor})}  />
                  </Grid>

                  <Grid item xs={12} md={6}>  
                    <RepetirSenhaField label="Repetir a senha" valor={dados.repetirSenha} onChange={(valor) => setDados({...dados, repetirSenha: valor})}  
                                       onValidate = {(valor, setIgual) => {if (valor !== dados.senha) {setIgual (false) } else {setIgual (true)} }}
                    />
                  </Grid>  
                </Grid>
            </FormControl>
            
            <Divider sx={{mt:3}}/>
            <LoadingButton
              disableElevation
              size="medium"
              loading={loading.inicio}
              sx={{mt: 2, mb:0}}
              variant="contained"
              color="primary"
              disabled={!botaoLigado}
              onClick={(e) => Enviar(e)}
            >
              <span>Enviar</span>
            </LoadingButton>
            
                
         </CardTitulo>
         
      </>} />
    
    </>
  );
}
