import { chamarApi } from "../services/api2";
import * as React from 'react';
import { OnlineStatus } from '../services/service.hooks'

export function Total (data, callback) {
    
    const getResult = async () => {
        try {
            
            const result = await chamarApi ({type: 'get', endpoint: 'listasTotal', data: data}) // chama a API
            callback (result);
        } catch (error) {
            callback (error);
        } 
    }
        getResult();
}

export function useListas(data, fetch, setLoading) {
    const [shouldFetch, setShouldFetch] = React.useState(fetch);  
    const [listas, setListas] = React.useState(null);
    const loading = React.useRef ({});
      
    const onlineStatus = OnlineStatus();
    
        const fetchListas = React.useCallback(async () => {
          
            try {
            console.debug('Listar listas');
            const result = await chamarApi({ type: 'get', endpoint: 'listasListar', data: data });
            
            if (result.isSuccess) {
                result.response.pop()
                setListas(result.response);
            } else {
                setListas([]);        
            }

            loading.current = {inicio: result.inicio, fim: result.fim, isSuccess: result.isSuccess, online: true, erro: result.erro, msg: result.msg}
            setLoading (loading.current) 
          } catch (error) {
            setListas([]);
            loading.current = {inicio: error.inicio, fim: error.fim, isSuccess: error.isSuccess, online: true,  erro: error.erro, msg: error.msg};
            setLoading (loading.current) 
          } finally {
            setShouldFetch(false);
            
          }
      }, []); // Dependências do useEffect
     
      React.useEffect (() => {
        if (onlineStatus) { 
            
            loading.current = {inicio: true, fim: false, isSuccess: false, online: true};
            setLoading (loading.current) 
            setShouldFetch(true)

            } 
        }, [onlineStatus])

      React.useEffect(() => {
        
        if (onlineStatus===false) {
            loading.current = {inicio: false, fim: true, isSuccess: false, online: false, erro: null, msg: null};
            setLoading (loading.current)
            setShouldFetch(false);
            return 
        }
        
        if (shouldFetch && onlineStatus) {
            fetchListas();

        }}, [shouldFetch]);
            
     // const refetch = () => setShouldFetch(true);
      
      return [ listas ];
    }