// REACT
import { useContext, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

// MUI
import { IconButton, Box, CssBaseline,  Toolbar, Typography, Drawer, List, Badge, Container } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/system';

// ICONS
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// CONTEXT
import { VariaveisContext } from '../../services/variaveisProvider';
 
// COMPONENTES
import LogoNavBar from '../../components/NavBar/LogoNavBar';
import NavBarIcon from '../../components/NavBar/NavBarIcon';
import MenuItem from '../../components/Gerais/SideBar/MenuItem';
import MenuMudaTema from '../../components/NavBar/MenuMudaTema';
import MenuUsuario from '../../components/NavBar/MenuUsuario';
import ApiCall from '../../services/api2';

// CONSTANTES
import { sidebarIcons  } from '../../data/config/config';
import { navbarIcons, NAVBAR_TITULO } from '../../data/config/config';
import { Retangulo } from '../../components/NavBar/NavBarFuncoes';
 

function SiteFramework (props) {
  const theme = useTheme();
  const { isMobile, openMenu, setOpenMenu, novasNotificacoes, setNovasNotificacoes } = useContext (VariaveisContext);
  const [open, setOpen] = useState(openMenu);
  
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(location.state?.selected);
  const [anchorEl, setAnchorEl] = useState(null); // menu de mudar tema
   
    const [openMudarTema, setOpenMudarTema] = useState(false); // menu de mudar tema
    const [openUsuario, setOpenUsuario] = useState(false); // menu de mudar tema
    const [top, setTop] = useState(0); 
    const [left, setLeft] = useState(0);
  
    async function chamarApi (api) {
    
      const result = await ApiCall (api);
      return result;
    }
    
    // CHAMA A API PARA BUSCAR AS NOTIFICAÇÕES A CADA X MINUTOS
    useEffect(() => {
    function fazerChamadaDeAPI() {
      let data = { params: {id_usuario: JSON.parse (localStorage.getItem ('usuario')).id }};
      
      const getResult = async () => {
      const result = await chamarApi ({type: 'get', endpoint:'notificacoesListar', data: data}) // chama a API
       
      if (result.isSuccess) {
       
        if (result.response.id) {
          if (!localStorage.getItem('notificacoes')) {
            localStorage.setItem('notificacoes', JSON.stringify([]));
          }
          let a = JSON.parse (localStorage.getItem ('notificacoes')); // pega as notificações que estão no localStorage
          let b = result.response; // pega as notificações que vieram da API
          let c = a.push (b); // junta as notificações do localStorage com as da API
          localStorage.setItem ('notificacoes' , JSON.stringify(c)); // guardar as novas notificações no localStorage
          setNovasNotificacoes (result.response.length); // guardar a quantidade de novas notificações no estado
        };  
      } 
      }  
        getResult();
      }
    
    // Configurar um intervalo para fazer a chamada de API a cada 1 minuto (60.000 milissegundos)
       const intervalo = 30000; // 120 segundos = 2 minutos
       const intervalId = setInterval(fazerChamadaDeAPI, intervalo);
    
    // Lembre-se de limpar o intervalo quando o componente for desmontado para evitar vazamentos de memória
       return () => clearInterval(intervalId);
    }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenMenu(false);
  };

  function RetornaOnClick (event, text) {
    if (text==='Modo escuro') {Retangulo (event, anchorEl, setTop, setLeft, setOpenMudarTema, openMudarTema, setAnchorEl);}
    if (text==='Usuário') {Retangulo (event, anchorEl, setTop, setLeft, setOpenUsuario, openUsuario, setAnchorEl);}
  }
  
  function onClickSideBarIcon (text) {
    setSelectedIndex(text);
    if (!isMobile) {
      handleDrawerOpen ();
    } else {
      handleDrawerClose ();
    }
  }

  return (
    <Container maxWidth={false}   >
      <Box sx={{ display: 'flex' }} >  
     
      <CssBaseline />
      
      {/* NAVBAR */}
      <AppBar position="fixed" open={open} sx={{boxShadow: '0'}}>
        <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between'}}>
          
        <div style ={{display: 'flex', alignItems: 'center'}}
      
      >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            
            sx={{
              
              ...(isMobile && {marginLeft: -1}),
              ...(!isMobile && {marginRight: 5}),
              ...(!isMobile && {marginLeft: -2}),
              ...(!open && { display: 'inline' }),
             
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!isMobile && !open &&
          <LogoNavBar open={open} isMobile={isMobile} />
        }
          {/* ************* TITULO  ************* */}
          {isMobile && open ? null :
          <Typography style ={{marginLeft: '0.85em', marginTop: '-0.32em'}} variant="h6" sx={{fontWeight:400, mb: -0.4}}>{NAVBAR_TITULO}</Typography>
          }
        
          </div>
          
          {/* ************* ICONS  ************* */}
        <Box sx={{
          ...(isMobile && open && {marginLeft: -2}),
        }} >
        <div style ={{display: 'flex'}}
       
        >  
       
        {navbarIcons.map(([text, icon, link, dica, menu, index]) => (
            text==="Notificações" && novasNotificacoes > 0 ?	
            <StyledBadge badgeContent={novasNotificacoes} color="secondary">
            <NavBarIcon 
              key={text}
              titulo={text}
              index={index}
              Icon={icon}
              link={link}
              dica={dica}
              menu={menu}
              open={openMenu}
             
              
              /></StyledBadge> :

              <NavBarIcon 
              key={text}
              titulo={text}
              index={index}
              Icon={icon}
              link={link}
              dica={dica}
              menu={menu}
              open={openMenu}
              
              onClick={(event) => RetornaOnClick (event, text)}
              />
          ))}
        
        </div>
        </Box> 
        </Toolbar>
        
      </AppBar>
      <MenuMudaTema anchorEl={anchorEl} open={openMudarTema} top={top} left={left} setOpen={setOpenMudarTema} />
    <MenuUsuario anchorEl={anchorEl} open={openUsuario} top={top} left={left} setOpen={setOpenUsuario} />
     
     {/* SIDEBAR */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            
          },
        }}
        transitionDuration ={500}
        variant={"persistent"}
        anchor="left"
        open={open}
        hideBackdrop={false}
      >
        <DrawerHeader sx={{   display: 'flex', justifyContent: 'space-between', 
        ...theme.palette.mode==='light' && {backgroundColor: 'primary.main'},
        ...isMobile && open && {mt:-1.5, mb:1},
        ...isMobile && !open && {mt:-1.0, mb:1},
        ...!isMobile && !open && {mt:-2.0, mb:1.8},
        ...!isMobile && open && {mt:-3.2, mb:1.8},
        
        }}>
           {/* ************* LOGO  ************* */}
            {/* Mostra apenas se o menu estiver aberto */}
            <div style={{width: '25px',
          ...isMobile && open && {marginTop: 20},
          }}/>
            
          <Box sx={{
             ...isMobile && {mt:-0.7},
             ...!isMobile && open && {mt: 0.9, mb:0.1},
             ...!isMobile && !open && {mt:0.0},
             
          }}>

          {open && <LogoNavBar open={open} />}
          </Box>
            
          
          <IconButton onClick={handleDrawerClose} sx={{
            ...isMobile && {mt:1.0},
            ...!isMobile && open && {mt:3.2},
            ...!isMobile && !open && {mt:1.8},
            ...theme.palette.mode==='light' && {color: '#ffffff'},
            }}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
                
       
        
        {/* ************** MENU ITEMS ************** */}
        <List>
          {sidebarIcons.map(([text, icon, link, divisor, index]) => (
            <MenuItem 
              key={text}
              text={text}
              open={open}
              index={index}
              Icon={icon}
              link={link}
              divisor={divisor}
              selected={selectedIndex === text}
              isMobile={isMobile}
              onClick={(text) => onClickSideBarIcon (text) }
              setOpen ={(valor) => setOpen(valor)}
              />
          ))}
        </List>


      </Drawer>
      
      <Main open={open}sx={{mt:2}}>
        <DrawerHeader />
        
        {props.children}

      </Main>
     
    </Box>
    </Container>
  );
} export default SiteFramework;


const drawerWidth = 230; 


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isMobile }) => ({
    
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen + 1000,
    }),
    
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen + 1000,
      }),
     
     [theme.breakpoints.up('sm')]: {marginLeft: 0},
     
    }),
  }),
); 

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' ,
})(({ theme, open, isMobile }) => ({
    
    transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen + 700,
  }),
  ...(open &&  {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen + 700,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
   
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 16,
    
    padding: '0 0px',
  },
}));
