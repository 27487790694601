import { useState, useEffect } from 'react';
import { Grid, CircularProgress, TextField, Button, InputAdornment, IconButton, Link, Typography, Avatar } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AtualizaCampos, validarSenha, validarEmail, validarFormularioCriarConta } from './valida';
import  CreateIcon from '@mui/icons-material/Create';
import Copyright from '../Copyright/Copyright';
import { COPYRIGHT_NOME, COPYRIGHT_SITE } from '../../data/config/config';

import logo from '../../data/images/logo_completo_dark.png';

function CriarConta({ dadosCriarConta, setDadosCriarConta, alterarParaLogin, handleCriarConta, isLoading, estado, isMobile }) {
    const [botaoLigado, setBotaoLigado] = useState(false);
    const [senhaIgual, setSenhaIgual] = useState(true);
    const [emailValido, setEmailValido] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    
    useEffect(() => {
        validarEmail(dadosCriarConta.email, setEmailValido);
        validarSenha(dadosCriarConta, setSenhaIgual);
        validarFormularioCriarConta(dadosCriarConta, !emailValido, setBotaoLigado);
    }, [dadosCriarConta]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    return (
        <>
            <img style={{ width: isMobile ? '90%' : '350px', height: 'auto', objectFit: 'contain', paddingTop: '0.1em', paddingBottom: '0.1em' }} src={logo} alt="Logo" />
            <Avatar sx={{ m: 1, bgcolor: 'error.dark', mt: 2 }}>
                <CreateIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
                Criar conta
            </Typography>

            <TextField
                disabled={isLoading}
                required
                fullWidth
                autoFocus
                margin="normal"
                name="nome"
                label="Nome"
                value={dadosCriarConta.nome}
                onChange={(e) => AtualizaCampos(e, estado, null, setDadosCriarConta)} />

            <TextField
                disabled={isLoading}
                required
                fullWidth
                margin="normal"
                name="sobrenome"
                label="Sobrenome"
                value={dadosCriarConta.sobrenome}
                onChange={(e) => AtualizaCampos(e, estado, null, setDadosCriarConta)} />

            <TextField
                disabled={isLoading}
                required
                fullWidth
                margin="normal"
                name="email"
                label="Email"
                type="email"
                value={dadosCriarConta.email}
                onChange={(e) => AtualizaCampos(e, estado, null, setDadosCriarConta, setEmailValido)}
                error={!emailValido}
                helperText={!emailValido ? 'Não é um email válido' : ''} />

            <TextField
                disabled={isLoading}
                required
                fullWidth
                margin="normal"
                name="senha"
                label="Senha"
                
                value={dadosCriarConta.senha}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }}
                onChange={(e) => AtualizaCampos(e, estado, null, setDadosCriarConta)} />

            <TextField
                disabled={isLoading}
                required
                fullWidth
                margin="normal"
                name="repetirSenha"
                label="Repetir Senha"
                
                error={!senhaIgual}
                value={dadosCriarConta.repetirSenha}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }}
                onChange={(e) => AtualizaCampos(e, estado, null, setDadosCriarConta)}
                helperText={!senhaIgual ? 'As senhas devem ser iguais.' : ''} />

            <Button
                disableElevation
                fullWidth
                variant="contained"
                disabled={!botaoLigado || isLoading}
                onClick={(event) => handleCriarConta(event)}
                sx={{ mt: 3, mb: 2 }}
            >
                {isLoading ? <CircularProgress color="primary" sx={{ padding: 1 }} /> : "Criar conta"}
            </Button>

            <Grid item xs sx={{ mb: 4 }}>
                <Link href="#" variant="body2" onClick={(e) => alterarParaLogin(e)}>
                    {isLoading ? null : "Já tenho conta!"}
                </Link>
            </Grid>

            <Copyright nome={COPYRIGHT_NOME} site={COPYRIGHT_SITE} />
        </>

    );
}

export default CriarConta;
