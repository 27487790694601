import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { NAVBAR_TITULO } from '../../../data/config/config';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MuiAppBar from '@mui/material/AppBar';

import { styled } from '@mui/system';
import LogoNavBar from '../../../components/NavBar/LogoNavBar';
import BtnAjuda from './BtnAjuda';

export default function NavBar() {
  const navigate = useNavigate ();

  const handleClickEntrar = () => {
    navigate ('/conta/login')
  }

  const handleClickSobre = () => {
    navigate ('/sobre')
  }

  const handleClickFAQ = () => {
    navigate ('/ajuda/faq')
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{mt:-2.3}} >
           <LogoNavBar open={true} isMobile={false} />
          </Box>
          
          <Typography style ={{marginLeft: '0.85em', marginTop: '-0.3em'}} variant="h6" sx={{fontWeight:500, mb: -0.6, fontSize: '1.4em', flexGrow: 1}}>{NAVBAR_TITULO}</Typography>
          
          <Box sx={{display: 'flex', gap: 2}} >
              <Button color="inherit" onClick={handleClickSobre} >Sobre</Button>
              <BtnAjuda />
              <Button color="primary" size='medium' variant='contained' onClick={handleClickEntrar}>Entrar</Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' ,
})(({ theme, open, isMobile }) => ({
    color: theme.palette.primary.main,
     
    
  
}));  