import { useState, useEffect } from "react";
import { TextField } from "@mui/material";

function FormNomeView (props) {
    
    const [value, setValue] = useState (props.value ? props.value : '');
    const focus = props.focus!==undefined ? props.focus : false;

    useEffect(() => {
        props.onChange (value);
    }, [value]);

    useEffect(() => {
        setValue (props.value);
    }, [props.value]);

    return (
    <>
    <TextField
                size="small"      
                required
                fullWidth
                autoFocus={focus}
                margin="normal"
                name="nome"
                label="Nome"
                value={value}
                onChange={(e) => setValue (() => (e.target.value))}
                /> 

    </>
)} export default FormNomeView;
