import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SiteFramework from '../../views/components/view.site-framework2.jsx'
import ModalSucessoErro from "../../components/Gerais/ModalSucessoErro/ModalSucessoErro";


export default function Logout () {
    const [open, setOpen] = useState (true);
    const location = useLocation ();
    const navigate = useNavigate ();

    function handleClose (e) {
        e.preventDefault();
        setOpen (false);
        console.debug ('location', location);
        navigate (location.state.tela);
    }

    function handleSair (e) {
        e.preventDefault();
        localStorage.clear ();
        setOpen (false);
        navigate ('/conta/login');
    }

    return (
        <SiteFramework 
        children = {<>
            
            <ModalSucessoErro open={open} msg={"Você realmente deseja sair da plataforma?"} data={{errorTipo: "warning", btn1: {label: "Voltar", color: "vermelho", component: null}, 
            btn2: {mostrar: true, label: "Sair", color: "primary", component: null}}} 
                            onClickBtn1={(e) => handleClose (e)} onClose={(e) => handleClose(e)}
                            onClickBtn2={(e) => handleSair (e)} success={false} 
                            />
       </>} />
    )
}
