import { useState } from 'react';
import { TextField } from '@mui/material';

function EmailField (props) {
  const { disabled, autofocus } = props;
  const [emailValido, setEmailValido] = useState(true);
  const [valor, setValor] = useState(props.valor ? props.valor : '' );

    function isEmail(email) {
        const expressaoRegularEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return expressaoRegularEmail.test(email);
      }
    
    function validaEmail(email) {
      if (email !== '') {
        if (isEmail(email)) {
          return true
        } else {
          return false;
        }
      } else return true;
    } 
    
    function Atualiza (e) {
        setValor (e.target.value);
        let a = validaEmail (e.target.value)
        setEmailValido (a);
        props.onChange (e.target.value);
        props.onValidate (e.target.value, a)
        
    }

  return (
    <TextField
        disabled={disabled}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoFocus={autofocus}
        autoComplete="email"
        value={valor}
        onChange={(e) => Atualiza (e)}
        error={!emailValido}
        helperText={!emailValido ? 'Não é um email válido' : ''} />
      );
} export default EmailField;

EmailField.defaultProps = {
    disabled: false,
    autofocus: false,
    onValidate: () => {}
}
